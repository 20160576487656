import { ChakraPrimaryButton } from 'components/Button'
import useActions from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'
import { default as UploadLabsFormComponent } from '../../MyHealth/Labs/UploadLabsForm'
import { CardFormProps } from '.'
import { useToast } from '@chakra-ui/react'

const UploadLabsForm: React.FC<CardFormProps> = ({
  action,
  actionDefinition,
}) => {
  const { handleCompleteAction } = useActions()
  const toast = useToast()

  const onSubmit = useCallback(() => {
    handleCompleteAction(action.id)
    toast({
      title: 'Action completed',
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }, [action, handleCompleteAction])

  return (
    <>
      <UploadLabsFormComponent
        onSubmit={onSubmit}
        buttonColor={'green'}
        buttonText={actionDefinition.buttonText}
      />
      <ChakraPrimaryButton onClick={onSubmit} styleProps={{ mt: 2 }}>
        Complete task - I have no labs to share
      </ChakraPrimaryButton>
    </>
  )
}

export default UploadLabsForm
