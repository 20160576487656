import Thread from './Thread'
import ChatContainer from './ChatContainer'
import useThreads from 'hooks/useThreads/useThreads'
import { Redirect } from 'react-router-dom'
import Loader from 'components/Loader'
import { RoutePaths } from 'containers/Core/Routes'
import useThread from 'hooks/useThreads/useThread'
import { memo } from 'react'
import ChatNavigation from '../Navigation/ChatNavigation'
import { Grid, GridItem } from '@chakra-ui/react'

import './styles.scss'

const Chat: React.FC = () => {
  return (
    <ChatContainer>
      <ChatRouter />
    </ChatContainer>
  )
}

const ChatRouter: React.FC = () => {
  const { thread } = useThread()
  const { threads } = useThreads()

  if (!threads) {
    return <Loader />
  } else if (thread) {
    return (
      <Grid
        templateAreas={`"navigation thread"`}
        gridTemplateColumns={{ base: '175px 1fr', md: '300px 1fr' }}
        gridTemplateRows={'calc(100vh - 100px)'}
        gap="1"
      >
        <GridItem area={'navigation'}>
          <ChatNavigation />
        </GridItem>
        <GridItem area={'thread'}>
          <Thread thread={thread} />
        </GridItem>
      </Grid>
    )
  } else if (threads.length) {
    return (
      <Grid
        templateAreas={`"navigation thread"`}
        gridTemplateColumns={{ base: '175px 1fr', md: '300px 1fr' }}
        gap="1"
      >
        <GridItem area={'navigation'}>
          <ChatNavigation />
        </GridItem>
        <GridItem area={'thread'}>
          <Redirect to={`${RoutePaths.DASHBOARD_CHAT}/${threads[0].id}`} />
        </GridItem>
      </Grid>
    )
  } else {
    return <Redirect to={RoutePaths.DASHBOARD_NEW_THREAD} />
  }
}

export default memo(Chat)
