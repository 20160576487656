"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resourceFactory = exports.resourceNamespace = void 0;
function resourceNamespace(entries) {
    return (source) => Object.fromEntries(Object.entries(entries).map(([key, factory]) => [key, factory(source)]));
}
exports.resourceNamespace = resourceNamespace;
function resourceFactory(constructor) {
    return (source) => new constructor(source);
}
exports.resourceFactory = resourceFactory;
