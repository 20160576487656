"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceError = void 0;
class SourceError extends Error {
    constructor({ cause, code, message, type }) {
        super(message);
        this.code = null;
        this.cause = null;
        this.type = type !== null && type !== void 0 ? type : 'client_error';
        this.code = code !== null && code !== void 0 ? code : null;
        this.cause = cause !== null && cause !== void 0 ? cause : null;
    }
    /* eslint-disable @typescript-eslint/explicit-module-boundary-types,
                      @typescript-eslint/no-unsafe-assignment,
                      @typescript-eslint/no-unsafe-member-access,
                      @typescript-eslint/no-explicit-any */
    static from(response) {
        return new SourceError({
            type: response.type,
            code: response.code,
            message: response.message,
        });
    }
}
exports.SourceError = SourceError;
