import * as yup from 'yup'
import { ErrorMessages } from './constants'

const requiredWhenDependent = (relationship_to_insured) => {
  if (relationship_to_insured !== 'Self') {
    return yup.string().required(ErrorMessages.REQUIRED_FIELD)
  }
}

export const insuranceFormSchema = yup.object({
  insured_person_dob: yup
    .string()
    .when('relationship_to_insured', requiredWhenDependent),
  insured_person_first_name: yup
    .string()
    .when('relationship_to_insured', requiredWhenDependent),
  insured_person_last_name: yup
    .string()
    .when('relationship_to_insured', requiredWhenDependent),
  insured_person_address_line_1: yup
    .string()
    .when('relationship_to_insured', requiredWhenDependent),
  insured_person_city: yup
    .string()
    .when('relationship_to_insured', requiredWhenDependent),
  insured_person_state: yup
    .string()
    .when('relationship_to_insured', requiredWhenDependent),
  insured_person_zip: yup
    .string()
    .when('relationship_to_insured', requiredWhenDependent),
  insured_person_address_line_2: yup.string().optional(),
  relationship_to_insured: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  group_id: yup.string().optional(),
  member_id: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  payer_id: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  other_payer_id: yup.string().optional(),
  rank: yup.string().required(ErrorMessages.REQUIRED_FIELD),
})
