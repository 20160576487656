"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnonymousAuthentication = void 0;
class AnonymousAuthentication {
    createHeaders() {
        return {};
    }
    static getInstance() {
        return this.instance;
    }
}
exports.AnonymousAuthentication = AnonymousAuthentication;
AnonymousAuthentication.instance = new AnonymousAuthentication();
