import { Program } from 'context/OnboardingContext/OnboardingProvider'

export const CreateUser = '/api/v1/app-users'
export const CheckEligibility = '/api/v1/app-users/eligibility'
export const PostUserLogin = '/api/v1/app-users/analytics'

export const Users = '/api/v1/users'
export const UserInfo = '/api/v1/userinfo'
export const ResetPassword = '/api/v1/userinfo/reset-password'
export const UserAddresses = '/api/v1/userinfo/addresses'
export const ShareUserAddress = '/api/v1/userinfo/address'
export const HowDidYouHear = '/api/v1/how-did-you-hear'

export const SelectProduct = Users + '/select-product'
export const WaitlistUserInfo = UserInfo + '/waitlist'
export const OnboardingResponse = '/api/v1/onboarding'
export const ShareHowDidYouHear = Users + '/how-did-you-hear'
export const UpdateWaitlistUsers = Users + '/insurance-recheck'

export const UpdateDirectCheckEligibility = `${Users}/check-eligibility`

export const UserQueryKey = 'user'
export const UserAddressesQueryKey = 'user-addresses'
export const NewPatientQueryKey = 'new-patient'
export const EligibilityQueryKey = 'eligibility'

export interface UserInfoProps {
  id: string
  accountDeactivated: boolean
  email: string
  firstName: string | null
  lastName: string | null
  phoneNumber: string | null
  stripeSubscriptionStatus: string | null
}
