"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeasurementResource = void 0;
const BaseResource_1 = require("../../BaseResource");
class MeasurementResource extends BaseResource_1.Resource {
    /**
     * Lists all measurements for the current account. The measurements returned are
     * sorted by     their timestamp, with the most recently created measurements
     * appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/measurements', {
            query: params,
            options,
        });
    }
    /**
     * Creates a measurement, which can be of kind `numeric` (with a single value) or
     * kind `composite` (with a collection of logically inseparable values (such as a
     * blood pressure reading). The shapes of these requests are different.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/measurements', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Return the details of a measurement (a reading from a Device)
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/measurements/${id}`, {
            options,
        });
    }
}
exports.MeasurementResource = MeasurementResource;
