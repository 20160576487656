import { memo, MouseEventHandler, useEffect, useState } from 'react'
import Button, { ButtonProps } from 'react-bootstrap/Button'
import Loader from 'components/Loader'

import './styles.scss'

interface PrimaryButtonProps extends ButtonProps {
  isLoading?: boolean
  loaderSize?: 'sm' | 'md'
  variant?: 'blue' | 'dark-blue' | 'green'
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  className,
  isLoading,
  onClick,
  disabled,
  loaderSize,
  variant = 'blue',
  ...props
}) => {
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    if (!isLoading) {
      setClicked(false)
    }
  }, [isLoading])

  const onClickHandler: MouseEventHandler<HTMLElement> = (e) => {
    setClicked(true)

    if (onClick) {
      onClick(e)
    }
  }

  const variantClassName = `btn-primary btn-primary-${variant}`

  return (
    <Button
      className={`position-relative ${variantClassName} ` + className}
      variant="primary"
      size="lg"
      disabled={disabled || (clicked && isLoading)}
      onClick={onClickHandler}
      {...props}
    >
      {clicked && isLoading ? <Loader size={loaderSize} /> : children}
    </Button>
  )
}

export default memo(PrimaryButton)
