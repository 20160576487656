import { Container } from 'react-bootstrap'
import Loader from 'components/Loader'
import useUser, { User } from 'hooks/useUser/useUser'
import { datadogRum } from '@datadog/browser-rum'
import { setUserId } from '@amplitude/analytics-browser'

export interface WithAccountProps {
  user: User
}

function withAccount<T extends WithAccountProps>(
  WrappedComponent: React.ComponentType<T>
): React.FC<Omit<T, 'user'>> {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'

  // Creating the inner component. The calculated Props type here is where the magic happens.
  const ComponentWithAccount: React.FC<Omit<T, 'user'>> = (props) => {
    const { user, userError, isLoading } = useUser()

    if (isLoading) {
      return <Loader />
    } else if (userError) {
      console.error('User Object Error:', userError)

      return (
        <Container className="d-flex flex-column justify-content-center height-full">
          <p className="h1 text-danger text-center">
            Some error occurred! Please try again later.
          </p>
        </Container>
      )
    } else if (!user) {
      return <Loader />
    }

    // set amplitude user id
    setUserId(user.id)

    datadogRum.setUser({
      id: user.id,
      email: user.email,
    })
    datadogRum.startSessionReplayRecording()

    return <WrappedComponent {...(props as T)} user={user} />
  }

  ComponentWithAccount.displayName = `withAccount(${displayName})`

  return ComponentWithAccount
}

export default withAccount
