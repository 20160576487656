import withSubscription from 'hoc/withSubscription'
import { WithAccountProps } from 'hoc/withAccount'
import Content from './Content'
import Navigation from './Navigation'
import { SourceProvider } from 'context/SourceContext/SourceProvider'
import {
  Grid,
  ChakraProvider,
  GridItem,
  useBreakpointValue,
} from '@chakra-ui/react'
import Header from './Header'

import './styles.scss'

const DashboardLayout: React.FC<WithAccountProps> = (props) => {
  const templateAreas = useBreakpointValue({
    base: `"header" "content"`,
    md: `"header header" "nav content"`,
  })

  const templateColumns = useBreakpointValue({
    base: '1fr',
    md: '1fr 8fr',
  })

  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <SourceProvider>
      <ChakraProvider>
        <Grid
          templateAreas={templateAreas}
          gridTemplateColumns={templateColumns}
          gap={1}
        >
          <GridItem area="header">
            <Header />
          </GridItem>
          {!isMobile && (
            <GridItem area="nav">
              <Navigation />
            </GridItem>
          )}
          <GridItem area="content">
            <Content {...props} />
          </GridItem>
        </Grid>
      </ChakraProvider>
    </SourceProvider>
  )
}

export default withSubscription(DashboardLayout)
