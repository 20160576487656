import { useToast } from '@chakra-ui/react'
import { ChakraPrimaryButton } from 'components/Button'
import useActions from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'

const HowLongLabsForm: React.FC = () => {
  const { handleRespondHowLongLabs } = useActions()
  const toast = useToast()

  const handleClick = useCallback((response: 'more' | 'less') => {
    handleRespondHowLongLabs(response)
    toast({
      title: 'Action completed',
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }, [])
  const handleLessThanClick = useCallback(() => handleClick('less'), [])
  const handleMoreThanClick = useCallback(() => handleClick('more'), [])

  return (
    <div className="d-flex justify-content-flex-start">
      <ChakraPrimaryButton onClick={handleLessThanClick}>
        Less than 2 weeks
      </ChakraPrimaryButton>
      <div style={{ width: '15px' }}></div>
      <ChakraPrimaryButton onClick={handleMoreThanClick}>
        More than 2 weeks
      </ChakraPrimaryButton>
    </div>
  )
}

export default HowLongLabsForm
