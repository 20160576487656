import React, { useState } from 'react'
import { FormControl } from '@chakra-ui/react'
import DatePicker from 'react-datepicker'
import './SingleDateInput.scss'

interface DateInputProps {
  value: Date
  onChange: (date: Date) => void
  maxDate?: Date
}

const DateInput = ({ onChange, value, maxDate }: DateInputProps) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)

  return (
    <FormControl>
      <DatePicker
        selected={value}
        onChange={onChange}
        dateFormat="MM/dd/yyyy"
        open={isDatePickerOpen}
        onCalendarOpen={() => setIsDatePickerOpen(true)}
        onCalendarClose={() => setIsDatePickerOpen(false)}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        maxDate={maxDate ? maxDate : undefined}
      />
    </FormControl>
  )
}

export default DateInput
