import { WithAccountProps } from 'hoc/withAccount'
import useActions, {
  Action,
  ActionDefinition,
} from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'
import {
  formatActionText,
  getActionLink,
  getDisableButton,
  getDisplayButton,
  getTooltip,
  handleAction,
} from 'utils/actions.utils'
import DashboardCard from './Card'
import CardForm from './CardForm'
import ContentCard from './ContentCard'

interface ActionCardProps extends WithAccountProps {
  action: Action
  completed?: boolean
  actionDefinition: ActionDefinition
}

const ActionCard: React.FC<ActionCardProps> = ({
  action,
  completed,
  actionDefinition,
  user,
}) => {
  const { handleCompleteAction } = useActions()

  const FORM_DEFINITIONS_TO_ALWAYS_SHOW = ['download-app']
  const disableButton = getDisableButton(action, actionDefinition, completed)
  const displayButton = getDisplayButton(
    disableButton,
    actionDefinition,
    completed
  )
  const buttonTooltip = getTooltip(action, actionDefinition)
  const buttonLink = disableButton
    ? undefined
    : getActionLink(action, actionDefinition, user, completed)

  const handleClick = useCallback(() => {
    const buttonLink = actionDefinition.buttonLink
    const autoComplete =
      action.completeCondition?.code === 'auto-complete' ||
      !action.completeCondition

    if (
      (buttonLink && autoComplete) ||
      actionDefinition.content ||
      autoComplete
    ) {
      handleCompleteAction(action.id)
    } else {
      handleAction(action, handleCompleteAction)
    }
  }, [action, actionDefinition])

  return (
    <DashboardCard
      icon={actionDefinition.icon}
      title={formatActionText(actionDefinition.title, user, action)}
      description={formatActionText(
        completed
          ? actionDefinition.completedDescription
          : actionDefinition.description,
        user,
        action
      )}
      buttonLink={buttonLink}
      buttonText={actionDefinition.buttonText}
      buttonTooltip={buttonTooltip}
      displayButton={displayButton}
      disableButton={disableButton}
      handleClick={handleClick}
    >
      {actionDefinition.type === 'form' &&
        (!completed ||
          FORM_DEFINITIONS_TO_ALWAYS_SHOW.includes(action.definition)) && (
          <CardForm action={action} actionDefinition={actionDefinition} />
        )}
      {actionDefinition.type === 'content' && (
        <ContentCard action={action} actionDefinition={actionDefinition} />
      )}
    </DashboardCard>
  )
}

export default ActionCard
