import { Thread as SourceThread } from '@allara-health/source-health-client'
import { memo, useMemo } from 'react'
import { getAssignee, getAssigneeName } from 'utils/chat.utils'
import ProfileImage from '../../../components/SourceProfileImage/ProfileImage'

interface ThreadDescriptionProps {
  thread: SourceThread
  className?: string
}

const ThreadDescription: React.FC<ThreadDescriptionProps> = ({
  thread,
  className = '',
}) => {
  const assignee = useMemo(() => getAssignee(thread), [thread])
  const assigneeName = useMemo(() => getAssigneeName(assignee), [assignee])

  return (
    <div className={`d-flex align-items-center ${className}`}>
      <ProfileImage user={assignee} className="d-none d-lg-block mr-3" />
      <div className="d-flex flex-column">
        <p className="mb-0 fs-2 font-weight-bold">{assigneeName}</p>
        <p className="spaced-capital-letters thread-subject mb-0">
          {thread.subject}
        </p>
      </div>
    </div>
  )
}

export default memo(ThreadDescription)
