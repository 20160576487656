import useUser from './useUser/useUser'
import {
  CustomerPortal as CustomerPortalRoute,
  CustomerPortalQueryKey,
  MembershipInfo,
  PaymentPortal,
  PaymentMethodQueryKey,
  Invoices,
  InvoicesQueryKey,
  CancelSubscription,
  ReactivateSubscription,
  DiscountOptions,
  DiscountOptionsQueryKey,
  ApplyDiscount,
  UpgradeToAnnual,
  PaymentPlan,
} from 'api/payment/routes'
import axios from 'axios'
import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'
import { useStytch, useStytchSession } from '@stytch/react'

interface UseStripePortalInterface {
  isLoading: boolean
  stripePortalLink?: string
  membershipInfo?: MembershipInfo
  noSubscription?: boolean
  stripePortalUpdatePaymentLink?: string
  invoicesData?: InvoicesData[]
  cancelSubscription: ({
    cancellationReason,
  }: {
    cancellationReason: string
  }) => Promise<void>
  reactivateCancelledSubscription: () => Promise<void>
  refetch: () => void
  error: unknown
  isError: boolean
  discountData?: CouponData[]
  applyDiscount: (couponId: string) => Promise<void>
  updateMonthlyInsuranceToAnnual: () => Promise<void>
  fetchPrice: ({ priceId }: { priceId: string }) => Promise<PlansData>
}

export interface PlansData {
  id: string
  currency: string
  type: string
  unit_amount: number
  recurring?: {
    interval: string
    interval_count: number
  }
}

export interface CouponData {
  id: string
  amountOff?: number
  percentOff?: number
  duration?: string
  durationInMonths?: number
  discountEndDate?: Date
  name?: string
}

interface InvoicesData {
  amountDue: number
  date: number | null
  status: string
}
// TO DO: Clean this up so DiscountData retrieved from the server matches the type of CouponData
interface DiscountData {
  coupon: {
    id: string
    amount_off: number
    percent_off: number
    duration: string
    duration_in_months: number
  }
}
interface Subscription {
  id: string
  status: string
  currentPeriodEnd: number
  productId: string
  amount: number
  interval: string
  cancelAtPeriodEnd: boolean
  priceId: string
  discount?: DiscountData
}

interface Address {
  city: string
  country: string
  line1: string
  line2: string
  postalCode: string
  state: string
}

interface billingInfo {
  address: Address
}

interface ProductInfo {
  name: string
  description: string
  type: string
  productionId: string
}
interface paymentMethod {
  id: string
  brand: string
  lastFour: string
  expiration: string
}
interface MembershipInfo {
  subscription: Subscription
  billing: billingInfo
  product: ProductInfo
  payment: paymentMethod
}

const useStripePortal = (): UseStripePortalInterface => {
  const { session } = useStytchSession()
  const stytch = useStytch()
  const { user } = useUser()
  const [refetchTrigger, setRefetchTrigger] = useState(0)
  const [isFetching, setIsFetching] = useState(false)

  const fetchStripePortalLink = async (): Promise<string | undefined> => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${CustomerPortalRoute}`,
        undefined,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => res.data.url ?? undefined)
      .catch(() => {
        return
      })
  }

  const { data: stripePortalLink, isLoading } = useQuery(
    CustomerPortalQueryKey,
    fetchStripePortalLink,
    {
      staleTime: 1000 * 60, // 1min
    }
  )

  const fetchMembershipInfo = async (): Promise<
    { membershipInfo?: MembershipInfo; noSubscription: boolean } | undefined
  > => {
    if (!session || !user?.id || user.accountStatus === 'CANCELLED') {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${MembershipInfo}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        if (res.status === 204) {
          return { membershipInfo: {}, noSubscription: true }
        }
        const data = await res.json()
        if (res.ok) {
          return { membershipInfo: data, noSubscription: false }
        }
      })
      .catch((error) => {
        return error
      })
  }

  const {
    data: membershipData,
    isLoading: isMembershipInfoLoading,
    refetch: refetchMembershipInfo,
    error: membershipError,
  } = useQuery(['membershipInfo', user?.id], fetchMembershipInfo, {
    staleTime: 1000 * 60, // 1 minute
  })

  const membershipInfo = membershipData?.membershipInfo
  const noSubscription = membershipData?.noSubscription

  useEffect(() => {
    refetchMembershipInfo()
  }, [refetchTrigger])

  const refetch = () => {
    setRefetchTrigger((value) => value + 1)
  }

  const fetchStripePortalUpdatePaymentLink = async (): Promise<
    string | undefined
  > => {
    if (!session || !user?.id) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios
      .get(`${process.env.REACT_APP_SERVER_URL}${PaymentPortal}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => res.data.url ?? undefined)
      .catch(() => {
        return
      })
  }

  const { data: stripePortalUpdatePaymentLink } = useQuery(
    PaymentMethodQueryKey,
    fetchStripePortalUpdatePaymentLink,
    {
      staleTime: 1000 * 60, // 1 minute
    }
  )

  const fetchInvoices = async (): Promise<InvoicesData[] | undefined> => {
    if (!session || !user?.id) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${Invoices}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          throw new Error(data.message || 'Failed to fetch invoices')
        }
      })
      .catch((error) => {
        return error
      })
  }

  const {
    data: invoicesData,
    isLoading: isInvoicesDataLoading,
    error: invoicesError,
    isError: isInvoicesError,
  } = useQuery(InvoicesQueryKey, fetchInvoices, {
    staleTime: 1000 * 60, // 1 minute
  })

  const cancelSubscription = async ({
    cancellationReason,
  }: {
    cancellationReason: string
  }) => {
    if (!session || !user?.id || !membershipInfo?.subscription.id) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined

    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${CancelSubscription(
        membershipInfo.subscription.id
      )}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ cancellationReason }),
      }
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        }
      })
      .catch((error) => {
        return error
      })
  }

  const reactivateCancelledSubscription = async () => {
    if (!session || !user?.id || !membershipInfo?.subscription.id) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${ReactivateSubscription(
        membershipInfo.subscription.id
      )}`,
      { headers: { Authorization: `Bearer ${accessToken}` }, method: 'POST' }
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        }
      })
      .catch((error) => {
        return error
      })
  }

  const fetchDiscounts = async () => {
    const membershipInfo = await fetchMembershipInfo().then(
      (data) => data?.membershipInfo
    )

    if (!session || !user?.id || !membershipInfo?.subscription.priceId) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${DiscountOptions(
        membershipInfo?.subscription.priceId
      )}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
      .then((res) => {
        const data = res.json()
        return data
      })
      .catch(() => {
        return
      })
  }

  const {
    data: discountData,
    isLoading: isDiscountDataLoading,
    error: discountError,
    isError: isDicountError,
  } = useQuery(DiscountOptionsQueryKey, fetchDiscounts, {
    staleTime: 1000 * 60, // 1 minute
  })

  const applyDiscount = async (couponId: string | undefined) => {
    if (!couponId) {
      return
    }

    if (!session || !user?.id) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${ApplyDiscount(couponId)}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        method: 'POST',
      }
    )
      .then((res) => {
        const data = res.json()
        return data
      })
      .catch(() => {
        return
      })
  }

  const updateMonthlyInsuranceToAnnual = async () => {
    if (!session || !user?.id || !membershipInfo?.subscription.priceId) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${UpgradeToAnnual(
        membershipInfo.subscription.id
      )}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        }
      })
      .catch((error) => {
        return error
      })
  }

  const fetchPrice = async ({ priceId }: { priceId: string }) => {
    if (!session || !priceId) {
      return
    }

    setIsFetching(true)

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${PaymentPlan(priceId)}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        }
      })
      .catch((error) => {
        return error
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  return {
    isLoading:
      isLoading ||
      isMembershipInfoLoading ||
      isInvoicesDataLoading ||
      isDiscountDataLoading ||
      isFetching,
    error: invoicesError || discountError || membershipError,
    isError: isInvoicesError || isDicountError,
    stripePortalLink,
    membershipInfo,
    stripePortalUpdatePaymentLink,
    invoicesData,
    cancelSubscription,
    reactivateCancelledSubscription,
    refetch,
    discountData,
    applyDiscount,
    updateMonthlyInsuranceToAnnual,
    fetchPrice,
    noSubscription,
  }
}

export default useStripePortal
