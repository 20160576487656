import * as yup from 'yup'
import { ErrorMessages } from './constants'

const addressSchema = yup.object().shape({
  address_line1: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  address_line2: yup.string(),
  city: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  state: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  zip: yup.string().required(ErrorMessages.REQUIRED_FIELD),
})

export const paymentFormSchema = yup.object({
  firstName: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  lastName: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  addresses: yup.array().of(addressSchema).min(1),
  termsOfUse: yup
    .boolean()
    .oneOf([true], 'You must accept our "Terms of Use" to proceed'),
})

export const paymentFormNoTNCSchema = yup.object({
  firstName: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  lastName: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  addresses: yup.array().of(addressSchema).min(1),
})

interface AddressComponentType {
  types: string[]
  short_name: string
  long_name: string
}

export const getPostalCode = (
  addressComponents: AddressComponentType[]
): string => {
  const postalCode = addressComponents.find(
    ({ types = [] }) => types[0] === 'postal_code'
  ) || { short_name: '' }

  return postalCode.short_name
}

export const getCountry = (
  addressComponents: AddressComponentType[]
): string => {
  const postalCode = addressComponents.find(
    ({ types = [] }) => types[0] === 'country'
  ) || { short_name: '' }

  return postalCode.short_name
}

export const getAddressState = (
  addressComponents: AddressComponentType[],
  getLongName?: boolean
): string => {
  const state = addressComponents.find(
    ({ types = [] }) => types[0] === 'administrative_area_level_1'
  ) || { short_name: '', long_name: '' }

  if (getLongName) {
    return state.long_name
  }

  return state.short_name
}
