import { Context } from '@datadog/browser-core'
import { datadogLogs } from '@datadog/browser-logs'

export const ddLog = (
  logType: 'debug' | 'info' | 'warn' | 'error',
  message: string,
  messageContext?: Context
) => {
  switch (logType) {
    case 'debug':
      return datadogLogs.logger.debug(message, messageContext)
    case 'info':
      return datadogLogs.logger.info(message, messageContext)
    case 'warn':
      return datadogLogs.logger.warn(message, messageContext)
    case 'error':
      return datadogLogs.logger.error(message, messageContext)
  }
}
