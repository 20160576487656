"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MemberResource = void 0;
const BaseResource_1 = require("../BaseResource");
class MemberResource extends BaseResource_1.Resource {
    /**
     * Returns a list of members within the current account.
     *
     * The members returned are sorted by creation date, with the most recently added
     * members appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/members', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new member and registers them with Source.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/members', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing member. You need only supply the unique
     * member identifier that was returned upon member creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/members/${id}`, {
            options,
        });
    }
    /**
     * Updates the specified member by setting the values of the parameters passed.
     *
     * Any parameters not provided will be left unchanged. For example, if you pass the
     * email parameter, that becomes the member's active email to be used.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/members/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Deletes the specified member. Members that have meaningful objects associated
     * with them, such as tasks, threads, or documents, may not be deleted.
     */
    delete(id, options) {
        return this.source.request('DELETE', `/v1/members/${id}`, {
            contentType: 'json',
            options,
        });
    }
}
exports.MemberResource = MemberResource;
