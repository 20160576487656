import { memo } from 'react'
import { FormControl, FormControlProps } from 'react-bootstrap'

interface TextAreaProps extends FormControlProps {
  rows?: number
  placeholder?: string
}

const TextArea: React.FC<TextAreaProps> = ({
  className,
  rows = 3,
  ...props
}) => {
  return (
    <FormControl
      as="textarea"
      rows={rows}
      className={'border-dark ' + className}
      {...props}
    />
  )
}

export default memo(TextArea)
