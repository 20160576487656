"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiKeyAuthentication = void 0;
class ApiKeyAuthentication {
    /**
     * Creates a new API Key Authorization
     *
     * API keys can be used for server-to-server authentication to the Source API. When
     * using this authentication method, a Bearer token is applied to each outgoing request.
     *
     * @param id the API key id
     * @param secret the API key secret to send on outgoing requests
     */
    constructor(id, secret) {
        this.id = id;
        this.secret = secret;
    }
    /**
     * Attaches an Authorization header
     *
     * @returns the headers for the request
     */
    createHeaders() {
        return {
            Authorization: `Bearer ${this.secret}`,
        };
    }
    /**
     * Captures API keys from environment variables
     */
    static fromEnvironment() {
        const keyId = process.env.SOURCE_API_KEY_ID;
        const keySecret = process.env.SOURCE_API_KEY_SECRET;
        if (!keyId || !keySecret) {
            throw new Error('Unable to find Source API keys. Did you set SOURCE_API_KEY_ID and SOURCE_API_KEY_SECRET?');
        }
        return new ApiKeyAuthentication(keyId, keySecret);
    }
}
exports.ApiKeyAuthentication = ApiKeyAuthentication;
