import { PrimaryButton, SecondaryButton } from 'components/Button'
import Loader from 'components/Loader'
import { memo } from 'react'
import { Modal } from 'react-bootstrap'

import './styles.scss'

interface ConfirmModalProps {
  open: boolean
  title: string
  isLoading: boolean
  confirmText: string
  onConfirm: () => void
  handleClose: () => void
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  title,
  isLoading,
  confirmText,
  onConfirm,
  handleClose,
}) => {
  return (
    <Modal onHide={handleClose} show={open} centered className="confirm-modal">
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && (
          <div className="position-relative" style={{ height: '60px' }}>
            <Loader />
          </div>
        )}
        <div className="w-100 half-width-buttons">
          <div className="w-100 d-flex">
            <SecondaryButton
              variant="dark-blue"
              onClick={handleClose}
              disabled={isLoading}
            >
              No
            </SecondaryButton>
            <PrimaryButton
              variant="dark-blue"
              onClick={onConfirm}
              disabled={isLoading}
            >
              {confirmText}
            </PrimaryButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default memo(ConfirmModal)
