import { useSchedulingContext } from 'context/SchedulingContext/SchedulingProvider'
import { memo } from 'react'
import BookScreen from './BookScreen'
import ConfirmScreen from './ConfirmScreen'
import LocationScreen from './LocationScreen'
import SuccessScreen from './SuccessScreen'
import AppointmentTypeScreen from './AppointmentTypeScreen'

const SchedulingRouter: React.FC = () => {
  const { currentScreen } = useSchedulingContext()

  switch (currentScreen) {
    case 'appointmentTypeSelect':
      return <AppointmentTypeScreen />
    case 'location':
      return <LocationScreen />
    case 'book':
      return <BookScreen />
    case 'confirm':
      return <ConfirmScreen />
    case 'success':
      return <SuccessScreen />
    default:
      return <LocationScreen />
  }
}

export default memo(SchedulingRouter)
