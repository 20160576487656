import { Action, ActionDefinition } from 'hooks/useTimeline/useActions'
import { Link } from 'react-router-dom'
import { getContentUrl } from 'utils/library-content.utils'

interface ContentCardProps {
  action: Action
  actionDefinition: ActionDefinition
}

const ContentCard: React.FC<ContentCardProps> = ({ actionDefinition }) => {
  if (!actionDefinition.content) {
    return <></>
  }

  return (
    <div className="d-flex flex-column">
      {actionDefinition.content.map((content, i) => {
        const link = getContentUrl(content)

        if (!link) {
          return <></>
        }

        return (
          <Link
            key={i}
            to={link}
            target="_blank"
            rel="noreferrer"
            className="mb-2"
          >
            <u>
              {content.buttonText} {content.title}
            </u>
          </Link>
        )
      })}
    </div>
  )
}

export default ContentCard
