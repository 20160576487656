import UseOnboardingPageView from 'hooks/useOnboardingPageView'
import { useEffect } from 'react'
import './styles.scss'

interface BookConsultProps {
  redirect?: string
}

const BookConsult: React.FC<BookConsultProps> = ({ redirect }) => {
  const { trackPageView } = UseOnboardingPageView()

  // Track page view
  useEffect(() => {
    if (redirect) {
      trackPageView('bookConsult')
    }
  }, [])

  useEffect(() => {
    window.location.replace('https://allarahealth.com/consult-redirect')
  }, [])

  return null
}

export default BookConsult
