import { useStytch, useStytchSession } from '@stytch/react'
import { CheckPromoCode as CheckPromoCodeRoute } from 'api/payment/routes'
import axios from 'axios'
import { PaymentPlan } from 'context/OnboardingContext/OnboardingProvider'
import { createContext, useCallback, useContext, useState } from 'react'
import Stripe from 'stripe'

export interface PromoCode {
  code: string
  isValid: boolean
  discount?: Discount
  error?: string
}

export type Discount = {
  newAmount: string
  subtitle?: string
  duration_in_months?: number | null
}

interface PromoCodeContextInterface {
  isLoading: boolean
  promoCode?: PromoCode
  applyCode: (code: string) => Promise<PromoCode>
  resetPromoCode: () => void
}

export const PromoCodeContext = createContext<PromoCodeContextInterface>(
  {} as PromoCodeContextInterface
)

interface PromoCodeProviderProps {
  plan?: PaymentPlan
  children: React.ReactNode
}

export const PromoCodeProvider: React.FC<PromoCodeProviderProps> = ({
  plan,
  children,
}) => {
  const stytch = useStytch()
  const { session } = useStytchSession()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [promoCode, setPromoCode] = useState<PromoCode | undefined>(undefined)

  const computeDiscount = (coupon: Stripe.Coupon): Discount | undefined => {
    if (!plan) {
      return undefined
    }

    const oldAmount = plan.unit_amount
    let newAmount = oldAmount

    if (coupon.percent_off) {
      newAmount = (1 - coupon.percent_off / 100) * oldAmount
    } else if (coupon.amount_off) {
      newAmount = oldAmount - coupon.amount_off
    }

    const recurringText = plan.recurring ? `/${plan.recurring.interval}` : ''
    const newAmountText =
      newAmount <= 0
        ? 'Free'
        : `$${(newAmount / 100).toFixed(2)}${recurringText}`
    const andThenText = plan.recurring
      ? `(then $${plan.unit_amount / 100}/${plan.recurring.interval})`
      : ''

    let couponRecurringText = ''

    switch (coupon.duration) {
      case 'forever':
        couponRecurringText = plan.recurring
          ? newAmount <= 0
            ? `for the first 2 years ${andThenText}`
            : `until cancelled`
          : ''
        break
      case 'once':
        couponRecurringText = plan.recurring
          ? `for the first ${plan.recurring.interval} ${andThenText}`
          : ''
        break
      case 'repeating':
        couponRecurringText = plan.recurring
          ? `for the first ${coupon.duration_in_months} months ${andThenText}`
          : ''
        break
    }

    return {
      newAmount: newAmountText,
      subtitle: couponRecurringText,
      duration_in_months: coupon.duration_in_months,
    }
  }

  const applyCode = useCallback(
    async (code: string) => {
      setIsLoading(true)
      const defaultPromoCode = {
        code,
        isValid: false,
        error: 'Error processing, please reload the page and try again.',
      }

      if (!session || !plan) {
        setIsLoading(false)
        setPromoCode(defaultPromoCode)
        return defaultPromoCode
      }

      const tokens = stytch.session.getTokens()
      const accessToken = tokens ? tokens.session_jwt : undefined

      return axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}${CheckPromoCodeRoute}`,
          { code, priceId: plan.id },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((res) => {
          const coupon = res.data
          const discount = computeDiscount(coupon)
          const promo = {
            code,
            discount,
            isValid: true,
          }
          setIsLoading(false)
          setPromoCode(promo)
          return promo
        })
        .catch((err) => {
          const promo = {
            code,
            isValid: false,
            error: err.response.data.message,
          }
          setIsLoading(false)
          setPromoCode(promo)

          return promo
        })
    },
    [plan]
  )

  const resetPromoCode = () => setPromoCode(undefined)

  return (
    <PromoCodeContext.Provider
      value={{ isLoading, promoCode, applyCode, resetPromoCode }}
    >
      {children}
    </PromoCodeContext.Provider>
  )
}

export const usePromoCodeContext = (): PromoCodeContextInterface =>
  useContext(PromoCodeContext)
