import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { RoutePaths } from 'containers/Core/Routes'
import AppLogo from 'components/AppLogo'

const PageNotFound: React.FC = () => {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center height-full">
      <AppLogo className="position-absolute top-3 " />
      <p className="h1 text-center text-danger pb-6">
        Oops! The page you’re looking for was not found.
      </p>
      <p className="text-center fs-6">
        If you have an account, you can go to{' '}
        <Link to={RoutePaths.DASHBOARD}>Dashboard</Link>, or{' '}
        <Link to={RoutePaths.WAITLIST}>Join our Waitlist</Link> to be a part of
        Allara.
      </p>
    </Container>
  )
}

export default PageNotFound
