import { User } from '@allara-health/source-health-client'
import ProfileImage from 'components/SourceProfileImage/ProfileImage'
import { ReactComponent as ExternalLinkIcon } from 'assets/images/external-link.svg'
import { useCallback } from 'react'

interface ProviderDetailsProps {
  provider: User
  className?: string
  externalLink?: string
}

const ProviderDetails: React.FC<ProviderDetailsProps> = ({
  provider,
  className,
  externalLink,
}) => {
  const openExternalLink = useCallback(() => {
    window.open(externalLink, '_blank')
  }, [externalLink])

  return (
    <div className={`provider d-flex flex-wrap ${className}`}>
      <ProfileImage user={provider} className="mb-1 mr-2" />
      <div>
        <div className="d-flex align-items-center mb-1">
          <h1 className="ff-inter-medium fs-7 mb-0 mr-2">
            {provider.first_name} {provider.last_name}
          </h1>
          {externalLink !== undefined && (
            <ExternalLinkIcon
              className="cursor-pointer"
              onClick={openExternalLink}
            />
          )}
        </div>
        <h2 className="text-uppercase fs-2">{provider.display_title}</h2>
      </div>
    </div>
  )
}

export default ProviderDetails
