import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react'
import { Field } from 'formik'

interface FormInputsProps {
  htmlFor?: string
  label?: string
  inputtype?: string
  id?: string
  isDisabled?: boolean
  errors?: string
  validate?: (value: string) => string | undefined
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  value?: string
}

const FormInputs = (props: FormInputsProps) => {
  return (
    <FormControl
      isInvalid={!!props.errors}
      isRequired={props.required ?? false}
    >
      <FormLabel
        htmlFor={props.htmlFor}
        fontWeight={300}
        color={'var(--gray-dark)'}
      >
        {props.label}
      </FormLabel>
      <Field
        as={Input}
        id={props.id}
        type={props.inputtype}
        {...props}
        isDisabled={props.isDisabled}
        value={props.value}
      />
      <FormErrorMessage>{props.errors}</FormErrorMessage>
    </FormControl>
  )
}

export default FormInputs
