import { RoutePaths } from 'containers/Core/Routes'
import { useSchedulingContext } from 'context/SchedulingContext/SchedulingProvider'
import { Redirect } from 'react-router-dom'
import LegalText from './LegalText'
import { ReactComponent as ChevronLeft } from 'assets/images/chevron-left.svg'
import Loader from 'components/Loader'
import { PrimaryButton, SecondaryButton } from 'components/Button'
import { useCallback, useEffect, useState } from 'react'
import ProviderDetails from 'components/Scheduling/ProviderDetails'
import TimingDetails from 'components/Scheduling/TimingDetails'
import { useSourceContext } from 'context/SourceContext/SourceProvider'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import Checkbox from 'components/CheckboxGroup/Checkbox'

import './styles.scss'

const ConfirmScreen: React.FC<WithAccountProps> = ({ user }) => {
  const {
    provider,
    dateTime,
    timeZone,
    isReschedule,
    appointmentType,
    nextScreen,
    previousScreen,
    bookAppointment,
    rescheduleAppointment,
  } = useSchedulingContext()
  const { setAlertText } = useAlert()
  const { source } = useSourceContext()
  const [expired, setExpired] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [confirmLegal, setConfirmLegal] = useState<boolean>(false)

  const handleBook = useCallback(() => {
    setIsLoading(true)

    const handleAction = isReschedule ? rescheduleAppointment : bookAppointment

    handleAction()
      .then(() => nextScreen())
      .catch(() => {
        setAlertText(
          `Your appointment could not be ${
            isReschedule ? 'rescheduled' : 'booked'
          }. Please contact concierge for assistance.`,
          "Oops that didn't quite got to plan"
        )
      })
      .finally(() => setIsLoading(false))
  }, [
    source,
    appointmentType,
    timeZone,
    dateTime,
    provider,
    user.sourceHealthId,
  ])

  const handleConfirmLegal = useCallback(() => setConfirmLegal(!confirmLegal), [
    confirmLegal,
  ])

  useEffect(() => {
    const expiredTimeout = setTimeout(() => setExpired(true), 1000 * 60 * 5)

    return () => clearTimeout(expiredTimeout)
  }, [])

  if (!appointmentType) {
    return <Loader />
  } else if (!provider || !dateTime || !timeZone) {
    return <Redirect to={RoutePaths.SCHEDULING} />
  }

  const navigationButtons = (
    <>
      <SecondaryButton
        onClick={previousScreen}
        variant="dark-blue"
        className="fs-2 mr-0 mr-lg-4 next-button"
        disabled={isLoading}
      >
        <div className="d-flex align-items-center">
          <ChevronLeft />
          Previous
        </div>
      </SecondaryButton>
      <PrimaryButton
        onClick={handleBook}
        variant="dark-blue"
        disabled={expired || isLoading || !confirmLegal}
        className="fs-2 next-button"
        isLoading={isLoading}
      >
        {isReschedule ? 'Reschedule' : 'Book'}
      </PrimaryButton>
    </>
  )

  return (
    <div className="confirm screen-container">
      <h1 className="ff-inter-medium fs-8">Confirmation</h1>
      <p className="fs-4 sub-title">
        Please confirm the following information:
      </p>
      {expired && (
        <div className="alert ff-inter fs-2">
          <span className="text-uppercase">Your Session is Expired</span>
          <br />
          Please go back to the previous step to select available time of an
          appointment
        </div>
      )}
      <div className="d-flex flex-wrap align-items-center mb-3">
        <ProviderDetails provider={provider} className="mr-8" />
        <TimingDetails
          date={dateTime}
          timeZone={timeZone}
          appointmentType={appointmentType}
        />
      </div>
      <LegalText />
      <div className="my-3">
        <Checkbox
          className="small"
          controlId="legal-confirm"
          checked={confirmLegal}
          onChange={handleConfirmLegal}
          label="Please confirm you understand and agree to our Cancellation and No Show Policy"
        />
      </div>
      <div className="d-none d-lg-flex">{navigationButtons}</div>
      <div className="d-block d-lg-none fixed-bottom half-width-buttons">
        <div className="w-100 d-flex">{navigationButtons}</div>
      </div>
    </div>
  )
}

export default withAccount(ConfirmScreen)
