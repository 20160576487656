import { useStytch, useStytchSession } from '@stytch/react'
import { SelectProduct as SelectProductRoute } from 'api/common/routes'
import axios from 'axios'
import { createContext, useContext, useState } from 'react'

export type Program =
  | 'nutrition-only'
  | 'diagnostic'
  | 'full-program'
  | 'insurance'
  | 'insurance-diagnostic'
  | 'mnc'

export function isProgramType(input: string): input is Program {
  return [
    'nutrition-only',
    'diagnostic',
    'full-program',
    'insurance',
    'insurance-diagnostic',
    'mnc',
  ].includes(input)
}

interface MetadataType {
  title: string
  type: Program
  program: string
  savings?: string
  benefits: string
  agreementNote?: string
  hidden?: string
  commitment?: string
}

interface RecurringType {
  interval: string
  interval_count: number
}

export interface PaymentPlan {
  currency: string
  id: string
  unit_amount: number
  metadata: MetadataType
  recurring: RecurringType
  product: string
}

interface OnboardingContextInterface {
  selectedPlan: PaymentPlan | undefined
  selectedProgram: Program | undefined
  paymentDone: boolean
  insuranceDone: boolean
  acceptedTerms: boolean
  setSelectedPlan: (plan: PaymentPlan) => void
  setSelectedProgram: (program?: Program) => void
  toggleAcceptedTerms: () => void
  markPaymentComplete: () => void
  markInsuranceComplete: () => void
  setVariantLabel: (label: string) => void
  variantLabel: string
}

export const OnboardingContext = createContext<OnboardingContextInterface>(
  {} as OnboardingContextInterface
)

interface OnboardingProviderProps {
  children: React.ReactNode
}

export const OnboardingProvider: React.FC<OnboardingProviderProps> = ({
  children,
}) => {
  const stytch = useStytch()
  const { session } = useStytchSession()
  const [paymentDone, _setPaymentDone] = useState<boolean>(false)
  const [acceptedTerms, _setAcceptedTerms] = useState<boolean>(false)
  const [insuranceDone, _setInsuranceDone] = useState<boolean>(false)
  const [selectedPlan, _setSelectedPlan] = useState<PaymentPlan | undefined>(
    undefined
  )
  const [selectedProgram, _setSelectedProgram] = useState<Program | undefined>(
    undefined
  )
  const [variantLabel, _setVariantLabel] = useState<string>('')
  const markPaymentComplete = () => _setPaymentDone(true)
  const markInsuranceComplete = () => _setInsuranceDone(true)
  const toggleAcceptedTerms = () => _setAcceptedTerms((prev) => !prev)
  const setSelectedPlan = (plan: PaymentPlan) => _setSelectedPlan(plan)
  const setVariantLabel = (label: string) => _setVariantLabel(label)

  const setSelectedProgram = (program?: Program) => {
    _setSelectedProgram(program)

    if (program) {
      saveSelectedProgramToDatabase(program)
    }
  }

  const saveSelectedProgramToDatabase = async (program: Program) => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const session_jwt = tokens ? tokens.session_jwt : undefined
    axios.patch(
      `${process.env.REACT_APP_SERVER_URL}${SelectProductRoute}`,
      { program },
      { headers: { Authorization: `Bearer ${session_jwt}` } }
    )
  }

  return (
    <OnboardingContext.Provider
      value={{
        paymentDone,
        acceptedTerms,
        insuranceDone,
        selectedPlan,
        selectedProgram,
        variantLabel,
        setSelectedPlan,
        setSelectedProgram,
        toggleAcceptedTerms,
        markPaymentComplete,
        markInsuranceComplete,
        setVariantLabel,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )
}

export const useOnboardingContext = (): OnboardingContextInterface =>
  useContext(OnboardingContext)
