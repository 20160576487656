import { Container } from 'react-bootstrap'
import Loader from 'components/Loader'
import {
  OnboardingResponse,
  WaitlistUser,
} from 'hooks/useUser/useEligibilityInfo'
import useEligibilityInfo from 'hooks/useUser/useEligibilityInfo'

export interface WithEligibilityProps {
  waitlistUser: WaitlistUser
  onboardingResponse: OnboardingResponse
}

function withEligibility<T extends WithEligibilityProps>(
  WrappedComponent: React.ComponentType<T>
): React.FC<Omit<T, 'waitlistUser' | 'onboardingResponse'>> {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'

  // Creating the inner component. The calculated Props type here is where the magic happens.
  const ComponentWithEligibility: React.FC<
    Omit<T, 'waitlistUser' | 'onboardingResponse'>
  > = (props) => {
    const { isLoading, waitlistUser, onboardingResponse } = useEligibilityInfo()

    if (isLoading) {
      return <Loader />
    } else if (!waitlistUser || !onboardingResponse) {
      return (
        <Container className="d-flex flex-column justify-content-center height-full">
          <p className="h1 text-danger text-center">
            Some error occurred! Please try again later.
          </p>
        </Container>
      )
    }

    return (
      <WrappedComponent
        {...(props as T)}
        waitlistUser={waitlistUser}
        onboardingResponse={onboardingResponse}
      />
    )
  }

  ComponentWithEligibility.displayName = `withEligibility(${displayName})`

  return ComponentWithEligibility
}

export default withEligibility
