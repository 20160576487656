import { PillButton } from 'components/Button'
import { Form } from 'react-bootstrap'
import OnboardingContainer from '../OnboardingContainer'
import { Links } from '../Payment/constants'
import { ReactComponent as RightIcon } from 'assets/images/chevron-right.svg'
import { useCallback, useState } from 'react'
import Loader from 'components/Loader'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import useUser from 'hooks/useUser/useUser'
import { useHistory } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'

import './styles.scss'
import { Amplitude } from '../../../utils/amplitude.utils'
import { PROGRAMS } from 'utils/program.utils'

const ConsentToTelehealth: React.FC = () => {
  const history = useHistory()
  const { setAlertText } = useAlert()
  const { handleAcceptTerms } = useUser()
  const {
    acceptedTerms,
    selectedProgram,
    toggleAcceptedTerms,
    variantLabel,
  } = useOnboardingContext()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleAccept = useCallback(() => {
    if (!acceptedTerms) {
      return
    }

    Amplitude.consentToTelehealth(variantLabel)

    setIsSubmitting(true)

    handleAcceptTerms()
      .then(() => {
        if (
          selectedProgram === PROGRAMS.INSURANCE ||
          selectedProgram === PROGRAMS.INSURANCE_DIAGNOSTIC
        ) {
          history.push(RoutePaths.INSURANCE_INFO)
        } else {
          history.push(RoutePaths.DASHBOARD)
        }
      })
      .catch(() => {
        setIsSubmitting(false)
        setAlertText(
          'Unable to electronically accept terms. Please refresh the page and try again',
          'An Error Occurred'
        )
      })
  }, [history, acceptedTerms])

  return (
    <OnboardingContainer>
      <div className="d-flex justify-content-center align-items-center">
        <div className="consent-to-telehealth d-flex justify-content-center align-items-center align-self-center my-4">
          <div className="d-flex justify-content-center align-items-center">
            <div className="justify-content-center align-items-center">
              <h2 className="fs-7 text-center header ff-inter-medium">
                Consent To Telehealth and Privacy Policy
              </h2>
              <Form.Group
                className="tnc-container d-flex align-items-start pt-3"
                controlId="termsOfUse"
              >
                <Form.Check
                  checked={acceptedTerms}
                  onChange={toggleAcceptedTerms}
                />
                <Form.Label className="fs-4 mx-3 text-transform-none">
                  By checking this box you acknowledge that you have received
                  the Allara Medical Group&apos;s{' '}
                  <a
                    href={Links.NoticeOfPrivacyPractices}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notice of Privacy Practices
                  </a>{' '}
                  &amp; you have{' '}
                  <a
                    href={Links.TelehealthConsent}
                    target="_blank"
                    rel="noreferrer"
                  >
                    consented to Telehealth
                  </a>
                </Form.Label>
              </Form.Group>
              <div>
                <PillButton
                  className="checkout-button position-relative justify-content-between w-100"
                  onClick={handleAccept}
                  block
                  disabled={isSubmitting || !acceptedTerms}
                >
                  {isSubmitting ? (
                    <Loader />
                  ) : (
                    <>
                      <div>Accept</div>
                      <div className="continue-button">
                        <RightIcon />
                      </div>
                    </>
                  )}
                </PillButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OnboardingContainer>
  )
}

export default ConsentToTelehealth
