export const ErrorMessages = {
  INVALID_EMAIL: 'Must be a valid email',
  REQUIRED_FIELD: 'This field is required!',
  UNDER_13: 'You must be 13 years old to submit this form',
  INVALID_DOB: 'Please enter a valid Date of Birth',
  ADDRESS_REQUIRED: 'Please select an address',
  OPTION_REQUIRED: 'Select at least 1 option',
}

export const Links = {
  TermsOfUse: `${process.env.REACT_APP_SHARING_URL}/terms-conditions`,
  PrivacyPolicy: `${process.env.REACT_APP_SHARING_URL}/privacy-policy`,
  HowItWorks: `${process.env.REACT_APP_SHARING_URL}/how-it-works`,
  FAQ: `${process.env.REACT_APP_SHARING_URL}/FAQ`,
  ContactUs: `${process.env.REACT_APP_SHARING_URL}/contact`,
  TelehealthConsent: `${process.env.REACT_APP_SHARING_URL}/telehealth-informed-consent`,
  NoticeOfPrivacyPractices: `${process.env.REACT_APP_SHARING_URL}/notice-of-privacy-practices`,
}
