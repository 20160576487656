import { useFormikContext } from 'formik'
import InputContainer from 'hoc/InputContainer'
import { Typeahead, TypeaheadComponentProps } from 'react-bootstrap-typeahead'
import {
  Option,
  TypeaheadProps,
  TypeaheadState,
} from 'react-bootstrap-typeahead/types/types'

interface TypeaheadSelectProps extends TypeaheadComponentProps {
  controlId: string
  label: string
  error?: string
}

export const TypeaheadSelect: React.FC<TypeaheadSelectProps> = ({
  controlId,
  label,
  error,
  ...props
}) => {
  const { setFieldValue } = useFormikContext()

  const onChange = (selected: Option[]) => {
    if (selected.length) {
      const value = selected[0] as Record<string, string>
      setFieldValue(controlId, value['id'])
    } else {
      setFieldValue(controlId, null)
    }
  }

  function isMatch(
    input: string,
    string: string,
    caseSensitive: boolean
  ): boolean {
    let searchStr = input
    let str = string

    if (!caseSensitive) {
      searchStr = searchStr.toLowerCase()
      str = str.toLowerCase()
    }

    return str.indexOf(searchStr) !== -1
  }

  const getOptionProperty = (option: Option, key: string) => {
    if (typeof option === 'string') {
      return undefined
    }

    return option[key]
  }

  /**
   * Default algorithm for filtering results.
   */
  const defaultFilterBy = (
    option: Option,
    props: Omit<TypeaheadProps, 'onChange'> & TypeaheadState
  ): boolean => {
    const { labelKey, text, options, caseSensitive } = props

    const fields: string[] = options.map((o) =>
      typeof o === 'string' ? o : (o as Record<string, string>)['label']
    )

    if (typeof labelKey === 'string') {
      // Add the `labelKey` field to the list of fields if it isn't already there.
      if (fields.indexOf(labelKey) === -1) {
        fields.unshift(labelKey)
      }
    }

    return fields.some((field: string) => {
      let value = getOptionProperty(option, field)

      if (typeof value !== 'string') {
        value = String(value)
      }

      // Ensure that even when text input does not match 'other' that is shown
      // when there are no other options
      if (
        !isMatch(text, 'other', false) &&
        isMatch('other', value as string, false)
      ) {
        // Only show other when no other matches
        const remainingOptions = fields.filter((o) =>
          isMatch(text, o, caseSensitive)
        )

        return remainingOptions.length === 0
      }

      return isMatch(text, value as string, caseSensitive)
    })
  }

  return (
    <InputContainer controlId={controlId} label={label} error={error}>
      <Typeahead
        id={controlId}
        onChange={onChange}
        filterBy={defaultFilterBy}
        {...props}
      />
    </InputContainer>
  )
}
