import { CloseIcon } from '@chakra-ui/icons'
import { Box, Flex, IconButton, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { File } from '@allara-health/source-health-client'

interface FilePreviewProps {
  files: File[]
  onRemove: (file: File) => void
}

export const FilePreview: React.FC<FilePreviewProps> = ({
  files,
  onRemove,
}) => {
  return (
    <Box mt={2} width={'50%'}>
      {files?.map((attachment, index) => (
        <Flex
          key={index}
          p={2}
          border="1px solid #ccc"
          borderRadius="md"
          mb={2}
          alignItems="center"
        >
          {attachment.mime_type.startsWith('image/') && (
            <Image
              src={attachment.url}
              alt={attachment.name}
              boxSize="40px"
              objectFit="cover"
              borderRadius="md"
              mr={2}
            />
          )}
          <Box flex="1">
            <Text mb={0} fontWeight="bold">
              {attachment.name}
            </Text>
            <Text mb={0} fontSize="sm" color="gray.500">
              {attachment.mime_type}
            </Text>
          </Box>
          <IconButton
            size="sm"
            aria-label="Remove file"
            icon={<CloseIcon />}
            onClick={() => onRemove(attachment)}
          />
        </Flex>
      ))}
    </Box>
  )
}
