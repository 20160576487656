import { useQuery } from 'react-query'
import {
  Banner as BannerRoute,
  QueryKey as BannerQueryKey,
} from 'api/banner/routes'
import { useStytch, useStytchSession } from '@stytch/react'

export type SanityBanner = {
  title: string
  bannerStatus: boolean
  description: SanityBlock
}

export type SanityBlock = {
  _type: string
  children: SanityBlockChild[]
  markDefs: SanityBlockMarkDef[]
}

interface SanityBlockMarkDef {
  _key: string
  _type: string
  color: string
}

export type SanityBlockChild = {
  _key: string
  _type: string
  marks: string[]
  text: string
}
interface UseSanityBannerInterface {
  isLoading: boolean
  error: unknown
  banner?: SanityBanner
}

export enum BannerConstants {
  MESSAGING = 'messaging-banner',
  DASHBOARD = 'dashboard-banner',
}

const useBanner = (name: string): UseSanityBannerInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchBanner = async (): Promise<SanityBanner | undefined> => {
    if (!session) {
      return undefined
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${BannerRoute}${name}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        if (res.ok) {
          return await res.json()
        } else {
          return undefined
        }
      })
      .catch(() => {
        return undefined
      })
  }

  const { isLoading, error, data: banner } = useQuery(
    [BannerQueryKey, name],
    fetchBanner,
    {
      staleTime: Infinity,
    }
  )

  return {
    isLoading,
    error,
    banner,
  }
}

export default useBanner
