import { Action, ActionDefinition } from 'hooks/useTimeline/useActions'
import AtHomeTestingForm from './AtHomeTestingForm'
import DownloadAppForm from './DownloadAppForm'
import HowLongLabsForm from './HowLongLabsForm'
import JoinNutritionOnlyProgramForm from './JoinNutritionOnlyProgramForm'
import JoinPCOSProgram from './JoinPCOSProgram'
import ShareAddressForm from './ShareAddressForm'
import ShareInsuranceForm from './ShareInsuranceForm'
import UploadLabsForm from './UploadLabsForm'
import GLP1WeightForm from './GLP1WeightForm'
import ScheduledCompletedLabs from './ScheduledCompletedLabs'

export interface CardFormProps {
  action: Action
  actionDefinition: ActionDefinition
}

const CardForm: React.FC<CardFormProps> = (props) => {
  switch (props.action.definition) {
    case 'how-long-labs':
      return <HowLongLabsForm />
    case 'share-insurance':
      return <ShareInsuranceForm {...props} />
    case 'share-address':
      return <ShareAddressForm {...props} />
    case 'upload-labs':
      return <UploadLabsForm {...props} />
    case 'at-home-testing':
      return <AtHomeTestingForm />
    case 'join-nutrition-prog':
      return <JoinNutritionOnlyProgramForm {...props} />
    case 'join-pcos-program':
      return <JoinPCOSProgram {...props} />
    case 'download-app':
      return <DownloadAppForm {...props} />
    case 'glp1-weight':
      return <GLP1WeightForm {...props} />
    case 'completed-labs':
    case 'scheduled-labs':
      return <ScheduledCompletedLabs {...props} />
    default:
      return <></>
  }
}

export default CardForm
