import { memo, useEffect, useState } from 'react'
import { WithAccountProps } from 'hoc/withAccount'
import { Heading, useToast } from '@chakra-ui/react'
import LoadingState from 'components/Spinner'
import EditProfileForm, { optionalToast } from './EditProfileForm'
import CurrentMembership from './CurrentMembership'
import CancelMembership from './CancelMembership'
import { useRouteMatch } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { User } from 'hooks/useUser/useUser'
import { useLocation } from 'react-router-dom'

import './styles.scss'

interface ProfileProps {
  user: User
}

const Profile: React.FC<WithAccountProps> = ({ user }: ProfileProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const match = useRouteMatch()
  const { selfServiceCancellation } = useFlags()
  const location = useLocation()
  const optionalToast = (location?.state as { optionalToast?: optionalToast })
    ?.optionalToast

  const toast = useToast()

  useEffect(() => {
    if (optionalToast) {
      toast({
        title: optionalToast.title,
        description: optionalToast.description,
        status: optionalToast.status,
        duration: 5000,
        isClosable: true,
      })
    }
  }, [optionalToast])

  return (
    <>
      <Heading as="h3" size="m" fontWeight={'semibold'}>
        Account
      </Heading>
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          {(() => {
            switch (match.path) {
              case RoutePaths.DASHBOARD_PROFILE:
                return (
                  <EditProfileForm user={user} setIsLoading={setIsLoading} />
                )
              case RoutePaths.MANAGE_MEMBERSHIP:
                if (!selfServiceCancellation) {
                  return null
                }

                return <CurrentMembership />
              case RoutePaths.MANAGE_MEMBERSHIP_CANCEL:
                if (!selfServiceCancellation) {
                  return null
                }

                return <CancelMembership />
            }
          })()}
        </>
      )}
    </>
  )
}

export default memo(Profile)
