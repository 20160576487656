"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const http = require("http");
const https = require("https");
const SourceError_1 = require("../SourceError");
const utils_1 = require("../utils");
function hasHeaders(content) {
    return (!!content &&
        typeof content === 'object' &&
        typeof content.getHeaders === 'function');
}
class NodeHttpClient {
    constructor(options = {}) {
        this.options = options;
    }
    request(request) {
        return new Promise((resolve, reject) => {
            var _a;
            const { timeout } = (_a = request.options) !== null && _a !== void 0 ? _a : {};
            const { headers, data } = this.serializeContent(request.data, request.contentType);
            const url = (0, utils_1.createUrl)(request.baseUrl, request.path, request.query);
            const isInsecureConnection = url.protocol === 'http:';
            let agent = this.options.agent;
            if (!agent) {
                agent = isInsecureConnection
                    ? NodeHttpClient.defaultHttpAgent
                    : NodeHttpClient.defaultHttpsAgent;
            }
            const req = (isInsecureConnection ? http : https).request({
                agent,
                host: url.hostname,
                port: url.port,
                path: url.pathname + url.search,
                method: request.method,
                timeout,
                headers: Object.assign(Object.assign({}, headers), request.headers),
                ciphers: 'DEFAULT:!aNULL:!eNULL:!LOW:!EXPORT:!SSLv2:!MD5',
            });
            req.once('response', (res) => {
                let data = '';
                res.on('data', (chunk) => {
                    data += chunk;
                });
                res.on('end', () => {
                    var _a;
                    try {
                        const parsed = JSON.parse(data); // eslint-disable-line
                        if (parsed.object === 'error') {
                            // eslint-disable-line
                            reject(SourceError_1.SourceError.from(parsed));
                            return;
                        }
                        resolve({
                            status: (_a = res.statusCode) !== null && _a !== void 0 ? _a : 0,
                            headers: res.headers,
                            data: parsed,
                        });
                    }
                    catch (ex) {
                        if (this.options.dangerouslyLogRequestExceptions) {
                            // eslint-disable-next-line no-console
                            console.error('Failed to resolve data, Source caught this error:', ex, data);
                        }
                        reject(new SourceError_1.SourceError({
                            type: 'client_error',
                            code: 'invalid_response',
                            message: 'Invalid response received from the API',
                            cause: ex,
                        }));
                    }
                });
            });
            req.on('error', (error) => {
                if ((0, utils_1.isTimeoutError)(error)) {
                    return reject(new SourceError_1.SourceError({
                        type: 'client_error',
                        code: 'request_timeout',
                        message: `Request aborted due to timeout being reached (${timeout !== null && timeout !== void 0 ? timeout : 0}ms)`,
                        cause: error,
                    }));
                }
                return reject(new SourceError_1.SourceError({
                    type: 'client_error',
                    code: 'connection_failed',
                    message: `Unable to connect to ${req.host}`,
                    cause: error,
                }));
            });
            if (request.contentType === 'multipart') {
                ;
                request.data.pipe(req);
            }
            else {
                req.once('socket', (socket) => {
                    if (socket.connecting) {
                        socket.once(isInsecureConnection ? 'connect' : 'secureConnect', () => {
                            // Send payload; we're safe:
                            req.write(data !== null && data !== void 0 ? data : '');
                            req.end();
                        });
                    }
                    else {
                        // we're already connected
                        req.write(data !== null && data !== void 0 ? data : '');
                        req.end();
                    }
                });
            }
        });
    }
    serializeContent(content, contentType = 'json') {
        if (typeof content === 'undefined') {
            return {};
        }
        switch (contentType) {
            case 'json':
                return {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: JSON.stringify(content),
                };
            case 'multipart':
                if (hasHeaders(content)) {
                    return {
                        headers: content.getHeaders(),
                    };
                }
                throw new Error('Multipart requests require request data as FormData instance');
        }
    }
}
exports.default = NodeHttpClient;
// Default HTTP agent to use for all outgoing requests
NodeHttpClient.defaultHttpAgent = new http.Agent({ keepAlive: true });
// Default HTTPs agent to use for all outgoing requestsAlive: true })
NodeHttpClient.defaultHttpsAgent = new https.Agent({ keepAlive: true });
