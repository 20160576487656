"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createUrl = exports.isTimeoutError = exports.serializeQuery = exports.toUrlEncoded = void 0;
function writeFlattenedValue(input, // eslint-disable-line
prefix = null, stringify = false, values = {}) {
    if (typeof input === 'string' || typeof input === 'number' || typeof input === 'boolean') {
        values[prefix !== null && prefix !== void 0 ? prefix : ''] = stringify ? String(input) : input;
    }
    else if (Array.isArray(input)) {
        for (let i = 0; i < input.length; i++) {
            writeFlattenedValue(input[i], `${prefix !== null && prefix !== void 0 ? prefix : ''}[${i}]`, stringify, values);
        }
        // Blob is only supported in the browser and not node - we currently don't support multipart request with the NodeAdpater either
    }
    else if (typeof Blob !== 'undefined' && input instanceof Blob) {
        values[prefix !== null && prefix !== void 0 ? prefix : ''] = input;
    }
    else if (input) {
        for (const [key, value] of Object.entries(input)) {
            writeFlattenedValue(value, prefix ? `${prefix}[${key}]` : key, stringify, values);
        }
    }
}
function toUrlEncoded(input, stringify = false) {
    const values = {};
    writeFlattenedValue(input, '', stringify, values);
    return values;
}
exports.toUrlEncoded = toUrlEncoded;
/**
 * Serializes an object into a query string
 *
 * @param input the object that needs to be converted
 * @returns a flattened query parameter
 */
function serializeQuery(input) {
    let query = '';
    for (const [key, value] of Object.entries(input)) {
        query += `${query ? '&' : ''}${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
    }
    return query;
}
exports.serializeQuery = serializeQuery;
/**
 *
 */
function isTimeoutError(error) {
    return error.code && error.code === 'ETIMEDOUT'; // eslint-disable-line
}
exports.isTimeoutError = isTimeoutError;
/**
 * Create a new URL from a base and appended path
 *
 * @param base the base URL to interpret relative from
 * @param path the target URL to hit
 * @param query query parameters to append
 * @return the created URL
 */
function createUrl(base, path, query) {
    const basePath = new URL(path, base);
    // If there were no query params provided, there's nothing to do
    if (!query) {
        return basePath;
    }
    // Otherwise, we need to serialize query params
    const serializedQuery = serializeQuery(toUrlEncoded(query));
    if (!serializedQuery) {
        return basePath;
    }
    // Append to existing query params, or attach them
    basePath.search = basePath.search ? basePath.search + '&' + serializedQuery : serializedQuery;
    return basePath;
}
exports.createUrl = createUrl;
