import { ChakraPrimaryButton, PrimaryButton } from 'components/Button'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import useInsurance, { Insurance } from 'hooks/useInsuranceCard'
import React, { FormEvent, useCallback, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
const ALLOWED_TYPES = ['image/jpg', 'image/jpeg', 'image/png']
import { QueryKey as InsuranceQueryKey } from 'api/insurance/routes'
interface UploadInsuranceCardFormProps {
  buttonText: string
  buttonColor?: 'blue' | 'green'
  showNoInsurance: boolean
  onSubmit: () => void
}

const UploadInsuranceCardForm: React.FC<UploadInsuranceCardFormProps> = ({
  buttonText,
  showNoInsurance,
  onSubmit,
}) => {
  const queryClient = useQueryClient()
  const { setAlertText } = useAlert()
  const backFileRef = useRef<HTMLInputElement>(null)
  const frontFileRef = useRef<HTMLInputElement>(null)
  const { handleUploadInsuranceCard } = useInsurance()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [backFileName, setBackFileName] = useState<string>('')
  const [frontFileName, setFrontFileName] = useState<string>('')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault()
    setIsLoading(true)
    const backFile = backFileRef?.current?.files?.[0]
    const frontFile = frontFileRef?.current?.files?.[0]

    if (!frontFile) {
      setAlertText(
        'Please upload an image for the front of the card.',
        'An Error Occurred'
      )
      return
    } else if (!backFile) {
      setAlertText(
        'Please upload an image for the back of the card.',
        'An Error Occurred'
      )
      return
    }

    // Upload all of the files
    await handleUploadInsuranceCard({ front: frontFile, back: backFile })
    onSubmit()
    setIsLoading(false)
  }

  const handleChooseBackFile = useCallback(
    () => handleChooseFile(backFileRef, setBackFileName),
    [backFileRef, setBackFileName]
  )

  const handleChooseFrontFile = useCallback(
    () => handleChooseFile(frontFileRef, setFrontFileName),
    [frontFileRef, setFrontFileName]
  )

  const handleChooseFile = useCallback(
    (
      fileRef: React.RefObject<HTMLInputElement>,
      setName: (name: string) => void
    ) => {
      if (fileRef?.current?.files?.[0]) {
        // Validate size of file and add name
        const file = fileRef.current.files[0]

        if (file) {
          if (file.size > 5000000) {
            setAlertText(
              `File ${file.name} is too large. Must be less than 5MB. For larger files please email concierge@allarahealth.com`,
              'An Error Occurred'
            )
          } else if (!ALLOWED_TYPES.includes(file.type)) {
            setAlertText(
              `File must be a JPG or PNG. For other files please email concierge@allarahealth.com`,
              'An Error Occurred'
            )
          } else {
            setName(file?.name)
          }
        }
      }
    },
    []
  )
  const handleNoInsurance = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault()
      onSubmit()
      await queryClient.cancelQueries(InsuranceQueryKey)
      queryClient.getQueryData<Insurance>(InsuranceQueryKey)
    },
    []
  )

  const handleFrontButtonClick = () => {
    frontFileRef.current?.click()
  }

  const handleBackButtonClick = () => {
    backFileRef.current?.click()
  }

  return (
    <div className="upload-labs-form">
      <form onSubmit={handleSubmit}>
        <input
          id="choose-front-input"
          className="file-input"
          ref={frontFileRef}
          type="file"
          accept="image/jpeg,image/jpg,image/png"
          onChange={handleChooseFrontFile}
        />
        <label htmlFor="choose-front-input">
          <ChakraPrimaryButton onClick={handleFrontButtonClick}>
            Upload front of card
          </ChakraPrimaryButton>
        </label>
        <p>
          {frontFileName.length ? (
            <span>{frontFileName}</span>
          ) : (
            <span>
              Upload an image of the front of the card. Please insure all
              details are clear and visible.
            </span>
          )}
        </p>
        <input
          id="choose-back-input"
          className="file-input"
          ref={backFileRef}
          type="file"
          accept="image/jpeg,image/jpg,image/png"
          onChange={handleChooseBackFile}
        />
        <label htmlFor="choose-back-input">
          <ChakraPrimaryButton onClick={handleBackButtonClick}>
            Upload back of card
          </ChakraPrimaryButton>
        </label>
        <p>
          {backFileName.length ? (
            <span>{backFileName}</span>
          ) : (
            <span>
              Upload an image of the back of the card. Please insure all details
              are clear and visible.
            </span>
          )}
        </p>
        <p>
          <i>
            Note: Uploading a new insurance card will replace any existing one.
          </i>
        </p>

        <ChakraPrimaryButton
          type="submit"
          disabled={!frontFileName || !backFileName || isLoading}
        >
          {buttonText}
        </ChakraPrimaryButton>
        {showNoInsurance && (
          <div className="my-4">
            <ChakraPrimaryButton onClick={handleNoInsurance}>
              I don&apos;t have insurance
            </ChakraPrimaryButton>
          </div>
        )}
      </form>
    </div>
  )
}

export default UploadInsuranceCardForm
