import {
  Expandable,
  Resource,
  Thread,
  User,
} from '@allara-health/source-health-client'
import { formatDate } from './time.utils'

export function getAssignee(thread: Thread): User {
  if (thread.assignee) {
    return thread.assignee as User
  } else {
    return {
      title: '',
      suffix: '',
      object: 'user',
      id: 'usr_default',
      first_name: 'Allara',
      last_name: 'Care Team',
      display_title: 'Allara Care Team',
      email: 'concierge@allarahealth.com',
      role: 'support',
      time_zone: 'America/New_York',
      licensed: false,
      groups: [],
      status: 'active',
      profile_image: null,
      created_at: '2022-05-09T13:24:49.679Z',
      updated_at: '2022-05-09T13:24:49.679Z',
      deactivated_at: null,
    }
  }
}

export function getAssigneeName(assignee: User): string {
  return (
    assignee.display_title ?? `${assignee.first_name} ${assignee.last_name}`
  )
}

export function getDate(thread: Thread): string {
  return formatDate(new Date(thread.last_message_at), 'mm.dd.yyyy')
}

export function isUnread(thread: Thread): boolean {
  const lastMessageSender = thread.last_message.sender
  const lastMessageSenderId =
    typeof lastMessageSender === 'string'
      ? lastMessageSender
      : lastMessageSender.id

  if (!thread.member_last_read) {
    return true
  } else if (lastMessageSenderId.startsWith('mem_')) {
    return false
  } else if (
    new Date(thread.last_message_at) > new Date(thread.member_last_read)
  ) {
    return true
  } else {
    return false
  }
}

export function isRead(thread: Thread): boolean {
  return !isUnread(thread)
}

export function expand<T extends Resource>(input: Expandable<T>): T {
  if (typeof input === 'string') {
    throw new Error('Expected resource to be expanded')
  }

  return input
}
