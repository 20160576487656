import { RoutePaths } from 'containers/Core/Routes'
import {
  ArticleContent,
  Content,
  VideoContent,
  WorksheetContent,
} from 'hooks/useLibrary'

/**
 * Attempt to get the patient app url for the content
 *
 * @param content - the sanity library Content
 * @returns relative url if exists or undefined
 */
export function getContentUrl(content: Content): string | undefined {
  const path = getContentPath(content)

  if (path) {
    return `${RoutePaths.CONTENT}?type=${content.type}&id=${btoa(path)}`
  }
}

function getContentPath(content: Content): string | undefined {
  switch (content.type) {
    case 'video':
      return (content as VideoContent).video
    case 'worksheet':
      return (content as WorksheetContent).file
    case 'article':
      return (content as ArticleContent).id
  }
}
