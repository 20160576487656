import { useStytch, useStytchSession } from '@stytch/react'
import {
  PaymentPlans as PaymentPlansRoute,
  QueryKey as PlansQueryKey,
} from 'api/payment/routes'
import {
  PaymentPlan,
  Program,
} from 'context/OnboardingContext/OnboardingProvider'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

interface UsePaymentPlansInterface {
  isLoading: boolean
  error: unknown
  plans: PaymentPlan[]
}

const usePaymentPlans = (type?: Program): UsePaymentPlansInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()

  const route = useMemo(() => (type ? PaymentPlansRoute(type) : null), [type])

  const fetchPlans = async (): Promise<PaymentPlan[]> => {
    if (!session || !route) {
      return []
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${route}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        const data: PaymentPlan[] = await res.json()

        if (res.ok) {
          // Sort plans so that any ones with savings appear first
          return data.sort((a, b) => {
            if (a.metadata.savings) {
              return -1
            } else if (b.metadata.savings) {
              return 1
            } else {
              return 0
            }
          })
        } else {
          return []
        }
      })
      .catch((err) => {
        throw err
      })
  }

  const { isLoading, error, data } = useQuery([PlansQueryKey, type], fetchPlans)

  const plans = useMemo(() => data ?? [], [data])

  return { isLoading, error, plans }
}

export default usePaymentPlans
