"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayerResource = exports.PayerStatus = void 0;
const BaseResource_1 = require("../BaseResource");
var PayerStatus;
(function (PayerStatus) {
    PayerStatus["ACTIVE"] = "active";
    PayerStatus["INACTIVE"] = "inactive";
})(PayerStatus = exports.PayerStatus || (exports.PayerStatus = {}));
class PayerResource extends BaseResource_1.Resource {
    /**
     * Returns a list of payers within the current account. The payers returned are sorted
     * by creation date, with the most recently added payer appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/payers', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new payer. You can create a new payer with a unique name and a display
     * color of your choice. If a payer already exists with the same name (case
     * insensitive), an error is returned.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/payers', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieve a payer by its unique identifier.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/payers/${id}`, {
            options,
        });
    }
    /**
     * Updates the specified payer by setting the values of the parameters passed. Any
     * parameters not provided will be left unchanged.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/payers/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Deletes the specified payer. A deleted payer is removed from all members to which it
     * relates.
     */
    delete(id, options) {
        return this.source.request('DELETE', `/v1/payers/${id}`, {
            contentType: 'json',
            options,
        });
    }
}
exports.PayerResource = PayerResource;
