import { memo } from 'react'
import { Route, RouteProps } from 'react-router'

import { useStytchSession } from '@stytch/react'
import Login from 'containers/Login'

interface ProtectedRouteProps extends RouteProps {
  path: string
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  path,
  ...props
}) => {
  const { session } = useStytchSession()

  if (!session) {
    return <Login />
  }

  return (
    <Route path={path} {...props}>
      {children}
    </Route>
  )
}

export default memo(ProtectedRoute)
