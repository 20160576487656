import React from 'react'
import { Provider } from 'react-redux'
import TagManager from 'react-gtm-module'
import App from './containers/Core/App'
import reportWebVitals from './reportWebVitals'
import store from 'app/store'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { init } from '@amplitude/analytics-browser'
import { createRoot } from 'react-dom/client'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { StytchProvider } from '@stytch/react'
import { StytchHeadlessClient } from '@stytch/vanilla-js/dist/index.headless'

import 'assets/fonts/Canela-Thin.ttf'
import 'assets/fonts/Ovo-Regular.ttf'
import 'assets/fonts/Inter-Regular.ttf'
import 'assets/fonts/Inter-Medium.ttf'
import 'assets/fonts/Inter-Bold.ttf'
import { StytchUIClient } from '@stytch/vanilla-js'

const renderApp = async () => {
  if (
    process.env.REACT_APP_GTM_ID &&
    process.env.REACT_APP_GTM_AUTH_KEY &&
    process.env.REACT_APP_GTM_ENV_ID
  ) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
      auth: process.env.REACT_APP_GTM_AUTH_KEY,
      preview: `env-${process.env.REACT_APP_GTM_ENV_ID}`,
    }
    TagManager.initialize(tagManagerArgs)
  }

  if (process.env.REACT_APP_DATADOG_TOKEN) {
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_TOKEN,
      forwardConsoleLogs: 'all',
      forwardErrorsToLogs: true,
      service: process.env.REACT_APP_URL,
      env: process.env.VERCEL_ENV,
      sampleRate: 100, // the percentage of sessions to track
    })
  }

  if (
    process.env.REACT_APP_DATADOG_APPLICATION_ID &&
    process.env.REACT_APP_DATADOG_CLIENT_TOKEN
  ) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: process.env.REACT_APP_DATADOG_SITE,
      service: process.env.REACT_APP_DATADOG_SERVICE,
      env: 'prod',
      sampleRate: 100,
      trackInteractions: true,
      replaySampleRate: 100,
      defaultPrivacyLevel: 'mask',
      trackFrustrations: true,
    })
  }

  if (process.env.REACT_APP_AMPLITUDE_KEY) {
    // Initialize Amplitude
    init(process.env.REACT_APP_AMPLITUDE_KEY)
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: `${process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID}` || '',
    context: {
      key: 'allara-user',
    },
  })

  const stytch = new StytchUIClient(
    process.env.REACT_APP_STYTCH_PUBLIC_TOKEN ||
      'public-token-test-1a512f8c-bb60-4c94-a741-6f5efb7e82e1'
  )

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const container = document.getElementById('root')
  const root = container ? createRoot(container) : null

  if (root) {
    root.render(
      <React.StrictMode>
        <StytchProvider stytch={stytch}>
          <LDProvider>
            <Provider store={store}>
              <App />
            </Provider>
          </LDProvider>
        </StytchProvider>
      </React.StrictMode>
    )
  }

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}

renderApp()
