// import Loader from 'components/Loader'
import LoadingState from 'components/Spinner'
import useAppointments from 'hooks/useAppointments/useAppointments'
import { useEffect, useMemo, useState } from 'react'
import AppointmentCard from './AppointmentCard'

const POLLING_INTERVAL = 2000
const MAX_REFETCHES = 1

const Appointments: React.FC<{ performRefetch?: boolean }> = ({
  performRefetch,
}) => {
  const { isLoading, nonCancelledAppointments, refetch } = useAppointments()
  const [maxNumberShown, setMaxNumberShown] = useState<number>(3)
  const [refetchCount, setRefetchCount] = useState<number>(0)
  const [isPolling, setIsPolling] = useState<boolean>(false) // New state for polling

  const handleShowMore = () => setMaxNumberShown((n) => n + 3)
  const handleShowLess = () => setMaxNumberShown((n) => n - 3)

  const appointmentsToShow = useMemo(
    () => nonCancelledAppointments.slice(0, maxNumberShown),
    [nonCancelledAppointments, maxNumberShown]
  )

  // We want to poll for new appointments if we are coming from the scheduling flow
  // This is to ensure that we are showing the most up to date appointment status from our db
  useEffect(() => {
    if (performRefetch) {
      setIsPolling(true) // Start polling
      const interval = setInterval(() => {
        if (refetchCount < MAX_REFETCHES) {
          refetch()
          setRefetchCount((count) => count + 1)
        } else {
          clearInterval(interval)
          setIsPolling(false) // End polling
        }
      }, POLLING_INTERVAL)

      return () => clearInterval(interval)
    }
  }, [refetch, refetchCount, performRefetch])

  return (
    <div>
      <p className="h1 py-2 fs-8">Appointments</p>
      {(isLoading || isPolling) && <LoadingState />}{' '}
      {!isLoading &&
        !isPolling &&
        nonCancelledAppointments.length >= 1 &&
        nonCancelledAppointments.map((appointment) => {
          return (
            <AppointmentCard key={appointment.id} appointment={appointment} />
          )
        })}
      {!isLoading && !isPolling && nonCancelledAppointments.length >= 1 && (
        <div className="d-flex justify-content-between">
          <p className="show-more-less-text" onClick={handleShowLess}>
            {maxNumberShown === 3 ? '' : 'Show Less'}
          </p>
          <p className="show-more-less-text" onClick={handleShowMore}>
            {appointmentsToShow.length > maxNumberShown ? 'Show More' : ''}
          </p>
        </div>
      )}
      {!isLoading && !isPolling && nonCancelledAppointments.length === 0 && (
        <div>
          <p>
            You have no appointments. When it is time to book your first
            appointment you will see it listed as an action above.
          </p>
        </div>
      )}
    </div>
  )
}

export default Appointments
