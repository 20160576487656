import withAccount, { WithAccountProps } from 'hoc/withAccount'
import { isInsurancePatient } from 'utils/program.utils'
import './styles.scss'

const LegalText: React.FC<WithAccountProps> = ({ user }) => {
  return (
    <div className="legal-text">
      {isInsurancePatient(user) && (
        <>
          <p className="fs-2 text-uppercase mb-0">Billing Info:</p>
          <p className="fs-2">
            You will be responsible for any co-pays or charges your insurance
            does not cover. We will use the credit/debit card on file to charge
            your co-pay on the day of your visit. We&apos;ll then submit your
            bill to insurance for you, and let you know if there is any
            outstanding charge remaining (or in some cases, a refund). We know
            that not all insurances cover nutrition benefits, so if your
            insurance does not cover nutrition services, we have a nutrition
            visit cash pay price of $50 per visit. But we will attempt to bill
            for these visits before we charge you.
          </p>
        </>
      )}
      <p className="fs-2 text-uppercase mb-0">Cancellation Policy:</p>
      <p className="fs-2">
        To respect our providers time, all appointments must be cancelled{' '}
        <strong>at least 24 hours in advance.</strong> Your provider will wait 5
        minutes past the start of your appointment before you&apos;re marked as
        a no-show. Violations of this policy will result in a $50 charge. Thank
        you for understanding.
      </p>
    </div>
  )
}

export default withAccount(LegalText)
