import * as yup from 'yup'
import { ErrorMessages } from './constants'

export const checkEligibilityFormSchema = yup.object({
  firstName: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  lastName: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  email: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  dob: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  gender: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  zipCode: yup.string().required(ErrorMessages.REQUIRED_FIELD),
  termsOfUse: yup
    .boolean()
    .oneOf([true], 'You must accept our "Terms of Use" to proceed'),
})
