import { Member } from '@allara-health/source-health-client'
import { MemberQueryKey, TimeZone as TimeZoneRoute } from 'api/source/routes'
import axios, { AxiosResponse } from 'axios'
import { useSourceContext } from 'context/SourceContext/SourceProvider'
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { useStytch, useStytchSession } from '@stytch/react'

interface UseMemberInterface {
  member?: Member
  isLoading: boolean
  handleUpdateTimeZone: UseMutateAsyncFunction<
    AxiosResponse<void> | undefined,
    unknown,
    string,
    unknown
  >
}

const useMember = (): UseMemberInterface => {
  const queryClient = useQueryClient()
  const { source } = useSourceContext()
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchMember = async (): Promise<Member | undefined> => {
    return source.members.retrieve('current')
  }

  const { data: member, isLoading } = useQuery(MemberQueryKey, fetchMember)

  const updateTimeZone = async (timeZone: string) => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios.patch(
      `${process.env.REACT_APP_SERVER_URL}${TimeZoneRoute}`,
      { timeZone },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
  }

  const mutateUpdate = useMutation(updateTimeZone, {
    onSettled: () => queryClient.invalidateQueries(MemberQueryKey),
    onMutate: async (timeZone: string) => {
      await queryClient.cancelQueries(MemberQueryKey)

      const previousData = queryClient.getQueryData<Member>(MemberQueryKey)

      if (previousData) {
        queryClient.setQueryData<Member>(MemberQueryKey, {
          ...previousData,
          time_zone: timeZone,
        })
      }

      return { previousData }
    },
  })

  const handleUpdateTimeZone = mutateUpdate.mutateAsync

  return { member, isLoading, handleUpdateTimeZone }
}

export default useMember
