"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAuthentication = void 0;
class UserAuthentication {
    /**
     * Creates a new User Key authorization
     *
     * This authentication type can be used when there is a valid session on the Source UI. It should not
     * be used by third party developers, generally.
     *
     * @param secret the user secreet
     * @param liveMode whether or not to use live mode
     */
    constructor(secret, liveMode = true) {
        this.secret = secret;
        this.liveMode = liveMode;
    }
    /**
     * Attaches an Authorization header
     *
     * @returns the headers for the request
     */
    createHeaders() {
        return {
            Authorization: `Bearer ${this.secret}`,
            'Source-Live-Mode': this.liveMode ? 'true' : 'false',
        };
    }
}
exports.UserAuthentication = UserAuthentication;
