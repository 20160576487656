import useGoals from 'hooks/useGoals'
import { memo } from 'react'
import { ReactComponent as CheckIcon } from 'assets/images/check.svg'
import { ReactComponent as GoalIcon } from 'assets/images/goals.svg'

const CompletedGoals: React.FC = () => {
  const { completedGoals } = useGoals()

  return (
    <>
      <p className="spaced-capital-letters fs-3 d-flex align-items-center">
        <GoalIcon className="goals-icon" />
        Completed Goals
      </p>
      <div>
        {completedGoals.map((goal) => (
          <div key={goal.id} className="d-flex align-items-center mb-3">
            <div className="check-icon-container">
              <CheckIcon />
            </div>
            {goal.frequencyPerWeek ? (
              <div className="w-100">
                You will {goal.text.toLowerCase()} {goal.frequencyPerWeek} days
                per week
              </div>
            ) : (
              <div className="w-100">{goal.text}</div>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default memo(CompletedGoals)
