import { RoutePaths } from 'containers/Core/Routes'
import CustomLink from './CustomLink'
import { BlogUrl } from '../constants'
import { memo, useCallback } from 'react'
import { useStytch } from '@stytch/react'

const NonPatientNavigation: React.FC = () => {
  const stytchClient = useStytch()

  const onLogout = useCallback(() => {
    stytchClient.session.revoke()
    window.location.href = window.location.origin + RoutePaths.LOGIN
  }, [stytchClient])

  return (
    <>
      <CustomLink to={RoutePaths.DASHBOARD}>Dashboard</CustomLink>
      <CustomLink to={RoutePaths.DASHBOARD_PROFILE}>PROFILE</CustomLink>
      <CustomLink to={BlogUrl} target="_blank" rel="noreferrer">
        BLOG
      </CustomLink>
      <CustomLink to="#" onClick={onLogout}>
        LOG OUT
      </CustomLink>
    </>
  )
}

export default memo(NonPatientNavigation)
