"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EncounterResource = void 0;
const BaseResource_1 = require("../../BaseResource");
class EncounterResource extends BaseResource_1.Resource {
    /**
     * Returns a list of encounters within the current account.
     *
     * The encounters by default are sorted by creation date, with the most recently
     * created encounter appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/encounters', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new encounter for the specified member. An encounter requires at least
     * a start timestamp and can optionally include resources related to the encounter.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/encounters', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing encounter. You need only supply the unique
     * encounter identifier that was returned upon creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/encounters/${id}`, {
            options,
        });
    }
    /**
     * Updates the specified encounter by setting the values of the parameters passed.
     * Any parameters not provided will be left unchanged.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/encounters/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Deletes an encounter.
     *
     * Deleting an encounter is only possible when any associated notes are themselves
     * deleted. The associated encounter.deleted event will contain the array of
     * related resources for the encounter as it existed prior to deletion. When
     * retrieving deleted encounters, note that the array or related resources will be
     * empty.
     */
    delete(id, options) {
        return this.source.request('DELETE', `/v1/encounters/${id}`, {
            contentType: 'json',
            options,
        });
    }
}
exports.EncounterResource = EncounterResource;
