import useThreads from 'hooks/useThreads/useThreads'
import { memo } from 'react'
import { Flex, Box, Skeleton, SkeletonCircle, VStack } from '@chakra-ui/react'
import ThreadPreview from '../Chat/ThreadPreview'

const ChatNavigation: React.FC = () => {
  const { threads, isLoading } = useThreads()

  return (
    <VStack spacing={4} align="stretch" overflowY="auto">
      <Box
        overflowY="auto"
        p={2}
        w="full"
        maxH="calc(100vh - 150px)"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray.300',
          },
        }}
        id="scrollableThreads"
      >
        {threads?.map((thread) => (
          <ThreadPreview key={thread.id} thread={thread} />
        ))}
      </Box>
      <Flex direction="row" alignItems="center" p={4}>
        <SkeletonCircle size="10" isLoaded={!isLoading} mr={4} />
        <Skeleton flex="1" height="20px" isLoaded={!isLoading} />
      </Flex>
    </VStack>
  )
}

export default memo(ChatNavigation)
