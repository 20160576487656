"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EncounterTypeResource = void 0;
const BaseResource_1 = require("../../BaseResource");
class EncounterTypeResource extends BaseResource_1.Resource {
    /**
     * Returns a list of encounter types within the current account. The encounter
     * types returned are sorted by creation date, with the most recently added
     * encounter type appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/encounter_types', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new encounter type in Source.
     *
     * After creating an encounter type, you can relate the encounter type to
     * appointment types and forms in order to begin creating encounters.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/encounter_types', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves an encounter type by its unique identifier.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/encounter_types/${id}`, {
            options,
        });
    }
    /**
     * Updates the specified encounter type by setting the values of the parameters
     * passed. Any parameters not provided will be left unchanged.
     *
     * Encounters are linked to encounter types in Source, so some changes made to an
     * encounter type will be visible on past encounters. However, changes to fields
     * that influence encounter behavior, such as the queue, will apply only to new
     * tasks that are related to open encounters.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/encounter_types/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Archives the specified encounter type. An archived encounter type can be viewed
     * on any encounter it relates to but cannot be used to create new encounters.
     */
    archive(id, params, options) {
        return this.source.request('POST', `/v1/encounter_types/${id}/archive`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Unarchives the specified encounter type. Once unarchived, the encounter type can
     * be used to create new encounters.
     */
    unarchive(id, options) {
        return this.source.request('POST', `/v1/encounter_types/${id}/unarchive`, {
            contentType: 'json',
            options,
        });
    }
}
exports.EncounterTypeResource = EncounterTypeResource;
