import {
  PillButton,
  SecondaryButton,
  SecondaryPillButton,
} from 'components/Button'
import { RoutePaths } from 'containers/Core/Routes'
import useHowDidYouHear, {
  HowDidYouHear as HowDidYouHearOption,
} from 'hooks/useHowDidYouHear'
import UseOnboardingPageView from 'hooks/useOnboardingPageView'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import OnboardingContainer from '../OnboardingContainer'

import './styles.scss'
import { Amplitude } from 'utils/amplitude.utils'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import { isPatient } from 'utils/program.utils'
import TextField from 'components/TextField'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'

interface HowDidYouHearProps extends WithAccountProps {
  canRedirect?: boolean
}

const HowDidYouHear: React.FC<HowDidYouHearProps> = ({ user, canRedirect }) => {
  const history = useHistory()
  const { trackPageView } = UseOnboardingPageView()
  const {
    howDidYouHear: options,
    shareHowDidYouHear,
    updateOnboardingHowDidYouHear,
  } = useHowDidYouHear()
  const { variantLabel } = useOnboardingContext()
  const [shared, setShared] = useState<boolean>(false)
  const [selected, setSelected] = useState<HowDidYouHearOption[] | undefined>(
    undefined
  )
  const [providerReferred, setProviderReferred] = useState<string>('')

  const providerReferredSelected = useMemo(
    () => selected?.find((v) => v.value === 'Referred by Provider'),
    [selected]
  )

  const handleProviderReferredChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setProviderReferred(event.target.value)
    },
    []
  )

  const toggleSelected = useCallback((value: HowDidYouHearOption) => {
    setSelected((current) => {
      if (!current) {
        return [value]
      } else if (current.find((v) => v.id === value.id)) {
        return current.filter((v) => v.id !== value.id)
      } else {
        return [...current, value]
      }
    })
  }, [])

  const handleContinue = useCallback(
    (cta?: 'share' | 'skip') => {
      const howDidYouHears = selected?.map((value) => value.value)
      Amplitude.howDidYouHear(howDidYouHears ?? [], cta ?? 'skip', variantLabel)

      const route = isPatient(user)
        ? `${RoutePaths.CONSENT_TO_TELEHEALTH}${window.location.search}`
        : RoutePaths.DASHBOARD

      history.push(route)
    },
    [window.location.search]
  )

  const handleShareHowDidYouHear = useCallback(() => {
    if (selected) {
      shareHowDidYouHear(selected)

      if (providerReferredSelected !== undefined) {
        updateOnboardingHowDidYouHear(providerReferred)
      }
    }

    if (canRedirect) {
      handleContinue('share')
    } else {
      setShared(true)
    }
  }, [selected, providerReferred, user.waitlistUser.id, canRedirect])

  // Track page view
  useEffect(() => {
    if (window.location.href.includes(RoutePaths.BOOK_CONSULT_COMPLETE)) {
      trackPageView('bookConsultComplete')
    }
  }, [])

  const isShareButtonDisabled = useMemo(
    () =>
      (selected?.length ?? 0) < 1 ||
      (providerReferredSelected && providerReferred.length < 1),
    [selected, providerReferredSelected, providerReferred]
  )

  return (
    <OnboardingContainer>
      <div className="d-none conversion-email">{user?.email}</div>
      <div className="d-flex justify-content-center pb-5">
        <div className="how-did-you-hear mt-5 py-3 m-4">
          <h1 className="ff-inter-medium header text-center">Done</h1>
          {!shared && (
            <>
              <p className="ff-inter-medium fs-6 sub-header text-center">
                How did you hear about us?
              </p>
              <p className="ff-inter fs-2 info-text text-center">
                (Select all that apply)
              </p>
              <div className="d-flex flex-column ff-inter">
                {options?.map((option) => (
                  <SecondaryButton
                    key={option.id}
                    onClick={() => toggleSelected(option)}
                    className={
                      selected?.find((v) => v.id === option.id)
                        ? 'secondary-button-active fs-5'
                        : 'fs-5'
                    }
                  >
                    {option.value}
                  </SecondaryButton>
                ))}
              </div>
              {providerReferredSelected !== undefined && (
                <div className="mt-3 px-4">
                  <TextField
                    onChange={handleProviderReferredChange}
                    value={providerReferred}
                    placeholder="Provider or practice name"
                  />
                </div>
              )}
              <div className="px-4">
                <PillButton
                  className="share-button justify-content-center mt-3 w-100"
                  onClick={handleShareHowDidYouHear}
                  disabled={isShareButtonDisabled}
                >
                  Share
                </PillButton>
                {canRedirect && (
                  <SecondaryPillButton
                    className="ff-inter-medium text-center link mt-2 d-flex justify-content-center align-self-center w-100"
                    onClick={() => handleContinue('skip')}
                  >
                    Skip
                  </SecondaryPillButton>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </OnboardingContainer>
  )
}

export default withAccount(HowDidYouHear)
