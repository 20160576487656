import { AppointmentType } from '@allara-health/source-health-client'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import momentTZ from 'moment-timezone'
import { addMinutes } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { memo } from 'react'

interface TimingDetailsProps extends WithAccountProps {
  date: Date
  timeZone: string
  appointmentType: AppointmentType
  className?: string
}

const TimingDetails: React.FC<TimingDetailsProps> = ({
  user,
  date,
  timeZone,
  appointmentType,
  className,
}) => {
  return (
    <div className={`appointment-details ${className}`}>
      <p className="appointment-details-metadata fs-5 mb-0">
        {user.waitlistUser.state.fullName},{' '}
        {momentTZ.tz(timeZone).format('z [(GMT] Z[)]')}
      </p>
      <p className="ff-inter-medium fs-6 mb-0">
        {formatInTimeZone(date, timeZone, 'EEEE, MMMM d, yyyy')}
      </p>
      <p className="ff-inter-medium fs-6 mb-0">
        {formatInTimeZone(date, timeZone, 'h:mm	aaa')} -{' '}
        {formatInTimeZone(
          addMinutes(date, appointmentType.duration),
          timeZone,
          'h:mm aaa'
        )}
      </p>
    </div>
  )
}

export default memo(withAccount(TimingDetails))
