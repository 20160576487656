import Linkify from 'components/Linkify'
import { AppointmentNote } from 'hooks/useAppointments/useAppointmentNotes'
import { Modal, ModalProps } from 'react-bootstrap'
import { formatName } from 'utils/provider.utils'
import { formatDate } from 'utils/time.utils'
import '../styles.scss'

interface AppointmentNoteModalProps extends ModalProps {
  note: AppointmentNote | null
}

const AppointmentNoteModal: React.FC<AppointmentNoteModalProps> = ({
  note,
  onHide,
}) => {
  if (!note) {
    return <></>
  }

  return (
    <Modal
      onHide={onHide}
      show={note !== null}
      size="lg"
      centered
      className="appointment-note-modal"
    >
      <Modal.Header className="fs-6" closeButton>
        Note from Appointment on {formatDate(new Date(note.appointment.time))}{' '}
        with {formatName(note.appointment.provider)}
      </Modal.Header>
      <Modal.Body className="py-3 justify-content-center p-4">
        <Linkify>
          <pre className="ff-inter-medium">
            <p className="note-text">{note.text}</p>
          </pre>
        </Linkify>
        <div className="mt-4">
          Attachments:
          {note.attachments?.length ? (
            note.attachments.map((attachment) => (
              <li key={attachment.id}>
                <a href={attachment.fileUrl} target="_blank" rel="noreferrer">
                  {attachment.fileName}
                </a>
              </li>
            ))
          ) : (
            <p>None</p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AppointmentNoteModal
