import useActions from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'
import UploadInsuranceCardForm from '../../MyHealth/Insurance/UploadInsuranceCardForm'
import { CardFormProps } from '.'
import { useToast } from '@chakra-ui/react'

const ShareInsuranceForm: React.FC<CardFormProps> = ({
  action,
  actionDefinition,
}) => {
  const { handleCompleteAction } = useActions()
  const toast = useToast()

  const onSubmit = useCallback(() => {
    handleCompleteAction(action.id)
    toast({
      title: 'Action completed',
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }, [action, handleCompleteAction])

  return (
    <UploadInsuranceCardForm
      showNoInsurance={true}
      onSubmit={onSubmit}
      buttonColor="green"
      buttonText={actionDefinition.buttonText}
    />
  )
}

export default ShareInsuranceForm
