"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const sign_1 = require("jose/jwt/sign"); // eslint-disable-line
const SourceError_1 = require("../SourceError");
const authentication_1 = require("../authentication");
class NodeTokenGenerator {
    constructor(configuration) {
        this.configuration = configuration;
        this.encoder = new TextEncoder();
    }
    /**
     * Generates a JWT suitable to allow a member to access the API
     *
     * @param options options for configuring the generated token
     * @returns a generated token that allows a member access to the API
     */
    generate(options) {
        return __awaiter(this, void 0, void 0, function* () {
            // Handle backwards-compatible 'member' alias
            const validatedOptions = this.validateOptions(options);
            return yield this.generateWithValidOptions(validatedOptions);
        });
    }
    /**
     * The actual implementation after accounting for backwards compatibility.
     */
    generateWithValidOptions(options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const authentication = this.configuration.getAuthentication();
            if (!(authentication instanceof authentication_1.ApiKeyAuthentication)) {
                throw new Error('You may only generate tokens when using API key authentication');
            }
            const signJWT = new sign_1.SignJWT({
                sub: options.subject,
                act: options.actor,
                iat: Math.floor(Date.now() / 1000),
                exp: Math.floor(options.expiration.getTime() / 1000),
                scopes: (_a = options.scopes) !== null && _a !== void 0 ? _a : [],
            });
            return yield signJWT
                .setProtectedHeader({ alg: 'HS256', kid: authentication.id })
                .sign(this.encoder.encode(authentication.secret));
        });
    }
    /**
     * Validate and transform the options to account for us allowing 'member' as a deprecated alias for 'subject'.
     * 1. We must have a subject (as 'subject' OR 'member')
     * 2. If using the member alias, there must not be 'subject' or 'actor' defined, since it is ambiguous and we only
     *    allow it for backwards compatibility.
     */
    validateOptions(options) {
        var _a;
        if (options.member) {
            if (options.subject || options.actor) {
                throw new SourceError_1.SourceError({
                    message: "The 'member' token option is deprecated and cannot be combined with 'subject' or 'actor' options",
                });
            }
        }
        const subject = (_a = options.subject) !== null && _a !== void 0 ? _a : options.member;
        if (!subject) {
            throw new SourceError_1.SourceError({
                message: "You must supply either 'subject' or the deprecated alias 'member' token options",
            });
        }
        return {
            subject,
            actor: options.actor,
            expiration: options.expiration,
            scopes: options.scopes,
        };
    }
}
exports.default = NodeTokenGenerator;
