"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CareTeamResource = void 0;
const BaseResource_1 = require("../BaseResource");
class CareTeamResource extends BaseResource_1.Resource {
    /**
     * Retrieves the details of an existing care team. You need only supply the unique
     * care team identifier that was returned upon creation, or that is associated with
     * a particular member (see the `care_team` property in the
     * [Member](/docs/api/reference/member/) object).
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/care_teams/${id}`, {
            options,
        });
    }
    /**
     * Updates the specified care team by setting the values of the parameters passed.
     *
     * Any parameters not provided will be left unchanged. For example, if you pass the
     * member parameter, that assigns the care team to the given member.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/care_teams/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
}
exports.CareTeamResource = CareTeamResource;
