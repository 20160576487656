import Loader from 'components/Loader'
import { memo, useEffect } from 'react'

const RedirectToOnboarding: React.FC = () => {
  useEffect(() => {
    const formsortUrl = `${process.env.REACT_APP_ONBOARDING_URL}`
    let search = `${window.location.search}`

    if (formsortUrl.includes('?')) {
      search = search.replace('?', '&')
    }

    window.location.href = `${formsortUrl}${search}`
  }, [])

  return <Loader />
}

export default memo(RedirectToOnboarding)
