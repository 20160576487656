import { ChakraPrimaryButton } from 'components/Button'
import DateInput from 'components/DatePicker/SingleDateInput'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import useLabs, { Lab } from 'hooks/useLabs'
import React, { Fragment, useMemo } from 'react'
import { FormEvent, useCallback, useRef, useState } from 'react'

interface UploadLabsFormProps {
  buttonText: string
  buttonColor?: 'blue' | 'green'
  lab?: Lab
  onSubmit: () => void
}

const UploadLabsForm: React.FC<UploadLabsFormProps> = ({
  buttonText,
  buttonColor,
  lab = undefined,
  onSubmit,
}) => {
  const { setAlertText } = useAlert()
  const { isSubmitting, handleUploadLabs, handleUpdateLabs } = useLabs()
  const importFileRef = useRef<HTMLInputElement>(null)
  const [fileNames, setFileNames] = useState<string[]>([])
  const defaultDate = useMemo(() => new Date(), [])
  const [documented, setDocumented] = useState<string>(
    defaultDate.toISOString()
  )

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault()

    if (lab) {
      handleUpdateSubmit(lab)
    } else {
      handleUploadSubmit()
    }
  }

  const handleUploadSubmit = () => {
    const fileList = importFileRef?.current?.files

    if (!fileList?.length) {
      setAlertText(
        'You did not select any files to be uploaded.',
        'An Error Occurred'
      )
      return null
    } else if (!documented) {
      setAlertText(
        'Please select a date for the labs. If you do not have an exact date please estimate to the best of your ability.',
        'An Error Occurred'
      )
      return null
    }

    // Filter to only valid files
    const files: File[] = []

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i)

      if (!file) {
        continue
      } else if (fileNames.includes(file.name)) {
        files.push(file)
      }
    }

    // Upload all of the files
    handleUploadLabs({ files, documented }).then(() => onSubmit())
  }

  const handleUpdateSubmit = (lab: Lab) => {
    if (!documented) {
      setAlertText(
        'Please select a date for the labs. If you do not have an exact date please estimate to the best of your ability.',
        'An Error Occurred'
      )
      return null
    }

    handleUpdateLabs({ lab, documented }).then(() => onSubmit())
  }

  const handleChooseFile = useCallback(() => {
    if (importFileRef?.current?.files?.length) {
      // Validate size of all files and push names
      const names = []

      for (let i = 0; i < importFileRef.current.files.length; i++) {
        const file = importFileRef.current.files.item(i)

        if (file) {
          if (file.size > 5000000) {
            setAlertText(
              `File ${file.name} is too large. Must be less than 5MB. For larger files please email concierge@allarahealth.com`,
              'An Error Occurred'
            )
          } else {
            names.push(file?.name)
          }
        }
      }

      setFileNames(names)
    }
  }, [importFileRef])

  const handleReportedChange = useCallback(
    (date: Date) => {
      setDocumented(date.toISOString())
    },
    [setDocumented]
  )

  const handleButtonClick = () => {
    importFileRef.current?.click()
  }

  const isDisabled = useMemo(() => {
    if (!documented) {
      return true
    } else if (isSubmitting) {
      return true
    } else if (!lab && fileNames.length === 0) {
      return true
    }
  }, [documented, lab, isSubmitting, fileNames])

  return (
    <div className="upload-labs-form">
      <form onSubmit={handleSubmit}>
        {!lab && (
          <>
            <input
              multiple
              id="choose-file-input"
              className="file-input"
              ref={importFileRef}
              type="file"
              accept="image/*,.pdf"
              onChange={handleChooseFile}
              style={{ display: 'none' }}
            />
            <label htmlFor="choose-file-input">
              <ChakraPrimaryButton onClick={handleButtonClick}>
                Choose file(s)
              </ChakraPrimaryButton>
            </label>
            <p>
              {fileNames.length ? (
                <span>
                  {fileNames.map((fileName) => (
                    <Fragment key={fileName}>
                      {fileName}
                      <br />
                    </Fragment>
                  ))}
                </span>
              ) : (
                <span>
                  Choose file(s) to import. Note: you can select multiple.
                </span>
              )}
            </p>
          </>
        )}
        <label htmlFor="date-picker">When were these labs done?</label>
        <DateInput
          onChange={handleReportedChange}
          value={new Date(documented)}
          maxDate={defaultDate}
        />
        <ChakraPrimaryButton
          type="submit"
          disabled={isDisabled}
          isLoading={isSubmitting}
        >
          {buttonText}
        </ChakraPrimaryButton>
      </form>
    </div>
  )
}

export default UploadLabsForm
