import React, { useState, useRef, ChangeEvent } from 'react'
import { Box, IconButton, Flex, useToast } from '@chakra-ui/react'
import { AttachmentIcon } from '@chakra-ui/icons'
import { FiSend } from 'react-icons/fi'
import TextareaAutosize from 'react-textarea-autosize'
import useThreads from 'hooks/useThreads/useThreads'
import {
  Message,
  Thread as SourceThread,
  File as SourceFile,
} from '@allara-health/source-health-client'
import { FilePreview } from './FilePreview'
import { useThreadContext } from '../../../context/Threads/ThreadContext'

interface MessageInputProps {
  thread: SourceThread
}

const MessageInput = ({ thread: thread }: MessageInputProps) => {
  const [message, setMessage] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<SourceFile[]>([])
  const { uploadFileToSource } = useThreads()
  const toast = useToast()
  const { handleSend } = useThreadContext()

  const send = async () => {
    if (message.trim() === '') {
      toast({
        title: 'Cannot send empty message.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      })
      return
    }

    try {
      handleSend({
        message,
        threadId: thread.id,
        attachments: files,
      })
    } catch (ex) {
      toast({
        title: 'Error sending message.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      return
    }

    setMessage('')
    setFiles([])
  }

  const handleAttachClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      try {
        const uploadedFile = await uploadFileToSource(file)

        if (uploadedFile) {
          setFiles((prevFiles) => [...prevFiles, uploadedFile])
        }
      } catch (ex) {
        toast({
          title: 'Error uploading file.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        return
      }
    }
  }

  const handleRemoveFile = (fileToRemove: SourceFile) => {
    setFiles((prevFiles) =>
      prevFiles.filter((file) => file.id !== fileToRemove.id)
    )
  }

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={3}
      display="flex"
      flexDirection="column"
    >
      <Box display="flex" alignItems="center">
        <TextareaAutosize
          minRows={1}
          maxRows={5}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter message..."
          style={{
            resize: 'none',
            width: '100%',
            border: 'none',
            outline: 'none',
          }}
        />
        <Flex alignItems="center" ml={2}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <IconButton
            aria-label="Attach file"
            icon={<AttachmentIcon />}
            onClick={handleAttachClick}
            mr={2}
          />
          <IconButton
            aria-label="Send message"
            icon={<FiSend />}
            onClick={send}
          />
        </Flex>
      </Box>
      <FilePreview files={files} onRemove={handleRemoveFile} />
    </Box>
  )
}

export default MessageInput
