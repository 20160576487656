import useLabOrders from 'hooks/useLabOrders'
import useLabs from 'hooks/useLabs'
import { useMemo } from 'react'
import { memo } from 'react'
import LabOrderRow from './LabOrderRow'
import LabRow from './LabRow'

const Labs: React.FC = () => {
  const { labs } = useLabs()
  const { labOrders } = useLabOrders()

  const labComponents = useMemo(
    () =>
      labs.map((lab) => {
        const date = lab.documented
          ? new Date(lab.documented)
          : new Date(lab.uploaded)
        return {
          date: date.getTime(),
          component: <LabRow key={lab.id} lab={lab} />,
        }
      }),
    [labs]
  )

  const labOrderComponents = useMemo(
    () =>
      labOrders.map((labOrder) => ({
        date: new Date(labOrder.signedDate).getTime(),
        component: <LabOrderRow key={labOrder.id} labOrder={labOrder} />,
      })),
    [labOrders]
  )

  const labsAndImaging = useMemo(() => {
    const components = [...labComponents, ...labOrderComponents]
    return components.sort((a, b) => a.date - b.date)
  }, [labComponents, labOrderComponents])

  return (
    <div className="labs-container mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <p className="spaced-capital-letters my-3">Labs and Imaging</p>
      </div>
      <p className="fs-2">
        Occasionally, your provider may ask you to share prior lab work or
        imaging results. If needed, your provider will direct you how to upload
        these items.
      </p>
      <div className="files-container pt-1">
        {labsAndImaging.map((component) => component.component)}
      </div>
      {labs.length === 0 && (
        <>
          <p>You don&apos;t have any labs uploaded.</p>
          <p className="fs-2">
            Once we receive your labs and a provider signs off on them they will
            be available here for download. If you have previous labs you would
            like to share with your provider please click the Upload button
            above.
          </p>
        </>
      )}
    </div>
  )
}

export default memo(Labs)
