"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JWTAuthentication = void 0;
class JWTAuthentication {
    /**
     * Creates a new token authentication
     *
     * @param secret a token generated by the SDK
     */
    constructor(token) {
        this.token = token;
    }
    /**
     * Attaches an Authorization header
     *
     * @returns the headers for the request
     */
    createHeaders() {
        return {
            Authorization: `Bearer ${this.token}`,
        };
    }
}
exports.JWTAuthentication = JWTAuthentication;
