import { memo } from 'react'
import { FormCheckProps } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

export interface RadioInputProps extends FormCheckProps {
  controlId?: string
}

const RadioInput: React.FC<RadioInputProps> = ({
  controlId = 'radio-option',
  ...props
}) => {
  return (
    <Form.Check
      inline
      name={controlId}
      type="radio"
      id={`radio-${props.value}`}
      {...props}
    />
  )
}

export default memo(RadioInput)
