"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageResource = void 0;
const BaseResource_1 = require("../../BaseResource");
class MessageResource extends BaseResource_1.Resource {
    /**
     * Returns a list of messages within a thread.
     *
     * The messages returned are sorted with the most recently sent appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/communication/messages', {
            query: params,
            options,
        });
    }
    /**
     * Creates a message within a thread.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/communication/messages', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of a message. You need only supply the unique message
     * identifier that was returned upon creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/communication/messages/${id}`, {
            options,
        });
    }
    /**
     * Redacts a message and its attachments that were sent in error to a member. You
     * can redact a message sent by a user or an API key on behalf of a user.
     *
     * Once redacted, the message content and any attachments are no longer accessible
     * to the member, however the fact that the message was redacted is displayed to
     * the member. The message is labeled as redacted and remains visible to users in
     * the Source UI or via API.
     */
    redact(id, options) {
        return this.source.request('POST', `/v1/communication/messages/${id}/redact`, {
            contentType: 'json',
            options,
        });
    }
}
exports.MessageResource = MessageResource;
