import InputContainer, { InputContainerProps } from 'hoc/InputContainer'
import { memo } from 'react'
import { Form, FormControlProps } from 'react-bootstrap'

interface SelectProps extends FormControlProps, InputContainerProps {
  containerClassName?: string
  options: { label: string; value: string | number }[]
}

const Select: React.FC<SelectProps> = ({
  label,
  controlId = 'form-control',
  containerClassName = '',
  options,
  value,
  error,
  onChange,
  helperText,
  ...inputProps
}) => {
  return (
    <InputContainer
      controlId={controlId}
      className={`select ${containerClassName}`}
      label={label}
      error={error}
      helperText={helperText}
    >
      <Form.Control
        as="select"
        size="lg"
        value={value}
        onChange={onChange}
        {...inputProps}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Control>
    </InputContainer>
  )
}

export default memo(Select)
