import {
  Expandable,
  File as SourceFile,
  Member,
  Thread,
  Message,
} from '@allara-health/source-health-client'
import {
  CreateThread as CreateThreadRoute,
  Threads as ThreadsRoute,
  ThreadsQueryKey,
  SendMessage as sendMessageRoute,
  UploadFile as uploadFileRoute,
} from 'api/source/routes'
import axios from 'axios'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import { useSourceContext } from 'context/SourceContext/SourceProvider'
import produce from 'immer'
import { useEffect, useMemo } from 'react'
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from 'react-query'
import { isRead, isUnread } from 'utils/chat.utils'
import { useStytch, useStytchSession } from '@stytch/react'

export type MessageSubject = {
  id: number
  subject:
    | 'Insurance Coverage or Eligibility'
    | 'Something Else'
    | 'Update Personal Information'
    | 'Medication Question/Concern'
    | 'Technical Issue/Support'
    | 'Question about my subscription or billing'
    | 'Question about My Results'
    | 'Question about My Referral'
    | 'Help scheduling my visit'
  careTeamMember: 'concierge' | 'dietician' | 'medical-provider'
}

export type CreateThreadDto = {
  subject: MessageSubject
  message: string
  customSubject?: string
}

export type SendMessageDto = {
  message: string
  attachments?: SourceFile[]
  threadId: string
}

interface UseThreadsInterface {
  error: unknown
  threads?: Thread[]
  isLoading: boolean
  unreadThreads?: false | Thread[]
  markAsRead: (thread: Thread) => void
  handleCreateThread: UseMutateAsyncFunction<
    Thread | undefined,
    unknown,
    CreateThreadDto,
    unknown
  >
  sendMessage: (message: SendMessageDto) => Promise<Message | undefined>
  uploadFileToSource: (file: File) => Promise<SourceFile | undefined>
}

const useThreads = (): UseThreadsInterface => {
  const { setAlertText } = useAlert()
  const queryClient = useQueryClient()
  const { source } = useSourceContext()
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchThreads = async ({ pageParam = null }): Promise<Thread[]> => {
    if (!session) {
      return []
    }

    const url = new URL(`${process.env.REACT_APP_SERVER_URL}${ThreadsRoute}`)

    if (pageParam) {
      url.searchParams.append('starting_after', pageParam)
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(url.toString(), {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          return []
        }
      })
      .catch((err) => {
        throw err
      })
  }

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage: isLoading,
  } = useInfiniteQuery(ThreadsQueryKey, fetchThreads, {
    getNextPageParam: (lastPage) => {
      return lastPage[lastPage.length - 1]?.id
    },
  })
  const threads = data?.pages.flatMap((page) => page)

  useEffect(() => {
    const scrollContainer = document.getElementById('scrollableThreads')

    if (!scrollContainer) {
      return
    }

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainer

      // We want to load the next set of messages before the use actually reaches the end of the list
      // so we subtract 500 from the scrollHeight
      if (
        scrollTop + clientHeight >= scrollHeight - 500 &&
        !isLoading &&
        hasNextPage
      ) {
        fetchNextPage()
      }
    }

    scrollContainer.addEventListener('scroll', handleScroll)
    return () => scrollContainer.removeEventListener('scroll', handleScroll)
  }, [fetchNextPage, isLoading, hasNextPage])

  const markAsRead = async (thread: Thread) => {
    if (isRead(thread)) {
      return null
    }

    const d = new Date()
    source.communications.threads
      .mark(thread.id, {
        member_last_read: d.toISOString(),
      })
      .then(() => queryClient.invalidateQueries([ThreadsQueryKey]))
  }

  const createThread = async (
    createThreadDto: CreateThreadDto
  ): Promise<Thread | undefined> => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${CreateThreadRoute}`,
        createThreadDto,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((res) => res.data as Thread)
      .catch((err) => {
        setAlertText(
          `Unable to create thread. Please reload the page and try again.`,
          'An Error Occurred',
          err
        )
        return undefined
      })
  }

  const mutateCreate = useMutation(createThread, {
    onSettled: () => queryClient.invalidateQueries(ThreadsQueryKey),
    onMutate: async (createThreadDto: CreateThreadDto) => {
      await queryClient.cancelQueries(ThreadsQueryKey)

      const previousData = queryClient.getQueryData<{ pages: Thread[][] }>(
        ThreadsQueryKey
      )

      if (previousData) {
        const nextState = produce(previousData, (draftState) => {
          const now = new Date().toISOString()
          draftState.pages[0].unshift({
            object: 'thread',
            id: '0',
            member: '0' as Expandable<Member>,
            assignee: null, // Ideally we would set this to the correct Source User but I'm not sure how to get that info here
            status: 'awaiting_care_team',
            subject:
              createThreadDto.customSubject ?? createThreadDto.subject.subject,
            last_message: {
              text: createThreadDto.message,
              attachments: [],
              sender: '0' as Expandable<Member>,
              sent_at: now,
              redacted_at: null,
              channel: null,
              channel_type: '',
              from: null,
              to: null,
              status: 'sent',
              direction: 'outbound',
            },
            created_at: now,
            updated_at: now,
            closed_at: null,
            member_last_read: null,
            last_message_at: now,
            channel: null,
            channel_type: '',
            last_remote_contact_point: null,
          })
        })
        queryClient.setQueryData<{ pages: Thread[][] }>(
          ThreadsQueryKey,
          nextState
        )
      }

      return { previousData }
    },
  })
  const handleCreateThread = mutateCreate.mutateAsync

  // Compute unread
  const unreadThreads = useMemo(() => {
    if (!threads || threads.every((thread) => !isUnread(thread))) {
      return false
    }

    // Check at least one thread is unread
    return threads.filter((thread) => isUnread(thread))
  }, [threads])

  const sendMessage = async (
    sendMessageDto: SendMessageDto
  ): Promise<Message | undefined> => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${sendMessageRoute}`,
        sendMessageDto,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        setAlertText(
          `Unable to send message. Please reload the page and try again.`,
          'An Error Occurred',
          err
        )
        return undefined
      })
  }

  const uploadFileToSource = async (
    file: File
  ): Promise<SourceFile | undefined> => {
    if (!session) {
      return
    }

    const formData = new FormData()
    formData.append('attachment', file)

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return await axios
      .post(`${process.env.REACT_APP_SERVER_URL}${uploadFileRoute}`, formData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        setAlertText(
          `We do not support that file type. Please select a new file and try again.`,
          'An Error Occurred',
          err
        )
        return undefined
      })
  }

  return {
    error,
    threads,
    isLoading,
    unreadThreads,
    markAsRead,
    handleCreateThread,
    sendMessage,
    uploadFileToSource,
  }
}

export default useThreads
