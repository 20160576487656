import { Slot, SlotListResponse } from '@allara-health/source-health-client'
import { SchedulingQueryKey } from 'api/source/routes'
import { useSchedulingContext } from 'context/SchedulingContext/SchedulingProvider'
import { useSourceContext } from 'context/SourceContext/SourceProvider'
import useMember from 'hooks/useUser/useMember'
import { useQuery } from 'react-query'

interface useSourceSlotInterface {
  slots?: Slot[]
  isLoading: boolean
}

const useSourceSlot = (
  startAt: string,
  endAt: string
): useSourceSlotInterface => {
  const { source } = useSourceContext()
  const { appointmentType } = useSchedulingContext()
  const { member } = useMember()

  const fetchSlots = async (): Promise<Slot[] | undefined> => {
    if (!appointmentType) {
      return
    }

    const response: SlotListResponse = await source.scheduling.slots.list(
      {
        start_at: startAt,
        end_at: endAt,
        appointment_type: appointmentType.id ?? '',
        member: member?.id,
      },
      {
        expand: ['slots.available'],
      }
    )

    return response.slots
  }

  const { data: slots, isLoading } = useQuery(
    [SchedulingQueryKey, startAt, endAt],
    fetchSlots
  )

  return { slots, isLoading }
}

export default useSourceSlot
