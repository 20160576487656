"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormVersionResource = void 0;
const BaseResource_1 = require("../../BaseResource");
class FormVersionResource extends BaseResource_1.Resource {
    /**
     * Updates a form version. If there is no draft version of the form, a new draft
     * version is created with the parameters provided.
     */
    latest(form, params, options) {
        return this.source.request('POST', `/v1/forms/${form}/versions/latest`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Publishes the form version, preventing any further changes. Once a version has
     * been published it cannot be unpublished. Once published, the version becomes the
     * form's published version and is used for all new responses.
     */
    latestPublish(form, params, options) {
        return this.source.request('POST', `/v1/forms/${form}/versions/latest/publish`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing form version.  Use 'latest' to retrieve the
     * latest version of the form, which is always the current draft version of the
     * form, or 'published' to retrieve the latest published version, which is the
     * version in use for any new response.
     */
    retrieve(form, id, options) {
        return this.source.request('GET', `/v1/forms/${form}/versions/${id}`, {
            options,
        });
    }
    /**
     * Returns a list of forms versions. The form versions returned are sorted by
     * creation date, with the most recently added form version appearing first.
     */
    list(form, params, options) {
        return this.source.request('GET', `/v1/forms/${form}/versions`, {
            query: params,
            options,
        });
    }
}
exports.FormVersionResource = FormVersionResource;
