import { ChakraPrimaryButton } from 'components/Button'
import React, { Fragment, ReactNode } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import './styles.scss'
import { Button } from '@chakra-ui/react'

export type Target = '_blank' | '_self'

export type ButtonLink = {
  link: string
  target: Target
}

export type ActionProperties = {
  buttonLink?: ButtonLink
  buttonText: string
  buttonTooltip?: string
  disableButton: boolean
  handleClick?: () => void
  isLink?: boolean
}

interface MultiActionCardProps {
  icon: string
  title: string
  children?: ReactNode
  description: string
  actions: ActionProperties[]
}

const MultiActionCard: React.FC<MultiActionCardProps> = ({
  icon,
  title,
  children,
  description,
  actions,
}) => {
  const renderActionButton = (action: ActionProperties) => {
    const { handleClick, buttonLink, disableButton, buttonText } = action

    const onClick = () => {
      if (handleClick) {
        handleClick()
      }

      if (buttonLink?.link) {
        window.open(buttonLink?.link, buttonLink?.target ?? '_blank')
      }
    }

    if (action.isLink) {
      return (
        <Button
          onClick={onClick}
          disabled={disableButton}
          variant="link"
          color="var(--blue-dark)"
          fontWeight={400}
          _hover={{ textDecoration: 'underline' }}
        >
          {buttonText}
        </Button>
      )
    } else {
      return (
        <ChakraPrimaryButton onClick={onClick} disabled={disableButton}>
          {buttonText}
        </ChakraPrimaryButton>
      )
    }
  }

  const renderButtons = () => {
    return actions.map((action, idx) => (
      <div
        className="d-flex justify-content-flex-start"
        style={{ marginTop: '1rem' }}
        key={`${title}-action-${idx}`}
      >
        {action.buttonTooltip && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tool-tip">{action.buttonTooltip}</Tooltip>
            }
          >
            <span>{renderActionButton(action)}</span>
          </OverlayTrigger>
        )}
        {!action.buttonTooltip && renderActionButton(action)}
      </div>
    ))
  }

  const renderLink = (text: string) => {
    const [preLinkText, remainder1] = text.split('{{')
    const [link, remainder2] = remainder1.split('|')
    const [linkText, remainder] = remainder2.split('}}')

    return (
      <>
        {preLinkText}
        <a href={link} target="_blank" rel="noreferrer">
          <span className="link">{linkText}</span>
        </a>
        {remainder}
      </>
    )
  }

  const renderCardDescription = () => {
    return description.split('\\n').map((line, i) => (
      <Fragment key={i}>
        <span>
          {line.includes('{{') && line.includes('}}') ? renderLink(line) : line}
        </span>
        <br />
      </Fragment>
    ))
  }

  return (
    <div className="dashboard-card multi-action-card mb-4 p-3">
      <div className="d-inline-flex align-items-center mb-3">
        <div className="dashboard-card-icon-container">
          <img src={icon} className="dashboard-card-icon" />
        </div>
        <div>
          <p className="fs-7 mb-0">{title}</p>
        </div>
      </div>
      <p>{renderCardDescription()}</p>
      {children}
      <div
        style={{ display: 'flex', justifyContent: 'flex-start', gap: '1rem' }}
      >
        {renderButtons()}
      </div>
    </div>
  )
}

export default MultiActionCard
