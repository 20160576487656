import React from 'react'
import InsuranceInfoForm from 'containers/Onboarding/InsuranceInfo/InsuranceForm'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
interface UploadInsuranceInfoProps {
  onSubmit: () => void
}

const UploadInsuranceInfo: React.FC<UploadInsuranceInfoProps> = ({
  onSubmit,
}) => {
  return (
    <div className="insurance-info d-flex justify-content-center align-items-center align-self-center my-4">
      <InsuranceInfoForm
        onSubmit={onSubmit}
        supportComponent={
          <Row className="py-3">
            <Col className="d-flex flex-column justify-content-center align-items-center">
              <Link
                to={RoutePaths.DASHBOARD_CHAT}
                rel="noreferrer"
                className="mb-2"
              >
                <u>Send a message to Patient Concierge</u>
              </Link>
            </Col>
          </Row>
        }
        subTitle="Please use the form below to update your insurance information below"
      />
    </div>
  )
}

export default UploadInsuranceInfo
