"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CredentialResource = exports.CredentialStatus = void 0;
const BaseResource_1 = require("../BaseResource");
var CredentialStatus;
(function (CredentialStatus) {
    CredentialStatus["ACTIVE"] = "active";
    CredentialStatus["INACTIVE"] = "inactive";
})(CredentialStatus = exports.CredentialStatus || (exports.CredentialStatus = {}));
class CredentialResource extends BaseResource_1.Resource {
    /**
     * Returns a list of credentials within the current account. The credentials returned are sorted
     * by creation date, with the most recently added credential appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/credentials', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new credential. You can create a new credential with a unique name and a display
     * color of your choice. If a credential already exists with the same name (case
     * insensitive), an error is returned.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/credentials', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieve a credential by its unique identifier.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/credentials/${id}`, {
            options,
        });
    }
    /**
     * Updates the specified credential by setting the values of the parameters passed. Any
     * parameters not provided will be left unchanged.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/credentials/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Deletes the specified credential. A deleted credential is removed from all members to which it
     * relates.
     */
    delete(id, options) {
        return this.source.request('DELETE', `/v1/credentials/${id}`, {
            contentType: 'json',
            options,
        });
    }
}
exports.CredentialResource = CredentialResource;
