"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceClient = void 0;
const utils_1 = require("./utils");
class SourceClient {
    constructor(http, configuration) {
        this.http = http;
        this.configuration = configuration;
    }
    /**
     * Execute a request against the Source API
     *
     * This method wraps the HttpClient's request method to add Source-specific functionality at
     * a higher level. We use this method to encode request parameters, parse out responses, and generally
     * enforce API conventions.
     *
     * @param method request method to send
     * @param path path to send the request to
     * @param args arguments of the request
     * @returns a response to the HTTP request
     */
    request(method, path, args) {
        return __awaiter(this, void 0, void 0, function* () {
            const baseUrl = this.configuration.getBaseUrl();
            const defaultAuthentication = this.configuration.getAuthentication();
            const { options, query, headers } = args, request = __rest(args, ["options", "query", "headers"]);
            const _a = options !== null && options !== void 0 ? options : {}, { expand, authentication = defaultAuthentication } = _a, otherOptions = __rest(_a, ["expand", "authentication"]);
            const actualQueryParams = query ? (0, utils_1.toUrlEncoded)(query, true) : {};
            const mergedQueryParams = expand ? Object.assign(Object.assign({}, actualQueryParams), { expand }) : actualQueryParams;
            const defaultOptions = this.configuration.getRequestOptions();
            const interceptors = this.configuration.getInterceptors();
            let index = 0;
            const next = (request) => __awaiter(this, void 0, void 0, function* () {
                if (index >= interceptors.length) {
                    return yield this.http.request(request);
                }
                else {
                    return interceptors[index++](request, next);
                }
            });
            const response = yield next(Object.assign(Object.assign({}, request), { baseUrl,
                method,
                path, query: mergedQueryParams, options: Object.assign(Object.assign({}, defaultOptions), otherOptions), headers: Object.assign(Object.assign({}, authentication.createHeaders()), headers) }));
            return Object.assign(Object.assign({}, response.data), { response: {
                    statusCode: response.status,
                    headers: response.headers,
                } });
        });
    }
}
exports.SourceClient = SourceClient;
