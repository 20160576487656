"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteResource = void 0;
const BaseResource_1 = require("../../BaseResource");
class NoteResource extends BaseResource_1.Resource {
    /**
     * Lists all notes.
     *
     * By default, this method does not return deleted or superseded notes. You can
     * optionally specify `include_deleted`  to view deleted notes and
     * `include_superseded` to view superseded notes.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/notes', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new note and an initial note version.
     *
     * To supersede an existing note, pass the identifier of the original note in the
     * supersedes parameter.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/notes', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves an existing note by its unique identifier.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/notes/${id}`, {
            options,
        });
    }
    /**
     * Updates a note.
     *
     * Only the note's author or an API key acting on behalf of the author can update a
     * note. When the note content is  updated, a new note version is created
     * automatically.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/notes/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Deletes a note.
     *
     * If a note requires correction or an addendum, supersede the note rather than
     * delete it. Deleting a note should be  reserved for use when correcting notes
     * during a backfill or when a note is associated to the wrong member. Once a  note
     * is deleted, it can no longer be accessed other than via the list all notes
     * endpoint.
     */
    delete(id, options) {
        return this.source.request('DELETE', `/v1/notes/${id}`, {
            contentType: 'json',
            options,
        });
    }
    /**
     * Signs an unsigned note.
     *
     * Only the note's author or an API key acting on behalf of the author can sign a
     * note. Signing a note sets the latest  note version as the final, signed version
     * of the note. Once signed, the note can no longer be modified. If a  correction
     * to a signed note is required, create a new note that supersedes the original.
     */
    sign(id, options) {
        return this.source.request('POST', `/v1/notes/${id}/sign`, {
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves a note version for a given note by its unique identifier.
     */
    retrieveVersion(id, noteVersionId, options) {
        return this.source.request('GET', `/v1/notes/${id}/versions/${noteVersionId}`, {
            options,
        });
    }
    /**
     * Lists all note versions for a given note.
     */
    listVersions(id, params, options) {
        return this.source.request('GET', `/v1/notes/${id}/versions`, {
            query: params,
            options,
        });
    }
}
exports.NoteResource = NoteResource;
