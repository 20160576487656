"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LicenseResource = void 0;
const BaseResource_1 = require("../BaseResource");
class LicenseResource extends BaseResource_1.Resource {
    /**
     * Returns a list of licenses within the current account. The licenses returned are
     * sorted by creation date, with the most recently added license appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/licenses', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new license for a user.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/licenses', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing license. You need only supply the unique
     * license identifier that was returned upon creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/licenses/${id}`, {
            options,
        });
    }
    /**
     * Updates the license with a new status, license number, or description. To update
     * other license fields, first delete the license and then create a new one.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/licenses/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Deletes the specified license.
     */
    delete(id, options) {
        return this.source.request('DELETE', `/v1/licenses/${id}`, {
            contentType: 'json',
            options,
        });
    }
}
exports.LicenseResource = LicenseResource;
