import { useState } from 'react'
import { Box } from '@chakra-ui/react'
import AreYouSureYoudLikeToCancel from './AreYouSureYoudLikeToCancel'
import NoDiscountConfirmation from './NoDiscountConfirmation'
import DiscountConfirmation from './DiscountConfirmation'
import { CouponData } from 'hooks/useStripePortal'

const CurrentMembership = () => {
  const [flow, setFlow] = useState<string>('')
  const [chosenCoupon, setChosenCoupon] = useState<CouponData>()
  const [updateToAnnual, setUpdateToAnnual] = useState<boolean>(false)
  const [cancellationReason, setCancellationReason] = useState<string>('')

  return (
    <>
      <Box borderWidth="1px" borderRadius="lg">
        {(() => {
          switch (flow) {
            case 'NoDiscountConfirmation':
              return (
                <NoDiscountConfirmation
                  cancellationReason={cancellationReason}
                />
              )
            case 'DiscountConfirmation':
              return (
                <DiscountConfirmation
                  chosenCoupon={chosenCoupon}
                  updateToAnnual={updateToAnnual}
                />
              )
            default:
              return (
                <AreYouSureYoudLikeToCancel
                  setFlow={setFlow}
                  setChosenCoupon={setChosenCoupon}
                  setUpdateToAnnual={setUpdateToAnnual}
                  setCancellationReason={setCancellationReason}
                />
              )
          }
        })()}
      </Box>
    </>
  )
}

export default CurrentMembership
