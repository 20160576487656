import { logEvent } from '@amplitude/analytics-browser'

export class Amplitude {
  static userHasInsurance(cta: 'yes' | 'no', variant_label?: string) {
    logEvent('User Has Insurance', {
      cta,
      variant_label: variant_label ?? '',
    })
  }

  static insuranceEligibility(
    state_field: string,
    insurance_company: string,
    variant_label?: string
  ) {
    logEvent('Insurance Eligibility', {
      state_field,
      insurance_company,
      variant_label: variant_label ?? '',
    })
  }

  static insurancePlanEligibility(cta: 'yes' | 'no', variant_label?: string) {
    logEvent('User has non-commerce insurance', {
      cta,
      variant_label: variant_label ?? '',
    })
  }

  static insuranceCoverageLoaded(
    coverage: 'covered' | 'testing-covered' | 'more-info',
    variant_label?: string
  ) {
    logEvent('Insurance coverage Loaded', {
      coverage,
      variant_label: variant_label ?? '',
    })
  }

  static insuranceCoverage(
    cta: 'covered' | 'testing-covered',
    variant_label?: string
  ) {
    logEvent('Insurance coverage', {
      cta,
      variant_label: variant_label ?? '',
    })
  }

  static userSelectsProduct(cta: string, variant_label?: string) {
    logEvent('User Selects Product', {
      cta,
      variant_label: variant_label ?? '',
    })
  }

  static userViewsPaymentPlans(variant_label?: string) {
    logEvent('User Views Payment Plans', {
      variant_label: variant_label ?? '',
    })
  }

  static initiatesCheckout(
    cta: 'checkout' | 'consult',
    product_tier?: string,
    variant_label?: string
  ) {
    logEvent(
      'Initiates Checkout',
      product_tier
        ? {
            product_tier,
            cta,
            variant_label: variant_label ?? '',
          }
        : { cta, variant_label: variant_label ?? '' }
    )
  }

  static userEntersPayment(
    time_on_page_in_seconds: number,
    variant_label?: string
  ) {
    logEvent('User Enters Payment', {
      time_on_page_in_seconds,
      variant_label: variant_label ?? '',
    })
  }

  static howDidYouHear(
    choices: string[],
    cta: 'share' | 'skip',
    variant_label?: string
  ) {
    logEvent('How Did You Hear', {
      choices,
      cta,
      variant_label: variant_label ?? '',
    })
  }

  static consentToTelehealth(variant_label?: string) {
    logEvent('Consent To Telehealth', {
      variant_label: variant_label ?? '',
    })
  }

  static insuranceDetails(
    time_on_page_in_seconds: number,
    variant_label?: string
  ) {
    logEvent('Insurance Details', {
      cta: 'submit',
      time_on_page_in_seconds,
      variant_label: variant_label ?? '',
    })
  }

  static bookAppointment(variant_label?: string) {
    logEvent('Book Appointment', {
      variant_label: variant_label ?? '',
    })
  }
}
