import { SecondaryButton } from 'components/Button'
import Loader from 'components/Loader'
import ProviderDetails from 'components/Scheduling/ProviderDetails'
import TimingDetails from 'components/Scheduling/TimingDetails'
import { RoutePaths } from 'containers/Core/Routes'
import { useSchedulingContext } from 'context/SchedulingContext/SchedulingProvider'
import { memo, useCallback, useMemo, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import LegalText from './LegalText'
import momentTZ from 'moment-timezone'
import { formatInTimeZone } from 'date-fns-tz'
import { addMinutes } from 'date-fns'
import { toGCal, toICS } from 'utils/time.utils'
import { ReactComponent as RescheduleIcon } from 'assets/images/reschedule.svg'
import { ReactComponent as CancelIcon } from 'assets/images/trash.svg'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import ConfirmModal from 'components/Scheduling/ConfirmModal'
import { addHours } from 'date-fns'

export type CalendarDetails = {
  dateTime: Date
  endDateTime: Date
  title: string
  description: string
  location: string
}

const SuccessScreen: React.FC = () => {
  const {
    provider,
    dateTime,
    timeZone,
    appointment,
    isReschedule,
    appointmentType,
    participantsChanged,
    setCurrentScreen,
    cancelAppointment,
  } = useSchedulingContext()
  const history = useHistory()
  const { setAlertText } = useAlert()
  const [isCancelling, setIsCancelling] = useState<boolean>(false)
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false)

  const showCancelConfirmModal = () => setShowCancelConfirm(true)
  const closeCancelConfirmModal = () => setShowCancelConfirm(false)

  const isWithin24Hours = useMemo(
    () =>
      appointment
        ? addHours(new Date(), 24).getTime() >
          new Date(appointment.start_at).getTime()
        : true,
    [appointment]
  )

  const getCalendarDetails = useCallback(
    (seperator: string): CalendarDetails | undefined => {
      if (
        !provider ||
        !dateTime ||
        !timeZone ||
        !appointment ||
        !appointmentType
      ) {
        return undefined
      }

      const endDateTime = addMinutes(dateTime, appointmentType.duration)
      const timeZoneShorthand = momentTZ.tz(timeZone).format('z')
      const timeZoneOffset = momentTZ.tz(timeZone).format('Z')
      const zonedDate = formatInTimeZone(
        dateTime,
        timeZone,
        'EEEE, MMMM d, yyyy'
      )
      const zonedStartDate = formatInTimeZone(dateTime, timeZone, 'h:mm	aaa')
      const zonedEndDate = formatInTimeZone(endDateTime, timeZone, 'h:mm	aaa')

      const providerName = `${provider.first_name} ${provider.last_name}`
      const videoCallUrl = appointment.video_call?.join_url ?? ''
      const title = `Allara ${appointmentType.name} with ${providerName}`
      const description = [
        `Allara ${appointmentType.name} with ${providerName}`,
        `Click to join: ${videoCallUrl}`,
        `${timeZoneShorthand} (GMT ${timeZoneOffset})`,
        `${zonedDate}`,
        `${zonedStartDate} - ${zonedEndDate}`,
      ].join(seperator)

      return {
        dateTime,
        endDateTime,
        title,
        description,
        location: videoCallUrl,
      }
    },
    [provider, dateTime, timeZone, appointment, appointmentType]
  )

  const handleDownloadICS = useCallback(() => {
    // Seperator used to join the description
    const ESCAPED_SEPARATOR =
      navigator.appVersion.indexOf('Win') !== -1 ? `\\r\\n` : `\\n`

    const calendarDetails = getCalendarDetails(ESCAPED_SEPARATOR)

    if (!calendarDetails || !appointment || !appointmentType) {
      return alert('Could not save ICS file')
    }

    // Download the ICS file
    const rawICS = toICS(`${appointment.id}@source`, calendarDetails)
    const blob = new Blob([rawICS])
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = `allara_${appointmentType.name.replace(
      / /g,
      '_'
    )}_appointment.ics`
    document.body.appendChild(a)
    a.click()
    a.remove()
    window.URL.revokeObjectURL(url)
  }, [getCalendarDetails])

  const handleAddToGCal = useCallback(() => {
    // Seperator used to join the description
    const SEPARATOR = navigator.appVersion.indexOf('Win') !== -1 ? `\r\n` : `\n`

    const calendarDetails = getCalendarDetails(SEPARATOR)

    if (!calendarDetails) {
      return alert('Could not create event')
    }

    window.open(toGCal(calendarDetails), '_blank')
  }, [getCalendarDetails])

  const handleReschedule = useCallback(() => {
    setCurrentScreen('book')

    if (!isReschedule) {
      history.push(`${RoutePaths.SCHEDULING}?appointment=${appointment?.id}`)
    }
  }, [appointment])

  const handleCancel = useCallback(() => {
    setIsCancelling(true)

    cancelAppointment()
      .catch(() => {
        setAlertText(
          'Your appointment was not deleted. Please contact concierge for assistance.',
          "Oops that didn't quite got to plan"
        )
      })
      .finally(() => {
        closeCancelConfirmModal()
        setIsCancelling(false)
      })
  }, [cancelAppointment])

  if (!appointmentType) {
    return <Loader />
  } else if (!provider || !dateTime || !timeZone || !appointment) {
    return <Redirect to={RoutePaths.SCHEDULING} />
  }

  const addToCalendarButtons = (
    <>
      <SecondaryButton
        variant="dark-blue"
        className="next-button mr-2"
        onClick={handleDownloadICS}
      >
        Add to iCal/Outlook
      </SecondaryButton>
      <SecondaryButton
        variant="dark-blue"
        className="next-button"
        onClick={handleAddToGCal}
      >
        Add to Google
      </SecondaryButton>
    </>
  )

  return (
    <div className="success screen-container">
      <h1 className="ff-inter-medium fs-8 mb-4">
        {appointmentType.name}, {appointmentType.duration} min
      </h1>
      {appointment.status === 'canceled' && (
        <div className="alert ff-inter fs-2">
          This appointment has been canceled
        </div>
      )}
      <div className="d-flex flex-column mb-3">
        <div className="d-flex flex-wrap align-items-center">
          <ProviderDetails provider={provider} className="mr-8 mb-1" />
          <TimingDetails
            date={dateTime}
            timeZone={timeZone}
            appointmentType={appointmentType}
          />
        </div>
        {appointment.status !== 'canceled' &&
          participantsChanged === false &&
          appointment.video_call?.join_url !== undefined && (
            <>
              <div className="d-none d-lg-flex my-3">
                {addToCalendarButtons}
              </div>
              <div className="d-block d-lg-none my-3 w-100 half-width-buttons">
                <div className="w-100 d-flex">{addToCalendarButtons}</div>
              </div>
            </>
          )}
        <LegalText />
        {isReschedule && appointment.status !== 'canceled' && !isWithin24Hours && (
          <div className="d-flex modify-buttons">
            <div
              className="d-flex align-items-center ff-inter-medium mr-3"
              onClick={handleReschedule}
            >
              <div>Reschedule</div>
              <RescheduleIcon className="ml-1" />
            </div>
            <div
              className="d-flex align-items-center ff-inter-medium"
              onClick={showCancelConfirmModal}
            >
              Cancel
              <CancelIcon className="ml-1" />
            </div>
          </div>
        )}
      </div>
      <ConfirmModal
        open={showCancelConfirm}
        isLoading={isCancelling}
        confirmText="Cancel"
        title="Are you sure you want to cancel?"
        handleClose={closeCancelConfirmModal}
        onConfirm={handleCancel}
      />
    </div>
  )
}

export default memo(SuccessScreen)
