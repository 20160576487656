import { User } from '@allara-health/source-health-client'
import { useSourceContext } from 'context/SourceContext/SourceProvider'
import { memo, useEffect, useState } from 'react'

import './styles.scss'

interface ProfileImageProps {
  user: User
  size?: 'sm' | 'lg'
  className?: string
}

const ProfileImage: React.FC<ProfileImageProps> = ({
  user,
  size = 'lg',
  className = '',
}) => {
  const { source } = useSourceContext()
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!user.profile_image) {
      setImageUrl(undefined)
    } else if (typeof user.profile_image === 'string') {
      source.files
        .retrieve(user.profile_image)
        .then((file) => setImageUrl(file.url))
        .catch(() => setImageUrl(undefined))
    } else {
      setImageUrl(user.profile_image.url)
    }
  }, [user, source])

  return (
    <div className={`profile-picture profile-picture-${size} ${className}`}>
      {imageUrl ? (
        <img src={imageUrl} />
      ) : (
        <div className="profile-picture-initials d-flex flex-column justify-content-center align-items-center">
          <div>
            {user.first_name?.[0]} {user.last_name?.[0]}
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(ProfileImage)
