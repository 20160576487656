import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

interface InitialState {
  isDrawerOpen: boolean
  isChatDrawerOpen: boolean
  lastChatThread: string | null
}

const initialState: InitialState = {
  isDrawerOpen: false,
  isChatDrawerOpen: false,
  lastChatThread: null,
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen
    },
    setIsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isDrawerOpen = action.payload
    },
    toggleChatDrawer: (state) => {
      state.isChatDrawerOpen = !state.isChatDrawerOpen
    },
    setIsChatDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isChatDrawerOpen = action.payload
    },
    setLastChatThread: (state, action: PayloadAction<string>) => {
      state.lastChatThread = action.payload
    },
  },
})

export const {
  toggleDrawer,
  setIsDrawerOpen,
  toggleChatDrawer,
  setIsChatDrawerOpen,
  setLastChatThread,
} = dashboardSlice.actions

export const selectIsDrawerOpen = (state: RootState): boolean =>
  state.dashboard.isDrawerOpen

export const selectIsChatDrawerOpen = (state: RootState): boolean =>
  state.dashboard.isChatDrawerOpen

export const selectLastChatThread = (state: RootState): string | null =>
  state.dashboard.lastChatThread

export default dashboardSlice.reducer
