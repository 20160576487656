import { PrimaryButton } from 'components/Button'
import { Modal, ModalProps } from 'react-bootstrap'

interface ResetPasswordModalProps extends ModalProps {
  email?: string
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  show,
  email,
  onHide,
}) => {
  return (
    <Modal onHide={onHide} show={show} centered>
      <Modal.Body className="justify-content-center p-4">
        <p className="text-center fs-6">
          A password reset email has been sent to {email ?? 'your email'} with a
          link to reset your password.
        </p>
        <p className="text-center fs-5">
          Be sure to check your &quot;spam&quot; folder if you don&apos;t see
          the email shortly. Otherwise, send us an email at
          concierge@allarahealth.com and we can help you out!
        </p>
        <div className="d-flex justify-content-center mt-4">
          <PrimaryButton
            className="primary-button font-weight-light fs-2 fs-sm-5 spaced-capital-letters"
            onClick={onHide}
          >
            Got It
          </PrimaryButton>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ResetPasswordModal
