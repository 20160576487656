import { ChakraPrimaryButton } from 'components/Button'
import useActions from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'
import { useToast } from '@chakra-ui/react'

const AtHomeTestingForm: React.FC = () => {
  const { handleRespondAtHomeTesting } = useActions()
  const toast = useToast()

  const handleClick = useCallback(
    (response: 'home' | 'labcorp' | 'quest' | 'unsure') => {
      handleRespondAtHomeTesting(response)
      toast({
        title: 'Action completed',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    },
    []
  )
  const handleQuestClick = useCallback(() => handleClick('quest'), [])
  const handleUnsureClick = useCallback(() => handleClick('unsure'), [])
  const handleLabCorpClick = useCallback(() => handleClick('labcorp'), [])

  return (
    <div className="d-flex justify-content-flex-start flex-column">
      <div style={{ height: '15px' }}></div>
      <ChakraPrimaryButton onClick={handleLabCorpClick}>
        At LabCorp
      </ChakraPrimaryButton>
      <div style={{ height: '15px' }}></div>
      <ChakraPrimaryButton onClick={handleQuestClick}>
        At Quest
      </ChakraPrimaryButton>
      <div style={{ height: '15px' }}></div>
      <ChakraPrimaryButton onClick={handleUnsureClick}>
        Other/Unsure
      </ChakraPrimaryButton>
    </div>
  )
}

export default AtHomeTestingForm
