"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupResource = void 0;
const BaseResource_1 = require("../BaseResource");
class GroupResource extends BaseResource_1.Resource {
    /**
     * Returns a list of groups within the current account.
     *
     * The groups returned are sorted by creation date, with the most recently added
     * group appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/groups', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new group, which describes a function of a user. You can use groups
     * alongside queues to control how tasks are routed among the care team.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/groups', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing group. You need only supply the unique
     * group identifier that was returned upon creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/groups/${id}`, {
            options,
        });
    }
    /**
     * Updates the specified group by setting the values of the parameters passed.
     *
     * Any parameters not provided will be left unchanged.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/groups/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Deletes the specified group. A deleted group is removed from all other objects
     * to which it relates.
     */
    delete(id, options) {
        return this.source.request('DELETE', `/v1/groups/${id}`, {
            contentType: 'json',
            options,
        });
    }
}
exports.GroupResource = GroupResource;
