import { useStytch, useStytchSession } from '@stytch/react'
import { PaymentPlan as PaymentPlanRoute } from 'api/payment/routes'
import { PaymentPlan } from 'context/OnboardingContext/OnboardingProvider'

interface UsePaymentPlanInterface {
  fetchPlan: (planId: string) => Promise<PaymentPlan | null>
}

const usePaymentPlan = (): UsePaymentPlanInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchPlan = async (planId: string): Promise<PaymentPlan | null> => {
    if (!session) {
      return null
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${PaymentPlanRoute(planId)}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
      .then(async (res) => {
        const data: PaymentPlan = await res.json()

        if (res.ok) {
          return data
        } else {
          return null
        }
      })
      .catch((err) => {
        throw err
      })
  }

  return { fetchPlan }
}

export default usePaymentPlan
