import { Heading, Box, Text, Button } from '@chakra-ui/react'
import useStripePortal, { CouponData } from 'hooks/useStripePortal'
import { formatNumberToCurrency } from 'containers/Core/utils'
import { ChakraPrimaryButton } from 'components/Button'
import { useHistory } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import { useFormik } from 'formik'
import LoadingState from 'components/Spinner'
import { useEffect, useState } from 'react'

interface DiscountConfirmationProps {
  chosenCoupon?: CouponData
  updateToAnnual?: boolean
}

const DiscountConfirmation = ({
  chosenCoupon,
  updateToAnnual,
}: DiscountConfirmationProps) => {
  const {
    membershipInfo,
    applyDiscount,
    isLoading,
    updateMonthlyInsuranceToAnnual,
    fetchPrice,
  } = useStripePortal()
  const history = useHistory()
  const [newPrice, setNewPrice] = useState<number | null>()

  useEffect(() => {
    const calculateAndUpdatePrice = async () => {
      const price = await calculateNewPrice()
      setNewPrice(price)
    }

    calculateAndUpdatePrice()
  }, [chosenCoupon, updateToAnnual, membershipInfo])

  const calculateNewPrice = async () => {
    if (updateToAnnual && chosenCoupon?.percentOff) {
      if (process.env.REACT_APP_STRIPE_ANNUAL_INSURANCE_PRICE_ID) {
        const annualPrice = await fetchPrice({
          priceId: process.env.REACT_APP_STRIPE_ANNUAL_INSURANCE_PRICE_ID,
        })
        return annualPrice?.unit_amount * (chosenCoupon.percentOff / 100)
      } else {
        return 18000 * (chosenCoupon.percentOff / 100)
      }
    }

    if (!membershipInfo?.subscription?.amount) {
      return 0
    }

    if (!chosenCoupon) {
      return membershipInfo?.subscription?.amount
    }

    if (
      chosenCoupon.amountOff &&
      chosenCoupon.amountOff < membershipInfo?.subscription?.amount
    ) {
      return membershipInfo?.subscription?.amount - chosenCoupon.amountOff
    }

    if (chosenCoupon.percentOff) {
      return (
        membershipInfo?.subscription?.amount * (chosenCoupon.percentOff / 100)
      )
    }

    return membershipInfo?.subscription?.amount
  }

  const oldPrice = membershipInfo?.subscription?.amount

  const duration = () => {
    if (updateToAnnual) {
      return '1 year'
    }

    if (chosenCoupon?.duration === 'once') {
      if (membershipInfo?.subscription?.interval === 'month') {
        return '1 month'
      } else {
        return '1 year'
      }
    }

    if (chosenCoupon?.duration === 'repeating') {
      return chosenCoupon?.durationInMonths + ' months'
    } else {
      return null
    }
  }

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      try {
        let optionalToast = {}

        if (updateToAnnual) {
          updateMonthlyInsuranceToAnnual()
          optionalToast = {
            title: 'Annual subscription confirmed',
            status: 'success',
          }
        } else {
          applyDiscount(chosenCoupon?.id ?? '')
          optionalToast = {
            title: 'New pricing confirmed',
            status: 'success',
          }
        }

        history.push(RoutePaths.DASHBOARD_PROFILE, { optionalToast })
      } catch (error) {
        const optionalToast = {
          title: 'There was an error processing your request',
          description: 'Please reach out to Patient Concierge for assistance.',
          status: 'error',
        }
        history.push(RoutePaths.DASHBOARD_PROFILE, { optionalToast })
        console.error(error)
      }
    },
  })

  return (
    <Box m={10}>
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          <Heading as="h4" size="m" fontWeight={'semibold'}>
            Discount Confirmation
          </Heading>
          <Text>Please confirm your new pricing below:</Text>
          <Text
            as="s"
            color="var(--gray-medium)"
            fontWeight={'bold'}
            fontSize={'xl'}
          >
            {formatNumberToCurrency(oldPrice)}
          </Text>
          <Text fontWeight={'normal'} fontSize={'xl'}>
            {formatNumberToCurrency(newPrice ?? 0)} for {duration()}
          </Text>
          <Text>
            After this time, your normal pricing will resume. If you have any
            questions or concerns, please don&apos;t hesitate to contact us.
          </Text>
          <form onSubmit={formik.handleSubmit}>
            <Box mt={6}>
              <Button
                mr={4}
                onClick={() => history.push(RoutePaths.DASHBOARD_PROFILE)}
              >
                Cancel
              </Button>
              <ChakraPrimaryButton type="submit">
                Confirm new pricing
              </ChakraPrimaryButton>
            </Box>
          </form>
        </>
      )}
    </Box>
  )
}

export default DiscountConfirmation
