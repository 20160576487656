"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppointmentTypeResource = void 0;
const BaseResource_1 = require("../../BaseResource");
class AppointmentTypeResource extends BaseResource_1.Resource {
    /**
     * Lists all available appointment types.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/scheduling/appointment_types', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new appointment type on Source.
     *
     * After creating an appointment type, you can use the availability endpoints to
     * begin searching your team and scheduling them.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/scheduling/appointment_types', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieve an appointment type by its unique identifier.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/scheduling/appointment_types/${id}`, {
            options,
        });
    }
    /**
     * Updates an appointment type.
     *
     * Appointments are linked to appointment types in Source, so changes made to an
     * appointment type will be visible on past appointments as well. However, some
     * fields, such as the duration and location of the appointment, are copied at the
     * time the appointment is created. Changing the duration of an appointment type
     * will not change the duration of past appointments.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/scheduling/appointment_types/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Deletes an appointment type from Source.
     *
     * Once an appointment type is deleted, it can no longer be used to schedule
     * further appointments. It will still be visible on past appointments that used
     * this type. You can delete an appontment type at any time.
     */
    delete(id, options) {
        return this.source.request('DELETE', `/v1/scheduling/appointment_types/${id}`, {
            contentType: 'json',
            options,
        });
    }
}
exports.AppointmentTypeResource = AppointmentTypeResource;
