import useLabs, { Lab } from 'hooks/useLabs'
import { useCallback } from 'react'
import { ReactComponent as DownloadIcon } from 'assets/images/download.svg'
import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg'
import { memo } from 'react'
import { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { formatDate } from 'utils/time.utils'
import UploadLabsModal from './UploadLabsModal'

interface LabRowProps {
  lab: Lab
}

const LabRow: React.FC<LabRowProps> = ({ lab }) => {
  const { downloadLab } = useLabs()
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [showUpdateLabModal, setShowUpdateLabModal] = useState<boolean>(false)

  const handleDownload = useCallback(() => {
    setIsDownloading(true)
    downloadLab(lab).then(() => setIsDownloading(false))
  }, [downloadLab, setIsDownloading])

  const toggleUpdateLabModal = useCallback(() => {
    setShowUpdateLabModal((show) => !show)
  }, [setShowUpdateLabModal])

  const date = lab.documented
    ? new Date(lab.documented)
    : new Date(lab.uploaded)
  const dateString = formatDate(date, 'mm/dd/yyyy')

  return (
    <div className="d-flex align-items-center justify-content-between mb-1">
      <p className="mb-0">
        {lab.type ?? 'Test'} result {lab.documented ? 'from' : 'uploaded on'}{' '}
        {dateString}
      </p>
      <div className="d-flex">
        {isDownloading ? (
          <OverlayTrigger
            placement="right"
            show={true}
            overlay={<Tooltip id="downloading-tooltip">Downloading...</Tooltip>}
          >
            <DownloadIcon className="download-icon disabled" />
          </OverlayTrigger>
        ) : (
          <DownloadIcon className="download-icon" onClick={handleDownload} />
        )}
        <div className="calendar-icon-spacer" />
        {lab.documented ? (
          <div className="calendar-icon-spacer" />
        ) : (
          <CalendarIcon
            className="calendar-icon"
            onClick={toggleUpdateLabModal}
          />
        )}
      </div>
      <UploadLabsModal
        show={showUpdateLabModal}
        onHide={toggleUpdateLabModal}
        lab={lab}
      />
    </div>
  )
}

export default memo(LabRow)
