import Loader from 'components/Loader'
import { ReactComponent as CheckIcon } from 'assets/images/check.svg'
import {
  Program,
  useOnboardingContext,
} from 'context/OnboardingContext/OnboardingProvider'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import {
  PROGRAMS,
  getProgramFeatures,
  getProgramName,
} from 'utils/program.utils'
import JoinModalConfirm from './JoinModalConfirm'
import usePaymentPlans from 'hooks/usePayment/usePaymentPlans'
import { PromoCodeProvider } from 'context/PromoCodeContext/PromoCodeProvider'
import SelectPlan from 'containers/Onboarding/PaymentPlans/SelectPlan'
import usePaymentPlan from 'hooks/usePayment/usePaymentPlan'

import './styles.scss'

export interface JoinModalProps {
  program?: Program
  promo?: string
  currentProgram?: Program
  handleClose: () => void
  onSuccess: () => void
}

const JoinModal: React.FC<JoinModalProps> = ({
  program,
  promo,
  currentProgram,
  handleClose,
  onSuccess,
}) => {
  const { plans, isLoading } = usePaymentPlans(program)
  const { fetchPlan } = usePaymentPlan()
  const { selectedPlan, setSelectedPlan } = useOnboardingContext()
  const title = useMemo(() => getProgramName(program), [program])
  const features = useMemo(() => getProgramFeatures(program, true), [program])

  const onHide = useCallback(() => {
    handleClose()
  }, [])

  useEffect(() => {
    if (
      program === PROGRAMS.INSURANCE &&
      currentProgram === PROGRAMS.INSURANCE_DIAGNOSTIC &&
      process.env.REACT_APP_0_DOLLAR_INSURANCE_PRICE_ID
    ) {
      const fetch0InsurancePlan = async () => {
        const data = await fetchPlan(
          process.env.REACT_APP_0_DOLLAR_INSURANCE_PRICE_ID ?? ''
        )
        const plan =
          data ??
          plans.find(
            (plan) =>
              plan.recurring.interval === 'year' && plan.unit_amount === 0
          )

        if (plan) {
          setSelectedPlan(plan)
        }
      }

      fetch0InsurancePlan()
    } else {
      if (plans.length) {
        setSelectedPlan(plans[plans.length - 1])
      }
    }
  }, [plans, program, currentProgram])

  return (
    <Modal
      onHide={onHide}
      show={program !== undefined}
      size="lg"
      centered
      className="join-program-modal"
    >
      {program && (
        <PromoCodeProvider plan={selectedPlan}>
          <Modal.Header closeButton>
            <Modal.Title>
              Continue with{' '}
              {currentProgram !== PROGRAMS.INSURANCE_DIAGNOSTIC
                ? `the ${title}`
                : `an annual ${title}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="d-flex flex-column pb-3 join-program-modal-body">
                <p className="fs-6 text-center">What&apos;s Included:</p>

                <div className="feature-list">
                  {features.map((feature, i) => (
                    <div key={i} className="d-flex align-items-center mb-3">
                      <div className="feature-check-icon">
                        <CheckIcon />
                      </div>
                      <p className="program-card-feature mb-0 fs-3">
                        {feature}
                      </p>
                    </div>
                  ))}
                </div>

                {!(
                  currentProgram == PROGRAMS.INSURANCE_DIAGNOSTIC &&
                  program === PROGRAMS.INSURANCE
                ) &&
                  plans.length > 1 && (
                    <div className="pricing-plans mw-300 mb-4 px-5 py-2">
                      <div className="d-flex flex-column">
                        {plans.map((plan, i) => (
                          <SelectPlan
                            key={plan.id}
                            plan={plan}
                            isLast={i === plans.length - 1}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                <JoinModalConfirm
                  currentProgram={currentProgram}
                  program={program}
                  promo={promo}
                  handleClose={handleClose}
                  onSuccess={onSuccess}
                />
              </div>
            )}
          </Modal.Body>
        </PromoCodeProvider>
      )}
    </Modal>
  )
}

export default memo(JoinModal)
