import { Thread } from '@allara-health/source-health-client'
import { useAppDispatch } from 'app/hooks'
import { RoutePaths } from 'containers/Core/Routes'
import {
  setIsDrawerOpen,
  setLastChatThread,
} from 'features/dashboard/dashbordSlice'
import { memo, useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  getAssignee,
  getAssigneeName,
  getDate,
  isUnread as _isUnread,
} from 'utils/chat.utils'
import ProfileImage from '../../../components/SourceProfileImage/ProfileImage'

interface ThreadPreviewProps {
  thread: Thread
}

const ThreadPreview: React.FC<ThreadPreviewProps> = ({ thread }) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const assignee = useMemo(() => getAssignee(thread), [thread])
  const assigneeName = useMemo(() => getAssigneeName(assignee), [assignee])
  const isUnread = useMemo(() => _isUnread(thread), [thread])
  const isActive = useMemo(() => location.pathname.includes(thread.id), [
    location,
  ])

  const handleClick = useCallback(() => {
    dispatch(setLastChatThread(thread.id))
    dispatch(setIsDrawerOpen(false))
    history.push(`${RoutePaths.DASHBOARD_CHAT}/${thread.id}`)
  }, [])

  return (
    <div
      className={`thread-preview d-flex align-items-center cursor-pointer py-3 pr-2 ${
        isActive ? 'active' : ''
      }`}
      onClick={handleClick}
    >
      <div className={`unread mx-1 ${isUnread ? 'visible' : 'invisible'}`} />
      <ProfileImage user={assignee} className="mx-2" />
      <div className="thread-details d-flex flex-column fs-3 mx-1 flex-1 text-truncate">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <p className="mb-0 font-weight-medium">{assigneeName}</p>
          <div className="thread-details-metadata fs-2 d-flex align-items-center">
            <div className="thread-details-metadata-date mr-1">
              {getDate(thread)}
            </div>
            <div className="thread-details-metadata-status ff-inter-medium">
              {thread.status === 'closed' ? (
                <div className="thread-details-metadata-status closed">
                  Closed
                </div>
              ) : (
                <div className="thread-details-metadata-status open">Open</div>
              )}
            </div>
          </div>
        </div>
        <div className="spaced-capital-letters thread-subject text-truncate">
          {thread.subject ?? assigneeName}
        </div>
        <div
          className={`thread-details-last-message-preview text-truncate ${
            isUnread ? 'font-weight-bold' : 'color-gray-dark'
          }`}
        >
          {thread.last_message.text}
        </div>
      </div>
    </div>
  )
}

export default memo(ThreadPreview)
