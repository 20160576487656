export const ContactAllaraEmail = 'concierge@allarahealth.com'

export const FacebookHref = `https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_SHARING_URL}`

export const BlogUrl = `${process.env.REACT_APP_SHARING_URL}/blog`

export const CareTeamUrl = 'https://allarahealth.com/care-team/'

export const InstagramUrl = 'https://instagram.com/allarahealth'

export const PlayStoreUrl =
  'https://play.google.com/store/apps/details?id=dev.azo.allara'

export const AppStoreUrl = 'https://apps.apple.com/us/app/allara/id1660816488'
