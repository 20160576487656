import { useStytch, useStytchSession } from '@stytch/react'
import { Automation as AutomationRoute } from 'api/automations/routes'
import {
  Insurance as InsuranceRoute,
  QueryKey as InsuranceQueryKey,
} from 'api/insurance/routes'
import axios, { AxiosResponse } from 'axios'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import JSZip from 'jszip'
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'

export type Insurance = {
  id: string
  user: string // id
  uploaded: string // date
  success: boolean
}

export type ElationInsuranceCard = {
  rank: number
  images: {
    side: 1 | 2
    url: string
    ttl: number
  }[]
}

export type UploadInsuranceCardParams = {
  front: File
  back: File
}
export type UploadInsuranceData = {
  insured_person_dob?: string
  insured_person_first_name?: string
  insured_person_last_name?: string
  insured_person_address?: string
  insured_person_city?: string
  insured_person_state?: string
  insured_person_zip?: string
  relationship_to_insured?: string
  group_id?: string
  member_id?: string
  payer_id?: string
  other_payer?: string
  rank: 'primary' | 'secondary' | 'tertiary'
}

interface UseInsuranceInterface {
  isLoading: boolean
  insurance: Insurance | undefined
  downloadInsurance: (insurance: Insurance) => Promise<void>
  handleUploadInsuranceCard: UseMutateAsyncFunction<
    AxiosResponse<string> | null,
    unknown,
    UploadInsuranceCardParams,
    unknown
  >
  handleUploadInsuranceData: UseMutateAsyncFunction<
    AxiosResponse<string> | null,
    unknown,
    UploadInsuranceData,
    unknown
  >
}

const useInsurance = (): UseInsuranceInterface => {
  const { setAlertText } = useAlert()
  const queryClient = useQueryClient()
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchInsurance = async (): Promise<Insurance | undefined> => {
    if (!session) {
      return undefined
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${InsuranceRoute}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          return undefined
        }
      })
      .catch(() => {
        return undefined
      })
  }

  const { isLoading, data: insurance } = useQuery(
    InsuranceQueryKey,
    fetchInsurance,
    { staleTime: Infinity }
  )

  const downloadInsurance = async () => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${InsuranceRoute}/download`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
      .then((res) => res.json())
      .then((insuranceCard: ElationInsuranceCard) => {
        const zip = new JSZip()
        const listOfPromises = insuranceCard.images.map(async (side) => {
          const data = await fetch(side.url).then((res) => res.blob())
          zip.file(
            `insurance_${new Date(insurance?.uploaded ?? 0).getTime()}_${
              side.side
            }.jpg`,
            data
          )
        })

        Promise.all(listOfPromises)
          .then(() => zip.generateAsync({ type: 'blob' }))
          .then((content) => {
            const url = window.URL.createObjectURL(content)
            const a = document.createElement('a')
            a.href = url
            a.download = 'insurance.zip'
            a.click()
            window.URL.revokeObjectURL(url)
          })
      })
      .catch((err) =>
        setAlertText(
          'Unable to download insurance card. Please try again.',
          'An Error Occurred',
          err
        )
      )
  }

  const uploadInsuranceCard = async (
    uploadInsuranceCardParams: UploadInsuranceCardParams
  ): Promise<AxiosResponse<string> | null> => {
    if (!session) {
      return null
    }

    const formData = new FormData()
    formData.append('front', uploadInsuranceCardParams.front)
    formData.append('back', uploadInsuranceCardParams.back)

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${AutomationRoute}/patient/insurance/upload`,
        formData,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .catch((err) => {
        setAlertText(
          `Unable to upload files ${uploadInsuranceCardParams.front.name} and ${uploadInsuranceCardParams.back.name}. Please reload the page and try again.`,
          'An Error Occurred',
          err
        )
        return null
      })
  }

  const uploadInsuranceData = async (
    uploadInsuranceDataParams: UploadInsuranceData
  ): Promise<AxiosResponse<string> | null> => {
    if (!session) {
      return null
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${AutomationRoute}/patient/insurance`,
        uploadInsuranceDataParams,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .catch((err) => {
        setAlertText(`Unable to add insurance data`, 'An Error Occurred', err)

        return null
      })
  }

  const mutateUpload = useMutation(uploadInsuranceCard, {
    onSettled: () => queryClient.invalidateQueries(InsuranceQueryKey),
    onMutate: async () => {
      await queryClient.cancelQueries(InsuranceQueryKey)

      const previousData = queryClient.getQueryData<Insurance>(
        InsuranceQueryKey
      )

      if (previousData) {
        queryClient.setQueryData<Insurance>(InsuranceQueryKey, {
          id: '0',
          uploaded: new Date().toISOString(),
          user: '0',
          success: true,
        })
      }

      return { previousData }
    },
  })

  const mutateUploadData = useMutation(uploadInsuranceData, {
    onSettled: () => queryClient.invalidateQueries(InsuranceQueryKey),
  })
  const handleUploadInsuranceCard = mutateUpload.mutateAsync
  const handleUploadInsuranceData = mutateUploadData.mutateAsync
  return {
    isLoading,
    insurance,
    downloadInsurance,
    handleUploadInsuranceCard,
    handleUploadInsuranceData,
  }
}

export default useInsurance
