import React from 'react'
import { Box, Container, Heading, Text, Image } from '@chakra-ui/react'
import logo from 'assets/images/logo.svg'
import { ChakraProvider } from '@chakra-ui/react'

const Downtime: React.FC = () => {
  return (
    <ChakraProvider>
      <Box
        w="100vw"
        h="100vh"
        bgGradient="linear(to-b, white, #5988ff)"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Container width="100%" height={'100%'} centerContent m={8}>
          <Box
            display={{ md: 'flex' }}
            flexDirection="column"
            alignItems="center"
            marginY={20}
            borderRadius={8}
          >
            <Image src={logo} borderRadius="full" boxSize="150px" />
            <Heading as="h4" size="l" textAlign="center">
              We&apos;re currently down for scheduled maintenance and will be
              back up shortly.
            </Heading>
            <Text my={4} textAlign="center">
              We appreciate your patience.
            </Text>
          </Box>
        </Container>
      </Box>
    </ChakraProvider>
  )
}

export default Downtime
