import React, { FC, memo, MouseEvent, useRef, useState } from 'react'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  Suggestion,
} from 'use-places-autocomplete'
import { Form, FormControlProps } from 'react-bootstrap'

import InputContainer from 'hoc/InputContainer'
import useOutsideOnClick from 'hooks/useOutsideOnClick'

interface InputProps extends FormikProps, FormControlProps {
  containerClassName?: string
  suggestionContainerClass?: string
  suggestionClass?: string
  inputClass?: string
  placeholder?: string
  validation?: (e: string) => boolean
  value?: string
  searchOptions?: SearchOption
  onSelect: (code: Result) => void
  error?: string
  label?: string
  noArrow?: boolean
  controlId?: string
}

const AddressAutocomplete: FC<InputProps> = (props) => {
  const [focused, setFocused] = useState(false)
  const containerRef = useRef(null)

  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'us' },
      types: ['(regions)'],
      ...props?.searchOptions,
    },
    debounce: 300,
  })

  const {
    controlId,
    onChange = () => ({}),
    placeholder,
    containerClassName,
    suggestionClass,
    suggestionContainerClass,
    inputClass,
    validation,
    error,
    label,
    value,
    onSelect,
    onBlur,
    noArrow,
    ...rest
  } = props

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validation) {
      if (validation(e.target.value)) {
        setValue(e.target.value)
        onChange(e)
      }
    } else {
      setValue(e.target.value)
      onChange(e)
    }
  }

  const handleSelect = (suggestion: Suggestion, e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const { place_id, structured_formatting, description } = suggestion
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"

    setValue(description, false)
    setFocused(false)
    // @ts-expect-error: Need to update autocomplete component to add types
    let result: Result = null
    getGeocode({ placeId: place_id })
      .then((res) => {
        // @ts-expect-error: Need to update autocomplete component to add types
        result = { ...res[0] }
        return getLatLng(res[0])
      })
      .then((resp) => {
        clearSuggestions()
        result = { ...result, ...resp, ...structured_formatting }
        onSelect(result)
      })
      .catch(() => {
        // TODO: Error handling to be done here
      })
  }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <li
          className={suggestionClass}
          key={place_id}
          onClick={(e) => handleSelect(suggestion, e)}
        >
          <a className="dropdown-item" href="#">
            <span className="dropdown-item-text">
              {main_text}, {secondary_text}
            </span>
          </a>
        </li>
      )
    })

  useOutsideOnClick({
    containerRef,
    callback: () => {
      setFocused(false)
    },
  })

  return (
    <InputContainer
      className={`position-relative ${
        noArrow ? 'no-arrow' : ''
      } ${containerClassName}`}
      controlId={controlId}
      label={label}
      error={error}
      ref={containerRef}
    >
      <Form.Control
        size="lg"
        autoComplete="off"
        disabled={!ready}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        className={inputClass}
        {...rest}
      />
      <ul
        className={
          'position-absolute dropdown-menu ' + suggestionContainerClass
        }
        style={{ display: focused && status === 'OK' ? 'block' : 'none' }}
        aria-labelledby={controlId}
      >
        {renderSuggestions()}
      </ul>
    </InputContainer>
  )
}

export default memo(AddressAutocomplete)
