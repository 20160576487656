"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentResource = void 0;
const BaseResource_1 = require("../BaseResource");
class CommentResource extends BaseResource_1.Resource {
    /**
     * Returns a list of all comments related to a task.
     *
     * The comments returned are sorted by creation date, with the most recently added
     * comments appearing first.
     */
    list(taskId, params, options) {
        return this.source.request('GET', `/v1/tasks/${taskId}/comments`, {
            query: params,
            options,
        });
    }
    /**
     * Creates a new comment on a task.
     */
    create(taskId, params, options) {
        return this.source.request('POST', `/v1/tasks/${taskId}/comments`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Finds a comment on a task
     */
    retrieve(taskId, id, options) {
        return this.source.request('GET', `/v1/tasks/${taskId}/comments/${id}`, {
            options,
        });
    }
    /**
     * Updates an existing comment on a task.
     */
    update(taskId, id, params, options) {
        return this.source.request('POST', `/v1/tasks/${taskId}/comments/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Deletes the specified comment. A deleted comment is no longer visible.
     */
    delete(taskId, id, options) {
        return this.source.request('DELETE', `/v1/tasks/${taskId}/comments/${id}`, {
            contentType: 'json',
            options,
        });
    }
}
exports.CommentResource = CommentResource;
