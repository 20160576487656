"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceResource = void 0;
const BaseResource_1 = require("../../BaseResource");
class DeviceResource extends BaseResource_1.Resource {
    /**
     * Retrieves the details of an existing device. You need only supply the unique
     * device identifier that was returned upon device creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/devices/${id}`, {
            options,
        });
    }
    /**
     * Returns a list of devices within the current account. The devices returned are
     * sorted by creation date, with the most recently added devices appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/devices', {
            query: params,
            options,
        });
    }
}
exports.DeviceResource = DeviceResource;
