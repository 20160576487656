import { Col, Row } from 'react-bootstrap'
import { ReactComponent as RightIcon } from 'assets/images/chevron-right.svg'
import { PillButton } from 'components/Button'
import TextField from 'components/TextField'
import Loader from 'components/Loader'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import Select from 'components/Select'
import UploadCard from 'assets/images/upload_card.png'
import { ALLOWED_TYPES, Links, RelationshipToInsured } from './constants'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { insuranceFormSchema } from './utils'
import useInsurance from 'hooks/useInsuranceCard'
import StateSelect from 'components/Select/StateSelect'
import { Amplitude } from 'utils/amplitude.utils'
import usePayers from 'hooks/usePayers'
import { TypeaheadSelect } from 'components/Typeahead'
import { useToast } from '@chakra-ui/react'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'

const rowProps = {
  className: 'py-1',
}

export interface InsuranceFormValues {
  insured_person_dob?: string
  insured_person_first_name?: string
  insured_person_last_name?: string
  insured_person_address_line_1?: string
  insured_person_address_line_2?: string
  insured_person_city?: string
  insured_person_state?: string
  insured_person_zip?: string
  relationship_to_insured: string
  group_id?: string
  member_id: string
  payer_id?: string
  other_payer?: string
  rank: 'primary' | 'secondary' | 'tertiary'
}

const initialValues: InsuranceFormValues = {
  insured_person_dob: '',
  insured_person_first_name: '',
  insured_person_last_name: '',
  relationship_to_insured: 'Self',
  group_id: '',
  member_id: '',
  payer_id: '',
  other_payer: '',
  rank: 'primary',
}

const OTHER_PAYER_VALUE = 'other'

type InsuranceInfoFormProps = {
  title?: string
  subTitle?: string
  supportComponent?: React.ReactNode
  onSubmit: () => void
}

const InsuranceInfoForm: React.FC<InsuranceInfoFormProps> = ({
  title,
  subTitle,
  supportComponent,
  onSubmit,
}) => {
  const toast = useToast()
  const { payers } = usePayers()
  const { setAlertText } = useAlert()
  const {
    handleUploadInsuranceCard,
    handleUploadInsuranceData,
  } = useInsurance()
  const { variantLabel } = useOnboardingContext()

  const backFileRef = useRef<HTMLInputElement>(null)
  const frontFileRef = useRef<HTMLInputElement>(null)
  const [backFile, setBackFile] = useState<File | null>(null)
  const [frontFile, setFrontFile] = useState<File | null>(null)

  const startTime = useMemo(() => {
    return Date.now()
  }, [])

  const payerOptions = useMemo(
    () => payers.map((p) => ({ label: p.name, id: p.id })),
    [payers]
  )

  const onSubmitHandler = async (
    {
      insured_person_dob,
      insured_person_first_name,
      insured_person_last_name,
      insured_person_address_line_1,
      insured_person_address_line_2,
      insured_person_city,
      insured_person_state,
      insured_person_zip,
      relationship_to_insured,
      group_id,
      member_id,
      payer_id,
      other_payer,
      rank,
    }: InsuranceFormValues,
    { setSubmitting }: FormikHelpers<InsuranceFormValues>
  ) => {
    if (!frontFile) {
      setAlertText(
        'Please upload an image for the front of the card.',
        'An Error Occurred'
      )
      setSubmitting(false)
      return
    } else if (!backFile) {
      setAlertText(
        'Please upload an image for the back of the card.',
        'An Error Occurred'
      )
      setSubmitting(false)
      return
    }

    const addressLine2 = insured_person_address_line_2
      ? ` ${insured_person_address_line_2}`
      : ''
    const insured_person_address = insured_person_address_line_1
      ? `${insured_person_address_line_1}${addressLine2}`
      : undefined

    const dataUploadReponse = await handleUploadInsuranceData({
      payer_id: payer_id === OTHER_PAYER_VALUE ? undefined : payer_id,
      other_payer,
      group_id,
      insured_person_dob,
      insured_person_first_name,
      insured_person_last_name,
      insured_person_address,
      insured_person_city,
      insured_person_state,
      insured_person_zip,
      member_id,
      relationship_to_insured:
        relationship_to_insured === 'Self'
          ? undefined
          : relationship_to_insured,
      rank,
    })

    if (!dataUploadReponse || dataUploadReponse.status !== 201) {
      setSubmitting(false)
      toast({
        title: 'An error occurred',
        description:
          'We were unable to upload your insurance card. Please try again or email concierge@allarahealth.com if the problem persists',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })

      return
    }

    // Upload all of the files
    await handleUploadInsuranceCard({
      front: frontFile,
      back: backFile,
    })

    setSubmitting(false)
    Amplitude.insuranceDetails(
      Math.round((Date.now() - startTime) / 1000),
      variantLabel
    )
    onSubmit()
  }

  const handleChooseBackFile = useCallback(
    () => handleChooseFile(backFileRef, 'back'),
    [backFileRef]
  )

  const handleChooseFrontFile = useCallback(
    () => handleChooseFile(frontFileRef, 'front'),
    [frontFileRef]
  )

  const handleChooseFile = useCallback(
    (fileRef: React.RefObject<HTMLInputElement>, side: 'front' | 'back') => {
      if (fileRef?.current?.files?.[0]) {
        // Validate size of file and add name
        const file = fileRef.current.files[0]

        if (file) {
          if (file.size > 5000000) {
            setAlertText(
              `File ${file.name} is too large. Must be less than 5MB. For larger files please email concierge@allarahealth.com`,
              'An Error Occurred'
            )
            return
          } else if (!ALLOWED_TYPES.includes(file.type)) {
            setAlertText(
              `File must be a JPG or PNG. For other files please email concierge@allarahealth.com`,
              'An Error Occurred'
            )
            return
          }

          side === 'front' ? setFrontFile(file) : setBackFile(file)
        }
      }
    },
    []
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={insuranceFormSchema}
      onSubmit={onSubmitHandler}
    >
      {({
        values,
        touched,
        errors,
        isSubmitting,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => {
        const {
          insured_person_dob,
          insured_person_first_name,
          insured_person_last_name,
          insured_person_address_line_1,
          insured_person_address_line_2,
          insured_person_city,
          insured_person_state,
          insured_person_zip,
          relationship_to_insured,
          group_id,
          member_id,
          payer_id,
          other_payer,
        } = values

        return (
          <div className="d-flex justify-content-center align-items-center">
            <form
              onSubmit={handleSubmit}
              className="insurance-info-form d-flex justify-content-center"
            >
              <div className="checkout-form-sub checkout-form-sub-1 justify-content-center align-items-center">
                {title && (
                  <h2 className="fs-7 text-center header ff-inter-medium">
                    {title}
                  </h2>
                )}
                <p className="text-center ff-inter-medium sub-header">
                  {subTitle ??
                    'To get started, please share your insurance information:'}
                </p>
                <Row {...rowProps} className="py-2">
                  <Col>
                    <TypeaheadSelect
                      controlId="payer_id"
                      label="Insurance carrier name*"
                      options={payerOptions}
                      highlightOnlyResult={true}
                      emptyLabel="No matches, please select 'Other'"
                      error={
                        errors.payer_id && touched.payer_id
                          ? errors.payer_id
                          : ''
                      }
                    />
                  </Col>
                </Row>
                {payer_id === OTHER_PAYER_VALUE && (
                  <Row>
                    <Col>
                      <TextField
                        label="Other insurance carrier name*"
                        controlId="other_payer"
                        value={other_payer}
                        error={
                          errors.other_payer && touched.other_payer
                            ? errors.other_payer
                            : ''
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                )}
                <Row {...rowProps}>
                  <Col>
                    <TextField
                      label="Member ID*"
                      controlId="member_id"
                      value={member_id}
                      error={
                        errors.member_id && touched.member_id
                          ? errors.member_id
                          : ''
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <TextField
                      label="Group ID"
                      controlId="group_id"
                      value={group_id}
                      error={
                        errors.group_id && touched.group_id
                          ? errors.group_id
                          : ''
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row {...rowProps}>
                  <Col>
                    <Select
                      options={RelationshipToInsured}
                      label="Patient's relation to policyholder*"
                      controlId="relationship_to_insured"
                      value={relationship_to_insured}
                      error={
                        errors.relationship_to_insured &&
                        touched.relationship_to_insured
                          ? errors.relationship_to_insured
                          : ''
                      }
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                {relationship_to_insured.length > 0 &&
                  relationship_to_insured !== 'Self' && (
                    <div>
                      <p className="black-text">Insured Person Details</p>
                      <p className="fs-3 black-text">
                        Please complete the following personal details about the
                        policy holder
                      </p>
                      <Row {...rowProps}>
                        <Col>
                          <TextField
                            label="First Name*"
                            controlId="insured_person_first_name"
                            value={insured_person_first_name}
                            error={
                              errors.insured_person_first_name &&
                              touched.insured_person_first_name
                                ? errors.insured_person_first_name
                                : ''
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label="Last Name*"
                            controlId="insured_person_last_name"
                            value={insured_person_last_name}
                            error={
                              errors.insured_person_last_name &&
                              touched.insured_person_last_name
                                ? errors.insured_person_last_name
                                : ''
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            type="date"
                            label="Date of Birth*"
                            controlId="insured_person_dob"
                            value={insured_person_dob}
                            error={
                              errors.insured_person_dob &&
                              touched.insured_person_dob
                                ? errors.insured_person_dob
                                : ''
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label="Address*"
                            controlId="insured_person_address_line_1"
                            value={insured_person_address_line_1}
                            error={
                              errors.insured_person_address_line_1 &&
                              touched.insured_person_address_line_1
                                ? errors.insured_person_address_line_1
                                : ''
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label="Apartment, Suite, Etc"
                            controlId="insured_person_address_line_2"
                            value={insured_person_address_line_2}
                            error={
                              errors.insured_person_address_line_2 &&
                              touched.insured_person_address_line_2
                                ? errors.insured_person_address_line_2
                                : ''
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label="City*"
                            controlId="insured_person_city"
                            value={insured_person_city}
                            error={
                              errors.insured_person_city &&
                              touched.insured_person_city
                                ? errors.insured_person_city
                                : ''
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={6}>
                          <StateSelect
                            label="State*"
                            controlId="insured_person_state"
                            value={insured_person_state}
                            error={
                              errors.insured_person_state &&
                              touched.insured_person_state
                                ? errors.insured_person_state
                                : ''
                            }
                            onChange={handleChange}
                          />
                        </Col>
                        <Col xs={12} sm={6}>
                          <TextField
                            label="ZipCode*"
                            controlId="insured_person_zip"
                            value={insured_person_zip}
                            error={
                              errors.insured_person_zip &&
                              touched.insured_person_zip
                                ? errors.insured_person_zip
                                : ''
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <hr />
                    </div>
                  )}
                <p className="black-text">Upload Images</p>
                <p className="fs-3 black-text">
                  Please upload an image of the front and back of your insurance
                  card
                </p>
                <Row {...rowProps}>
                  <Col>
                    <div className="upload-card">
                      <img
                        className={`position-absolute ${
                          frontFile ? 'img' : ''
                        }`}
                        src={
                          frontFile
                            ? URL.createObjectURL(frontFile)
                            : UploadCard
                        }
                      />
                      <input
                        id="choose-front-input"
                        className="d-flex justify-content-center align-items-center"
                        ref={frontFileRef}
                        type="file"
                        accept="image/jpeg,image/jpg,image/png"
                        onChange={handleChooseFrontFile}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="upload-card">
                      <img
                        className={`position-absolute ${backFile ? 'img' : ''}`}
                        src={
                          backFile ? URL.createObjectURL(backFile) : UploadCard
                        }
                      />
                      <input
                        id="choose-front-input"
                        className="d-flex justify-content-center align-items-center"
                        ref={backFileRef}
                        type="file"
                        accept="image/jpeg,image/jpg,image/png"
                        onChange={handleChooseBackFile}
                      />
                    </div>
                  </Col>
                </Row>
                <Row {...rowProps} className="py-3">
                  <Col className="d-flex justify-content-center">
                    <PillButton
                      className="checkout-button position-relative justify-content-between w-100"
                      type="submit"
                      block
                      disabled={
                        isSubmitting ||
                        !(
                          payer_id &&
                          (payer_id === OTHER_PAYER_VALUE
                            ? other_payer
                            : true) &&
                          member_id &&
                          relationship_to_insured &&
                          (relationship_to_insured !== 'Self'
                            ? insured_person_dob &&
                              insured_person_first_name &&
                              insured_person_last_name &&
                              insured_person_address_line_1 &&
                              insured_person_city &&
                              insured_person_state &&
                              insured_person_zip
                            : true) &&
                          backFile !== null &&
                          frontFile !== null
                        )
                      }
                    >
                      {isSubmitting ? (
                        <Loader />
                      ) : (
                        <>
                          <div>Submit</div>
                          <div className="continue-button">
                            <RightIcon />
                          </div>
                        </>
                      )}
                    </PillButton>
                  </Col>
                </Row>
                {supportComponent ?? (
                  <Row className="py-3">
                    <Col className="d-flex flex-column justify-content-center align-items-center">
                      <p>
                        Note: If you have any Blue plan, please select the
                        &lsquo;Blue Cross Blue Shield&rsquo; option in the drop
                        down. If you do not see your carrier listed in the
                        dropdown or need some help, please email
                        <a
                          href={`mailto: ${Links.ContactEmail}`}
                          className="underline"
                        >
                          {Links.ContactEmail}
                        </a>
                      </p>
                    </Col>
                  </Row>
                )}
              </div>
            </form>
          </div>
        )
      }}
    </Formik>
  )
}

export default InsuranceInfoForm
