import Loader from 'components/Loader'
import { RoutePaths } from 'containers/Core/Routes'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import {
  isProgramType,
  Program,
  useOnboardingContext,
} from 'context/OnboardingContext/OnboardingProvider'
import withEligibility, { WithEligibilityProps } from 'hoc/withEligibility'
import useSearchParams from 'hooks/useSearchParams'
import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import PaymentPlans from '.'

const PaymentPlansRoute: React.FC<WithEligibilityProps> = ({
  waitlistUser,
}) => {
  const { setAlertText } = useAlert()
  const searchParams = useSearchParams()
  const { selectedProgram, setSelectedProgram } = useOnboardingContext()

  const program = searchParams.get('program')
  const isValidProgram = program ? isProgramType(program) : false

  useEffect(() => {
    if (isValidProgram) {
      setSelectedProgram(program as Program)
    }
  }, [program])

  if (!waitlistUser) {
    return <Loader />
  } else if (
    !selectedProgram &&
    !waitlistUser.selectedProduct &&
    !isValidProgram
  ) {
    return <Redirect to={`${RoutePaths.PROGRAMS}${window.location.search}`} />
  } else if (isValidProgram) {
    return <PaymentPlans program={program as Program} isDirectFlow={true} />
  } else if (selectedProgram) {
    // Handle routing based on app selected program state
    if (
      !waitlistUser.eligibleProducts
        .map((p) => p.type)
        .includes(selectedProgram)
    ) {
      setAlertText(
        `You are not eligible for the ${selectedProgram} program`,
        'Alert'
      )
      return <Redirect to={`${RoutePaths.PROGRAMS}${window.location.search}`} />
    } else {
      return <PaymentPlans program={selectedProgram} />
    }
  } else if (waitlistUser.selectedProduct) {
    // Handle routing based on DB selected product
    if (
      !waitlistUser.eligibleProducts
        .map((p) => p.type)
        .includes(waitlistUser.selectedProduct.type)
    ) {
      setAlertText(
        `You are not eligible for the ${waitlistUser.selectedProduct.type} program`,
        'Alert'
      )
      return <Redirect to={`${RoutePaths.PROGRAMS}${window.location.search}`} />
    } else {
      setSelectedProgram(waitlistUser.selectedProduct.type)
      return <PaymentPlans program={waitlistUser.selectedProduct.type} />
    }
  }

  return <Redirect to={`${RoutePaths.PROGRAMS}${window.location.search}`} />
}

export default withEligibility(PaymentPlansRoute)
