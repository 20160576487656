import { ImageProps } from 'react-bootstrap'
import logo from 'assets/images/logo.svg'
import { memo } from 'react'
import { Image } from '@chakra-ui/react'

const AppLogo: React.FC<ImageProps> = ({ className = '', ...props }) => {
  return (
    <div className={'logo-container ' + className}>
      <a href="/">
        <Image src={logo} />
      </a>
    </div>
  )
}

export default memo(AppLogo)
