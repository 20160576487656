import {
  AppointmentType,
  AppointmentTypeListResponse,
} from '@allara-health/source-health-client'
import { AppointmentTypeQueryKey } from 'api/source/routes'
import { useSourceContext } from 'context/SourceContext/SourceProvider'
import { useQuery } from 'react-query'

interface UseAppointmentTypeInterface {
  appointmentType?: AppointmentType
  isLoading: boolean
  appointmentTypeList?: AppointmentTypeListResponse
  isFetchAppointmentTypeListLoading: boolean
}

const useAppointmentType = (
  appointmentTypeId?: string
): UseAppointmentTypeInterface => {
  const { source } = useSourceContext()

  const fetchAppointmentType = async (): Promise<
    AppointmentType | undefined
  > => {
    if (!appointmentTypeId) {
      return
    }

    return source.scheduling.appointmentTypes.retrieve(appointmentTypeId)
  }

  const { data: appointmentType, isLoading } = useQuery(
    `${AppointmentTypeQueryKey}-${appointmentTypeId}`,
    fetchAppointmentType
  )

  const fetchAppointmentTypeList = async (): Promise<AppointmentTypeListResponse> => {
    return source.scheduling.appointmentTypes.list()
  }

  const {
    data: appointmentTypeList,
    isLoading: isFetchAppointmentTypeListLoading,
  } = useQuery(`all-${AppointmentTypeQueryKey}`, fetchAppointmentTypeList)

  return {
    appointmentType,
    isLoading,
    appointmentTypeList,
    isFetchAppointmentTypeListLoading,
  }
}

export default useAppointmentType
