import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

export interface QuestionnaireResponse {
  providerTypes?: number[]
  goals?: number[]
  labWorkDone?: boolean | null
  notes?: string | null
  otherProvider?: string | null
  previouslyDiagnosedPCOS?: boolean | null
  userId?: string | null
}

interface State {
  questionnaireResponse: QuestionnaireResponse
}

const initialState: State = {
  questionnaireResponse: {},
}

const onboardingSlice = createSlice({
  initialState,
  name: 'onboarding',
  reducers: {
    setProviderTypes: (state, action: PayloadAction<number[]>) => {
      state.questionnaireResponse.providerTypes = action.payload
    },
    setOtherProviders: (state, action: PayloadAction<string>) => {
      state.questionnaireResponse.otherProvider = action.payload
    },
    setGoals: (state, action: PayloadAction<number[]>) => {
      state.questionnaireResponse.goals = action.payload
    },
    setLabWorkDone: (state, action: PayloadAction<boolean>) => {
      state.questionnaireResponse.labWorkDone = action.payload
    },
    setNotes: (state, action: PayloadAction<string>) => {
      state.questionnaireResponse.notes = action.payload
    },
    setPreviouslyDiagnosedPCOS: (state, action: PayloadAction<boolean>) => {
      state.questionnaireResponse.previouslyDiagnosedPCOS = action.payload
    },
    setQuestionnaireResponse: (
      state,
      action: PayloadAction<QuestionnaireResponse>
    ) => {
      const qResp = { ...state.questionnaireResponse, ...action.payload }
      state.questionnaireResponse = qResp
    },
  },
})

export const {
  setProviderTypes,
  setGoals,
  setLabWorkDone,
  setNotes,
  setOtherProviders,
  setPreviouslyDiagnosedPCOS,
  setQuestionnaireResponse,
} = onboardingSlice.actions

export const selectQuestionnaireResponse = (
  state: RootState
): QuestionnaireResponse => state.onboarding.questionnaireResponse

export default onboardingSlice.reducer
