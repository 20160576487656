import { useQuery } from 'react-query'
import { User } from './useUser'
import { NewPatientQueryKey, UserInfo } from 'api/common/routes'
import { useStytch, useStytchSession } from '@stytch/react'

interface UseNewPatientInterface {
  user?: User
  isLoading: boolean
}

const useNewPatient = (): UseNewPatientInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchUser = async (): Promise<User | undefined> => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${UserInfo}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then(async (res) => {
      const data = await res.json()
      const user = data as User

      if (res.ok && user.sourceHealthId) {
        return user
      } else {
        throw new Error('Not ready yet')
      }
    })
  }

  const { data: user, isLoading } = useQuery(NewPatientQueryKey, fetchUser, {
    retry: 8,
    retryDelay: (attempt) => 500 * 1.5 ** attempt,
  })

  return { user, isLoading }
}

export default useNewPatient
