"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebhookResource = void 0;
const BaseResource_1 = require("../BaseResource");
class WebhookResource extends BaseResource_1.Resource {
    /**
     * Lists all webhooks for the current account. The webhooks returned are sorted by
     * creation date, with the most recently created webhooks appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/webhooks', {
            query: params,
            options,
        });
    }
    /**
     * A webhook endpoint must have a URL and a list of events.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/webhooks', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing webhook. You need only supply the unique
     * webhook identifier that was returned upon creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/webhooks/${id}`, {
            options,
        });
    }
    /**
     * Updates the webhook endpoint. You may edit the URL and list of events for the
     * webhook.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/webhooks/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Removes a webhook from your account, which will stop sending events to your
     * endpoint
     */
    delete(id, options) {
        return this.source.request('DELETE', `/v1/webhooks/${id}`, {
            contentType: 'json',
            options,
        });
    }
}
exports.WebhookResource = WebhookResource;
