import Loader from 'components/Loader'
import useLabs, { Lab } from 'hooks/useLabs'
import { Modal, ModalProps } from 'react-bootstrap'
import UploadLabsForm from './UploadLabsForm'

interface LabsModalProps extends ModalProps {
  onHide: () => void
  lab?: Lab // Indicates an update
}

const UploadLabsModal: React.FC<LabsModalProps> = ({ show, onHide, lab }) => {
  const { isSubmitting } = useLabs()

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="lg"
      centered
      className="upload-labs-modal"
    >
      <Modal.Header className="fs-6" closeButton>
        {lab ? 'Add Report Date to Lab' : 'Share Previous Labs with Provider'}
      </Modal.Header>
      <Modal.Body className="justify-content-center p4">
        {lab && (
          <p>
            We do not have a record of when these labs were completed. If you
            would like to add a (rough) date, please do so below. Otherwise you
            may close this message.
          </p>
        )}
        {isSubmitting ? (
          <div>
            <p className="text-center">{lab ? 'Updating' : 'Uploading'}...</p>
            <div className="position-relative my-3">
              <Loader />
            </div>
          </div>
        ) : (
          <UploadLabsForm
            buttonText={lab ? 'Update' : 'Upload'}
            onSubmit={onHide}
            lab={lab}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default UploadLabsModal
