import {
  Member,
  MessageAttachment,
  Message as MessageResource,
  User,
} from '@allara-health/source-health-client'
import { FC, ComponentType } from 'react'
import {
  Avatar,
  Box,
  Flex,
  Text,
  Link,
  VStack,
  HStack,
  Icon,
} from '@chakra-ui/react'
import { AttachmentIcon } from '@chakra-ui/icons'
import { ReactComponent as AllaraLogo } from '../../../assets/images/logo-blue.svg'
import Linkify from 'components/Linkify'
import { expand } from 'utils/chat.utils'
import { AvatarProps } from './MessageList'
import { Formatter } from 'types'
import { Name } from 'components/MessageName'

export interface MessageProps {
  /**
   * Message that should be rendered in this component
   */
  message: MessageResource
  /**
   * Whether or not this message is grouped with the previous message
   */
  groupWithPrevious?: boolean
  /**
   * Whether or not this message is grouped with the next message
   */
  groupWithNext?: boolean
  /**
   * Custom function to format a date into a string
   */
  formatTimestamp?: Formatter<Date, string>
  /**
   * Component that is responsible for rendering user avatars
   */
  AvatarComponent?: ComponentType<AvatarProps>
}

const CustomMessage: FC<MessageProps> = ({ message }: MessageProps) => {
  const sender = message.sender as Member | User
  const isOutgoing = sender?.id.startsWith('mem_')
  const senderPhoto = sender?.profile_image
    ? expand(sender.profile_image)
    : null

  const date = new Date(message.sent_at)
  const defaultDateFormatter = new Intl.DateTimeFormat('en-US', {
    timeStyle: 'short',
    dateStyle: 'short',
  })

  const bg = isOutgoing ? 'var(--blue-dark-hover)' : 'var(--off-white)'
  const color = isOutgoing ? 'white' : 'var(--gray-900)'

  return (
    <Flex
      direction={isOutgoing ? 'row-reverse' : 'row'}
      align="flex-start"
      mb={4}
    >
      <Box>
        {senderPhoto ? (
          <Avatar size="sm" src={senderPhoto.url} />
        ) : (
          <AllaraLogo width="32" height="32" />
        )}
      </Box>
      <VStack
        align="stretch"
        spacing={1}
        borderRadius="md"
        p={3}
        ml={isOutgoing ? 0 : 2}
        mr={isOutgoing ? 2 : 0}
        flex="1"
        maxWidth="70%"
        bg={bg}
        color={color}
      >
        <Box>
          <Linkify>
            <Text size={'sm'} m={0} whiteSpace="pre-wrap">
              {message.text}
            </Text>
          </Linkify>
        </Box>
        {message.attachments.length > 0 && (
          <Box>
            {message.attachments.map((attachment, index) => (
              <Box key={index} mb={1}>
                <Attachment key={index} attachment={attachment} />
              </Box>
            ))}
          </Box>
        )}
        <HStack justifyContent="space-between" fontSize="sm">
          <Text m={0} fontSize={'xs'}>
            <Name person={message.sender} />
          </Text>
          <Text m={0} fontSize={'xs'}>
            {defaultDateFormatter.format(date)}
          </Text>
        </HStack>
      </VStack>
    </Flex>
  )
}

export interface AttachmentProps {
  attachment: MessageAttachment
}

export const Attachment: FC<AttachmentProps> = ({ attachment }) => {
  return (
    <HStack
      p={2}
      bg="var(--gray-light)"
      borderRadius="lg"
      alignItems="center"
      maxWidth="50%"
      fontSize={'xs'}
      spacing={1}
      color={'var(--blue-dark-hover)'}
    >
      <Icon as={AttachmentIcon} />
      <Link
        size="sm"
        href={attachment.url}
        target="_blank"
        rel="noreferrer"
        download
      >
        {attachment.description ?? attachment.url}
      </Link>
    </HStack>
  )
}

export default CustomMessage
