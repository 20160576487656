import { ChakraPrimaryButton } from 'components/Button'
import useActions from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import JoinModal from 'components/JoinModal'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { PROGRAMS, getProgramName } from 'utils/program.utils'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import { CardFormProps } from '.'
import { useToast } from '@chakra-ui/react'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
)

const JoinNutritionOnlyProgramForm: React.FC<CardFormProps> = ({ action }) => {
  const { setAlertText } = useAlert()
  const { handleCompleteAction } = useActions()
  const { selectedProgram, setSelectedProgram } = useOnboardingContext()
  const toast = useToast()

  const handleCloseModal = useCallback(() => setSelectedProgram(undefined), [])

  const handleOpenModal = useCallback(
    () => setSelectedProgram(PROGRAMS.NUTRITION_ONLY),
    []
  )

  const onSuccess = useCallback(() => {
    handleCompleteAction(action.id)
    toast({
      title: 'Action completed',
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
    setAlertText('We are configuring your dashboard now.', 'Payment Success!')
  }, [])

  const buttonText = `Join ${getProgramName(PROGRAMS.NUTRITION_ONLY)}`

  return (
    <Elements stripe={stripePromise}>
      <JoinModal
        program={selectedProgram}
        handleClose={handleCloseModal}
        onSuccess={onSuccess}
      />
      <ChakraPrimaryButton onClick={handleOpenModal}>
        {buttonText}
      </ChakraPrimaryButton>
    </Elements>
  )
}

export default JoinNutritionOnlyProgramForm
