"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskDefinitionResource = void 0;
const BaseResource_1 = require("../BaseResource");
class TaskDefinitionResource extends BaseResource_1.Resource {
    /**
     * Returns a list of task definitions within the current account.
     *
     * The task definitions returned are sorted by creation date, with the most
     * recently added task definitions appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/task_definitions', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new task definition and registers it with Source. Task defiitions must
     * be created in order to create tasks of that type
     */
    create(params, options) {
        return this.source.request('POST', '/v1/task_definitions', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing task definition. You need only supply the
     * unique task definition identifier that was returned upon creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/task_definitions/${id}`, {
            options,
        });
    }
    /**
     * Updates the specified task definition by setting the values of the parameters
     * passed.
     *
     * Any parameters not provided will be left unchanged. For example, if you pass the
     * name parameter, that becomes the task definitions's active name that is used in
     * the API and interface.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/task_definitions/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
}
exports.TaskDefinitionResource = TaskDefinitionResource;
