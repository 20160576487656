"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelationshipResource = void 0;
const BaseResource_1 = require("../BaseResource");
class RelationshipResource extends BaseResource_1.Resource {
    /**
     * Returns a list of relationships within the current account.
     *
     * The relationships by default are sorted by creation date, with the most recently
     * created relationship appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/relationships', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new relationship between two members. You can create relationships to
     * allow caregivers, family members, or other people to whom the member has
     * consented to participate in the member's care.
     *
     * An active relationship allows members to view information about one another. For
     * example, if Member A is a caregiver for Member B, Member A can view, edit, and
     * create any data for Member B. Member B can view basic demographic information
     * about Member A.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/relationships', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing relationship. You need only supply the
     * unique relationship identifier that was returned upon relationship creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/relationships/${id}`, {
            options,
        });
    }
    /**
     * Updates a relationship between two members.
     *
     * Any parameters not provided are left unchanged. For example, if you pass the
     * status parameter, that becomes the relationship's current status.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/relationships/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
}
exports.RelationshipResource = RelationshipResource;
