"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskResource = void 0;
const BaseResource_1 = require("../BaseResource");
class TaskResource extends BaseResource_1.Resource {
    /**
     * Returns a list of tasks within the current account.
     *
     * The tasks returned are sorted by creation date, with the most recently added
     * task appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/tasks', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new task and registers it with Source. Once a task is created you
     * cannot update the definition or member associated with that task. Note that you
     * cannot create a task using system-managed task definitions (those with keys
     * starting with system).
     */
    create(params, options) {
        return this.source.request('POST', '/v1/tasks', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing task. You need only supply the unique task
     * identifier that was returned upon creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/tasks/${id}`, {
            options,
        });
    }
    /**
     * Updates the specified task by setting the values of the parameters passed.
     *
     * Any parameters not provided will be left unchanged. For example, if you pass the
     * assignee parameter, that assigns the task to the given user. Note that you
     * cannot resolve system-managed tasks (those with `managed` set to `true`).
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/tasks/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
}
exports.TaskResource = TaskResource;
