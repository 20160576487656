import { memo } from 'react'
import { FormCheckProps, Form } from 'react-bootstrap'

interface Props extends FormCheckProps {
  controlId: string
}

const Checkbox: React.FC<Props> = ({ controlId, className, ...props }) => {
  return (
    <Form.Check
      inline
      name={controlId}
      type="checkbox"
      id={`checkbox-${props.value}`}
      className={'xs-12 ' + className}
      {...props}
    />
  )
}

export default memo(Checkbox)
