"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormResource = void 0;
const BaseResource_1 = require("../../BaseResource");
class FormResource extends BaseResource_1.Resource {
    /**
     * Returns a list of forms within the current account. The forms returned are
     * sorted by creation date, with the most recently added form appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/forms', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new form with a single draft form version. After creating a form, you
     * can use the update draft version endpoint to modify the form's contents.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/forms', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing form. You need only supply the unique form
     * identifier that was returned upon creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/forms/${id}`, {
            options,
        });
    }
    /**
     * Updates a form. You can update the name, key, or description of a form. Updates
     * to these fields affect all versions of the form.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/forms/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Archives a specified form. Once archived, it can no longer be used to generate
     * new form responses.  Existing form responses are not affected when a form is
     * archived.
     */
    archive(id, options) {
        return this.source.request('POST', `/v1/forms/${id}/archive`, {
            contentType: 'json',
            options,
        });
    }
    /**
     * Unarchives the specified form. Once unarchived, the form's published version can
     * be again accessed to create form responses and the draft version of the form can
     * be edited.
     */
    unarchive(id, options) {
        return this.source.request('POST', `/v1/forms/${id}/unarchive`, {
            contentType: 'json',
            options,
        });
    }
}
exports.FormResource = FormResource;
