import { WithAccountProps } from 'hoc/withAccount'
import useActions, { Action } from 'hooks/useTimeline/useActions'
import { Fragment, useMemo, useState } from 'react'
import ActionCard from './ActionCard'

const Completed: React.FC<WithAccountProps> = (props) => {
  const { isLoading, completedActions, actionDefinitions } = useActions()
  const [maxNumberShown, setMaxNumberShown] = useState<number>(3)
  const [completedActionsToShow, setCompletedActionsToShow] = useState<
    Action[]
  >([])

  const handleShowMore = () => setMaxNumberShown((n) => n + 3)
  const handleShowLess = () => setMaxNumberShown((n) => n - 3)
  const shouldShowAction = (action: string) =>
    action === 'nutrition-guide' ||
    action === 'complete-init-labs' ||
    action === 'drafting-order' ||
    action === 'share-address' ||
    action === 'complete-intake' ||
    action === 'complete-labs' ||
    action === 'how-long-labs' ||
    action === 'share-insurance'

  const actionsToShow = useMemo(() => {
    const shownActions = completedActions.filter((action) =>
      shouldShowAction(action.definition)
    )
    setCompletedActionsToShow(shownActions)
    return shownActions.slice(0, maxNumberShown)
  }, [completedActions, maxNumberShown])

  if (!isLoading && actionsToShow.length) {
    return (
      <div>
        <p className="h1 py-2 fs-8">Completed</p>
        {actionsToShow.map((action) => {
          const actionDefinition = actionDefinitions.find(
            (a) => a.id === action.definition
          )

          if (actionDefinition) {
            return (
              <ActionCard
                key={action.id}
                action={action}
                completed={true}
                actionDefinition={actionDefinition}
                {...props}
              />
            )
          } else {
            return <Fragment key={action.id}></Fragment>
          }
        })}
        <div className="d-flex justify-content-between">
          <p className="show-more-less-text" onClick={handleShowLess}>
            {maxNumberShown === 3 ? '' : 'Show Less'}
          </p>
          <p className="show-more-less-text" onClick={handleShowMore}>
            {completedActionsToShow.length > maxNumberShown ? 'Show More' : ''}
          </p>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default Completed
