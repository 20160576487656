import { PrimaryButton } from 'components/Button'
import { memo, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ReactComponent as ChevronRight } from 'assets/images/chevron-right.svg'
import { useSchedulingContext } from 'context/SchedulingContext/SchedulingProvider'
import useAppointmentTypeId from 'hooks/useAppointments/useAppointmentTypeId'
import RadioInput from 'components/RadioGroup/RadioInput'
import Loader from 'components/Loader'

type AppointmentInfo = {
  appointmentTypeId: string
  appointmentText: string
}

const AppointmentTypeScreen: React.FC = () => {
  const { nextScreen, setAppointmentTypeId } = useSchedulingContext()
  const [selectedAppointmentTypeId, setSelectedAppointmentTypeId] = useState('')
  const { appointmentTypeId: mdAppointmentTypeId } = useAppointmentTypeId(
    'Followup MD'
  )
  const { appointmentTypeId: rdAppointmentTypeId } = useAppointmentTypeId(
    'Followup RD'
  )
  const isSubmitDisabled = !selectedAppointmentTypeId

  const handleSubmit = () => {
    setAppointmentTypeId(selectedAppointmentTypeId)
    nextScreen()
  }

  const submitButton = (
    <PrimaryButton
      type="submit"
      variant="dark-blue"
      className="fs-2 next-button"
      disabled={isSubmitDisabled}
      style={{ pointerEvents: isSubmitDisabled ? 'none' : 'auto' }}
      onClick={() => handleSubmit()}
    >
      <div>
        Next
        <ChevronRight />
      </div>
    </PrimaryButton>
  )

  if (!mdAppointmentTypeId || !rdAppointmentTypeId) {
    return <Loader />
  }

  const appointmentTypes: AppointmentInfo[] = [
    {
      appointmentTypeId: mdAppointmentTypeId,
      appointmentText: 'Medical Follow Up',
    },
    {
      appointmentTypeId: rdAppointmentTypeId,
      appointmentText: 'Nutrition Follow Up',
    },
  ]
  return (
    <div className="screen-container">
      <div className="d-flex mb-3 gap-2">
        {appointmentTypes.map((appointmentType) => {
          const isSelected =
            selectedAppointmentTypeId === appointmentType.appointmentTypeId
          return (
            <div
              key={appointmentType.appointmentTypeId}
              className={`appointment-type-container d-flex flex-row align-items-center ${
                isSelected ? 'appointment-type-container-selected' : ''
              }`}
              onClick={() =>
                setSelectedAppointmentTypeId(appointmentType.appointmentTypeId)
              }
            >
              <p>{appointmentType.appointmentText}</p>
              <RadioInput
                controlId={appointmentType.appointmentTypeId}
                checked={isSelected}
              />
            </div>
          )
        })}
      </div>
      <div className="submit-button-container">
        {isSubmitDisabled && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="submit-button-tool-tip">
                Please selected an appointment type
              </Tooltip>
            }
          >
            <span>{submitButton}</span>
          </OverlayTrigger>
        )}
        {!isSubmitDisabled && submitButton}
      </div>
    </div>
  )
}

export default memo(AppointmentTypeScreen)
