import { useMemo, useRef } from 'react'
import AppNavigation from './AppNavigation'
import withSubscription from 'hoc/withSubscription'
import { WithAccountProps } from 'hoc/withAccount'
import { PROGRAMS, isNonPatientMember } from 'utils/program.utils'
import NonPatientNavigation from './NonPatientNavigation'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import {
  ChakraProvider,
  Box,
  VStack,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'

const Navigation: React.FC<WithAccountProps> = ({ user }) => {
  const { selectedProgram, paymentDone } = useOnboardingContext()
  const btnRef = useRef<HTMLButtonElement | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const justPaidForMNC = useMemo(
    () => paymentDone && selectedProgram === PROGRAMS.MNC,
    [paymentDone, selectedProgram]
  )

  const NavComponent = useMemo(() => {
    if (isNonPatientMember(user) || justPaidForMNC) {
      return <NonPatientNavigation />
    } else {
      return <AppNavigation />
    }
  }, [user])

  return (
    <ChakraProvider>
      <Box>
        <VStack align="stretch" p="5" spacing="5">
          <IconButton
            ref={btnRef}
            icon={<HamburgerIcon />}
            display={['block', 'block', 'none']} // Only show in mobile views
            onClick={onOpen}
            aria-label="Open Menu"
          />
          <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader borderBottomWidth="1px">
                <IconButton
                  icon={<CloseIcon />}
                  onClick={onClose}
                  aria-label="Close Menu"
                />
              </DrawerHeader>
              <DrawerBody>{NavComponent}</DrawerBody>
            </DrawerContent>
          </Drawer>
          <Box display={['none', 'none', 'block']}>{NavComponent}</Box>{' '}
        </VStack>
      </Box>
    </ChakraProvider>
  )
}

export default withSubscription(Navigation)
