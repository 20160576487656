import useActions, { AddressInfo } from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'
import { Formik, FormikHelpers, Form } from 'formik'
import { CardFormProps } from '.'
import Address from 'components/Address'
import { Grid, useToast } from '@chakra-ui/react'
import { ChakraPrimaryButton } from 'components/Button'
import useUser, { AddressType } from 'hooks/useUser/useUser'

const ShareAddressForm: React.FC<CardFormProps> = ({ action }) => {
  const { handleCompleteAction } = useActions()
  const { shareAddressInfo } = useUser()
  const toast = useToast()

  const values = {
    addresses: [
      {
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        zip: '',
      },
    ],
  }

  const handleSubmit = useCallback(
    (
      addressInfo: { addresses: AddressInfo[] },
      { setSubmitting }: FormikHelpers<{ addresses: AddressInfo[] }>
    ) => {
      const submittedAddressInfo = {
        ...addressInfo.addresses[0],
        type: AddressType.PRIMARY,
      }

      if (shareAddressInfo) {
        shareAddressInfo(submittedAddressInfo)
          .then(() => {
            setSubmitting(false)
            handleCompleteAction(action.id)
            toast({
              title: 'Address saved',
              description: 'Your address has been saved.',
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
          })
          .catch((error) => {
            setSubmitting(false)
            toast({
              title: 'Error sharing address',
              description: error.message,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          })
      }
    },
    [shareAddressInfo, handleCompleteAction, action.id, toast]
  )

  return (
    <Formik initialValues={values} onSubmit={handleSubmit}>
      {() => (
        <Form>
          <Grid
            templateColumns={{ base: '2fr 1fr', md: '2fr 1fr' }}
            alignSelf="stretch"
            gap={6}
          >
            <Address />
          </Grid>
          <ChakraPrimaryButton type="submit" styleProps={{ mt: 4 }}>
            Submit
          </ChakraPrimaryButton>
        </Form>
      )}
    </Formik>
  )
}

export default ShareAddressForm
