import React, { useState } from 'react'
import * as Yup from 'yup'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Heading,
  Link,
  Text,
  Flex,
  chakra,
  ChakraProvider,
  useToast,
} from '@chakra-ui/react'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from 'formik'
import validator from 'validator'
import useUser from 'hooks/useUser/useUser'
import { useStytch } from '@stytch/react'
import { RoutePaths } from 'containers/Core/Routes'
import PasswordInput from 'components/PasswordInput'
import OnboardingContainer from '../OnboardingContainer'
import { StytchAPIError } from '@stytch/vanilla-js'
import { ddLog } from 'utils/logs.utils'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'

interface SignupProps {
  redirectPath?: string
}

interface FormData {
  email: string
  password: string
  phoneNumber: string
}

const PasswordRequirements: React.FC<{ password: string }> = ({ password }) => {
  const requirements = [
    { label: '8 characters', isValid: password.length >= 8 },
    { label: 'Uppercase letter', isValid: /[A-Z]/.test(password) },
    { label: 'Lowercase letter', isValid: /[a-z]/.test(password) },
    {
      label: 'Special character',
      isValid: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    },
    { label: 'Number', isValid: /\d/.test(password) },
  ]

  return (
    <Box mt={2} mb={4}>
      <Flex>
        <Box flex="1">
          {requirements.slice(0, 3).map((req, index) => (
            <Flex key={index} alignItems="center" mb={2}>
              <chakra.span mr={2} color={req.isValid ? 'green.500' : 'red.500'}>
                {req.isValid ? <CheckIcon /> : <CloseIcon />}
              </chakra.span>
              <Text
                color={req.isValid ? 'green.500' : 'red.500'}
                fontSize="sm"
                mb={0}
              >
                {req.label}
              </Text>
            </Flex>
          ))}
        </Box>
        <Box flex="1">
          {requirements.slice(3).map((req, index) => (
            <Flex key={index} alignItems="center" mb={2}>
              <chakra.span mr={2} color={req.isValid ? 'green.500' : 'red.500'}>
                {req.isValid ? <CheckIcon /> : <CloseIcon />}
              </chakra.span>
              <Text
                color={req.isValid ? 'green.500' : 'red.500'}
                fontSize="sm"
                mb={0}
              >
                {req.label}
              </Text>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Box>
  )
}

const Signup: React.FC<SignupProps> = ({ redirectPath }) => {
  const [password, setPassword] = useState('')
  const { createUser, checkUserEligibilityPreSignup } = useUser()
  const stytchClient = useStytch()
  const toast = useToast()
  const params = new URLSearchParams(window.location.search)
  const emailParam = params.get('email')
  const redirectUri =
    window.location.origin + redirectPath + window.location.search ??
    RoutePaths.ONBOARDING
  const responderUuid = params.get('responder_uuid')
  const variantLabel = params.get('variant_label')
  const [isLoading, setIsLoading] = useState(false)
  const { setVariantLabel } = useOnboardingContext()

  const initialValues: FormData = {
    email: emailParam ?? '',
    password: '',
    phoneNumber: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        'Password must contain at least one special character'
      )
      .matches(/\d/, 'Password must contain at least one number')
      .required('Password is required'),
    phoneNumber: Yup.string()
      .test('is-valid-phone', 'Must be a valid phone number', (value) =>
        validator.isMobilePhone(value || '')
      )
      .required('Phone number is required'),
  })

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikHelpers<FormData>['setFieldValue']
  ) => {
    const newPassword = e.target.value
    setPassword(newPassword)
    setFieldValue('password', newPassword)
  }

  const onSubmit = async (values: FormData) => {
    setIsLoading(true)
    const params = {
      email: values.email,
      password: values.password,
      trusted_metadata: {
        phoneNumber: values.phoneNumber,
      },
      session_duration_minutes: 60,
    }

    try {
      const eligibility = await checkUserEligibilityPreSignup(values.email)

      if (eligibility.error) {
        setIsLoading(false)
        toast({
          title: 'Error',
          description: eligibility.error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return
      }

      const stytchUser = await stytchClient.passwords.create(params)
      const response = await createUser({
        email: stytchUser.user.emails[0].email,
        authId: stytchUser.user_id,
        phoneNumber: values.phoneNumber,
        responderUuid: responderUuid ?? '',
        variantLabel: variantLabel ?? '',
      })
      setVariantLabel(variantLabel ?? '')

      if (response) {
        window.location.href = redirectUri
      } else {
        setIsLoading(false)
        toast({
          title: 'Error',
          description: 'Something went wrong. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (error) {
      setIsLoading(false)
      let description = (
        <>
          There was an error creating your account. You may have an account,
          please try logging in{' '}
          <Link href={RoutePaths.LOGIN} textDecoration="underline">
            here
          </Link>
          . If you need support, please reach out to{' '}
          <Link
            href="mailto:concierge@allarahealth.com"
            textDecoration="underline"
          >
            concierge@allarahealth.com
          </Link>
          .
        </>
      )

      if (error instanceof StytchAPIError) {
        if (error.error_type === 'breached_password') {
          description = (
            <>Password is too common. Please try a different password.</>
          )
        }

        ddLog('info', 'Stytch Error::create user error', {
          errorMessage: error.error_message,
          requestId: error.request_id,
        })
      }

      toast({
        title: 'Error',
        description: description,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <OnboardingContainer>
      <ChakraProvider>
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          pt={10}
        >
          <Box bg="white" p={8} borderRadius="lg" boxShadow="lg" width="sm">
            <Heading color="gray.700" as="h4" size="m">
              Ready to select your plan?
            </Heading>
            <Text fontSize="sm" mb={6}>
              Based on your answers and location, we&apos;ll recommend the best
              plan for you.
              <br />
              Please create an account below to save your progress
            </Text>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isValid, dirty, setFieldValue }) => (
                <Form>
                  <FormControl mb={4} isRequired>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Field as={Input} id="email" name="email" type="email" />
                    <ErrorMessage name="email" component={FormErrorMessage} />
                  </FormControl>

                  <FormControl mb={4} isRequired>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <PasswordInput
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handlePasswordChange(e, setFieldValue)
                      }
                    />
                    <PasswordRequirements password={password} />
                  </FormControl>

                  <FormControl mb={4} isRequired>
                    <FormLabel htmlFor="phoneNumber">Phone number</FormLabel>
                    <Field
                      as={Input}
                      id="phoneNumber"
                      name="phoneNumber"
                      type="tel"
                    />
                    <ErrorMessage name="phoneNumber">
                      {(msg) => <div>{msg}</div>}
                    </ErrorMessage>
                  </FormControl>

                  <Button
                    type="submit"
                    backgroundColor={'var(--blue-light)'}
                    color="white"
                    width="full"
                    mt={4}
                    _hover={{
                      backgroundColor: 'var(--blue-dark-hover)',
                    }}
                    isDisabled={!(isValid && dirty)}
                    isLoading={isLoading}
                    loadingText="Submitting"
                  >
                    Continue
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Flex>
      </ChakraProvider>
    </OnboardingContainer>
  )
}

export default Signup
