"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Source = void 0;
const SourceClient_1 = require("./SourceClient");
const SourceConfiguration_1 = require("./SourceConfiguration");
const adapter_1 = require("./adapter");
const resources_1 = require("./resources");
const token_1 = require("./token");
class Source {
    constructor(baseUrl, authentication, options = {}) {
        var _a;
        this.baseUrl = baseUrl;
        const adapter = (_a = options.adapter) !== null && _a !== void 0 ? _a : (0, adapter_1.createClientForEnvironment)();
        this.configuration = new SourceConfiguration_1.SourceConfiguration(Object.assign({ authentication: authentication, baseUrl }, options));
        this.tokens = (0, token_1.createTokenGenerator)(this.configuration);
        this.client = new SourceClient_1.SourceClient(adapter, this.configuration);
        // Bootstrap all resources and attach them to the client
        Object.assign(this, (0, resources_1.allResources)(this.client));
    }
    /**
     * Executes a request using the underlying client
     *
     * @param method the method to hit on the API
     * @param path the path to hit on the API
     * @param options options/request inputs
     * @returns the API response
     */
    request(method, path, options) {
        return this.client.request(method, path, options);
    }
}
exports.Source = Source;
