import { useEffect } from 'react'

type OutsideclickProps = {
  containerRef: React.MutableRefObject<HTMLInputElement | null>
  callback: () => void
}

const useOutsideOnClick = ({
  containerRef,
  callback,
}: OutsideclickProps): void => {
  const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
    if (
      !containerRef.current ||
      containerRef.current.contains(event.target as Node)
    ) {
      return
    }

    callback()
  }

  useEffect(() => {
    if (containerRef.current) {
      document.addEventListener('mousedown', handleOutsideClick)
      document.addEventListener('touchstart', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('touchstart', handleOutsideClick)
    }
  }, [containerRef, callback])
}

export default useOutsideOnClick
