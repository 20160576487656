import { useEffect, useState } from 'react'
import axios from 'axios'
import { Content as ContentRoute } from 'api/library/routes'
import { RoutePaths } from 'containers/Core/Routes'
import { Redirect } from 'react-router-dom'
import useSearchParams from 'hooks/useSearchParams'
import withSubscription from 'hoc/withSubscription'
import Loader from 'components/Loader'

import './styles.scss'
import { useStytch } from '@stytch/react'

const Content: React.FC = () => {
  const searchParams = useSearchParams()
  const stytch = useStytch()

  const [contentUrl, setContentUrl] = useState<string | null>(null)

  const path = searchParams.get('id')
  const type = searchParams.get('type')

  useEffect(() => {
    if (!type || !path) {
      return
    }

    const fetchContent = async () => {
      const tokens = stytch.session.getTokens()
      const accessToken = tokens ? tokens.session_jwt : undefined
      const url = `${process.env.REACT_APP_SERVER_URL}${ContentRoute(path)}`
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: 'blob',
        })
        .then((res) => {
          const file = new File([res.data], 'test.pdf', {
            type: 'application/pdf',
          })
          setContentUrl(window.URL.createObjectURL(file))
        })
    }

    window.scrollTo(0, 0)

    switch (type) {
      case 'worksheet':
        fetchContent()
        break
      default:
        break
    }
  }, [type, path])

  const backgroundColor = '#fff'

  if (!path || !type) {
    return <Redirect to={RoutePaths.DASHBOARD_CONTENT_LIBRARY} />
  } else if (!contentUrl) {
    return <Loader />
  } else {
    return (
      <div
        className="container-fluid d-flex align-items-center w-100 h-100 px-0"
        style={{ backgroundColor }}
      >
        <iframe src={contentUrl} className="w-100 h-100-vh" />
      </div>
    )
  }
}

export default withSubscription(Content)
