import {
  Heading,
  Box,
  Divider,
  Text,
  useBreakpointValue,
  Grid,
  GridItem,
  Badge,
} from '@chakra-ui/react'
import useStripePortal from 'hooks/useStripePortal'
import {
  formatNumberToDate,
  formatNumberToCurrency,
} from 'containers/Core/utils'

const InvoicesList = () => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { invoicesData } = useStripePortal()

  const formattedInvoiceData = invoicesData?.map((invoice) => ({
    date: formatNumberToDate(invoice?.date),
    amount: formatNumberToCurrency(invoice?.amountDue),
    status: invoice.status,
  }))

  const formatDate = (date: Date) => {
    if (isMobile) {
      return date.toLocaleDateString()
    } else {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
      return date.toLocaleDateString(undefined, options)
    }
  }

  return (
    <Box m={10}>
      <Heading as="h5" size="s" fontWeight={'semibold'}>
        Invoice history
      </Heading>
      <Divider my={2} />
      <Box>
        {formattedInvoiceData?.map((invoice, index) => (
          <Grid templateColumns="2fr 1fr 1fr" gap={6} key={index}>
            <GridItem>
              <Text>{formatDate(invoice.date)}</Text>
            </GridItem>
            <GridItem>
              <Text>{invoice.amount}</Text>
            </GridItem>
            <GridItem>
              {invoice.status === 'paid' ? (
                <Badge colorScheme={'green'}>Paid</Badge>
              ) : (
                <Badge colorScheme={'red'}>Failed</Badge>
              )}
            </GridItem>
          </Grid>
        ))}
      </Box>
    </Box>
  )
}

export default InvoicesList
