import logo from 'assets/images/logo-blue.svg'
import { ReactNode } from 'react'
import { Image } from 'react-bootstrap'

import './styles.scss'
interface OnboardingContainerProps {
  children: ReactNode
}

const OnboardingContainer: React.FC<OnboardingContainerProps> = ({
  children,
}) => {
  return (
    <div className="onboarding">
      <div className="logo">
        <Image src={logo} />
      </div>
      {children}
    </div>
  )
}

export default OnboardingContainer
