import { PillButton, SecondaryPillButton } from 'components/Button'
import OnboardingContainer from '../OnboardingContainer'
import { ReactComponent as RightIcon } from 'assets/images/arrow-right.svg'
import { ReactComponent as LeftIcon } from 'assets/images/chevron-left.svg'
import { useCallback, useEffect, useMemo } from 'react'
import usePaymentPlans from 'hooks/usePayment/usePaymentPlans'
import Loader from 'components/Loader'
import { Redirect, useHistory } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import {
  PaymentPlan,
  Program,
  useOnboardingContext,
} from 'context/OnboardingContext/OnboardingProvider'
import withEligibility, { WithEligibilityProps } from 'hoc/withEligibility'
import UseOnboardingPageView from 'hooks/useOnboardingPageView'
import { Amplitude } from '../../../utils/amplitude.utils'
import { PROGRAMS } from 'utils/program.utils'

import './styles.scss'

interface PaymentPlansProps extends WithEligibilityProps {
  program: Program
  isDirectFlow?: boolean
}

interface PaymentPlansCardPriceProps {
  program: Program
  plans: PaymentPlan[]
  unitAmount?: number
  recurringInterval?: string
  isVip?: boolean
}

const NormalPrice: React.FC<PaymentPlansCardPriceProps> = ({
  program,
  unitAmount,
  recurringInterval,
}) => {
  const { selectedPlan } = useOnboardingContext()

  return (
    <>
      {program === PROGRAMS.INSURANCE && selectedPlan ? (
        <span className="ff-inner fs-13 font-weight-bold">
          {`\$${
            (selectedPlan.unit_amount / 100) *
            selectedPlan.recurring.interval_count
          }`}
          <span className="ff-inter fs-10 font-weight-normal color-gray-500">
            {selectedPlan.recurring.interval === 'year' ? '/year' : '/month'}
          </span>
        </span>
      ) : (
        <div>
          <span className="ff-inner fs-15 font-weight-bold">
            {unitAmount && `\$${unitAmount}`}
          </span>
          {recurringInterval && (
            <span>/ {recurringInterval === 'month' ? 'month' : ''}</span>
          )}
        </div>
      )}
    </>
  )
}

const PaymentPlans: React.FC<PaymentPlansProps> = ({
  program,
  isDirectFlow,
  waitlistUser,
}) => {
  const history = useHistory()
  const { trackPageView } = UseOnboardingPageView()
  const {
    selectedPlan,
    selectedProgram,
    setSelectedPlan,
    variantLabel,
  } = useOnboardingContext()
  const { plans, isLoading: isPlansLoading } = usePaymentPlans(program)

  const handleCheckout = useCallback(() => {
    history.push(
      `${isDirectFlow ? RoutePaths.DIRECT_CHECKOUT : RoutePaths.PAYMENT}${
        window.location.search
      }`
    )
    Amplitude.initiatesCheckout(
      'checkout',
      selectedPlan?.metadata.commitment,
      variantLabel
    )
  }, [window.location.search])

  const handleNeedMoreInfo = useCallback(() => {
    Amplitude.initiatesCheckout('consult', undefined, variantLabel)
    history.push(`${RoutePaths.BOOK_CONSULT}${window.location.search}`)
  }, [window.location.search])

  const handleReturnToPrograms = useCallback(() => {
    history.push(`${RoutePaths.PROGRAMS}${window.location.search}`)
  }, [window.location.search])

  // Track page view
  useEffect(() => {
    Amplitude.userViewsPaymentPlans(variantLabel)
    trackPageView('paymentPlans')
  }, [])

  useEffect(() => {
    if (plans?.length) {
      const initialPlan = plans[0]
      setSelectedPlan(initialPlan)
    }
  }, [plans])

  const unitAmount = useMemo(() => {
    if (!selectedPlan) {
      return undefined
    } else if (selectedPlan.recurring?.interval === 'year') {
      return selectedPlan.unit_amount / 12 / 100
    } else {
      return selectedPlan.unit_amount / 100
    }
  }, [selectedPlan])

  const recurringInterval = useMemo(() => {
    if (!selectedPlan || !selectedPlan.recurring) {
      return undefined
    } else {
      return 'month'
    }
  }, [selectedPlan])

  // Commenting this out because Molly asked to take it out
  // const promotionText = useMemo(() => {
  //   if (!selectedPlan) {
  //     return undefined
  //   } else if (program === PROGRAMS.INSURANCE) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $180/year
  //       </>
  //     )
  //   } else if (program === PROGRAMS.INSURANCE_DIAGNOSTIC) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $15
  //       </>
  //     )
  //   }

  // Commenting this out because Molly asked to take it out
  // const promotionText = useMemo(() => {
  //   if (!selectedPlan) {
  //     return undefined
  //   } else if (program === PROGRAMS.INSURANCE) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $180/year
  //       </>
  //     )
  //   } else if (program === PROGRAMS.INSURANCE_DIAGNOSTIC) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $15
  //       </>
  //     )
  //   }

  // Commenting this out because Molly asked to take it out
  // const promotionText = useMemo(() => {
  //   if (!selectedPlan) {
  //     return undefined
  //   } else if (program === PROGRAMS.INSURANCE) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $180/year
  //       </>
  //     )
  //   } else if (program === PROGRAMS.INSURANCE_DIAGNOSTIC) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $15
  //       </>
  //     )
  //   }

  // Commenting this out because Molly asked to take it out
  // const promotionText = useMemo(() => {
  //   if (!selectedPlan) {
  //     return undefined
  //   } else if (program === PROGRAMS.INSURANCE) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $180/year
  //       </>
  //     )
  //   } else if (program === PROGRAMS.INSURANCE_DIAGNOSTIC) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $15
  //       </>
  //     )
  //   }

  // Commenting this out because Molly asked to take it out
  // const promotionText = useMemo(() => {
  //   if (!selectedPlan) {
  //     return undefined
  //   } else if (program === PROGRAMS.INSURANCE) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $180/year
  //       </>
  //     )
  //   } else if (program === PROGRAMS.INSURANCE_DIAGNOSTIC) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $15
  //       </>
  //     )
  //   }

  // Commenting this out because Molly asked to take it out
  // const promotionText = useMemo(() => {
  //   if (!selectedPlan) {
  //     return undefined
  //   } else if (program === PROGRAMS.INSURANCE) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $180/year
  //       </>
  //     )
  //   } else if (program === PROGRAMS.INSURANCE_DIAGNOSTIC) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $15
  //       </>
  //     )
  //   }

  // Commenting this out because Molly asked to take it out
  // const promotionText = useMemo(() => {
  //   if (!selectedPlan) {
  //     return undefined
  //   } else if (program === PROGRAMS.INSURANCE) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $180/year
  //       </>
  //     )
  //   } else if (program === PROGRAMS.INSURANCE_DIAGNOSTIC) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $15
  //       </>
  //     )
  //   }

  // Commenting this out because Molly asked to take it out
  // const promotionText = useMemo(() => {
  //   if (!selectedPlan) {
  //     return undefined
  //   } else if (program === PROGRAMS.INSURANCE) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $180/year
  //       </>
  //     )
  //   } else if (program === PROGRAMS.INSURANCE_DIAGNOSTIC) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $15
  //       </>
  //     )
  //   }

  // Commenting this out because Molly asked to take it out
  // const promotionText = useMemo(() => {
  //   if (!selectedPlan) {
  //     return undefined
  //   } else if (program === PROGRAMS.INSURANCE) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $180/year
  //       </>
  //     )
  //   } else if (program === PROGRAMS.INSURANCE_DIAGNOSTIC) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $15
  //       </>
  //     )
  //   }

  // Commenting this out because Molly asked to take it out
  // const promotionText = useMemo(() => {
  //   if (!selectedPlan) {
  //     return undefined
  //   } else if (program === PROGRAMS.INSURANCE) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $180/year
  //       </>
  //     )
  //   } else if (program === PROGRAMS.INSURANCE_DIAGNOSTIC) {
  //     return (
  //       <>
  //         Limited time promotion,
  //         <br />
  //         normally $15
  //       </>
  //     )
  //   }

  //   return undefined
  // }, [program, selectedPlan])

  if (isPlansLoading || !plans.length) {
    return <Loader />
  }

  if (!selectedProgram && !waitlistUser?.selectedProduct) {
    return <Redirect to={`${RoutePaths.PROGRAMS}${window.location.search}`} />
  }

  return (
    <OnboardingContainer>
      <div className="d-flex flex-column align-items-center mx-4">
        <div className="payment-plans mt-5">
          {!isDirectFlow && (
            <div
              className="ff-ovo d-flex position-absolute align-items-center back-button"
              onClick={handleReturnToPrograms}
            >
              <LeftIcon />
              Back
            </div>
          )}
          <div className="insurance-payment-plan d-flex justify-content-center flex-column mt-5">
            <span className="ff-inter-medium header mt-4 mb-3 align-self-center fs-6">
              {plans.length > 1
                ? 'Select your plan'
                : 'Continue with your plan'}
            </span>
            {plans.length > 1 && selectedPlan !== null && (
              <div className="tab d-flex mb-6">
                {plans.map((plan) => (
                  <div
                    onClick={() => setSelectedPlan(plan)}
                    className={`tab-item d-flex justify-content-center align-items-center ${
                      selectedPlan?.id === plan.id ? 'tab-item-selected' : ''
                    }`}
                    key={plan.id}
                  >
                    <span>{plan.metadata.benefits}</span>
                    {plan.metadata.savings && (
                      <span className="promo ml-1">
                        {plan.metadata.savings.toUpperCase()}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            )}
            <div className="align-self-center align-items-center d-flex flex-column">
              <div>
                <NormalPrice
                  program={program}
                  plans={plans}
                  unitAmount={unitAmount}
                  recurringInterval={recurringInterval}
                />
              </div>
              {/* {promotionText && (
                <p className="color-gray-500 mt-2 mb-4 text-center">
                  {promotionText}
                </p>
              )} */}
            </div>
            {(program === PROGRAMS.INSURANCE ||
              program === PROGRAMS.INSURANCE_DIAGNOSTIC) && (
              <div className="mx-4">
                <p className="fs-1 text-center fw-700 ff-inter-bold text-color-gray-500 my-0">
                  Your Allara membership does not include the cost of care.
                </p>
                <p className="fs-1 text-center ff-inter text-color-gray-500 my-0">
                  *The cost of visits is billed separately and the amount
                  depends on your coverage and copay
                </p>
              </div>
            )}
            <div className="mx-5">
              <div className="d-flex justify-content-center my-3 w-100">
                <PillButton
                  className="continue-button justify-content-between w-100"
                  onClick={handleCheckout}
                >
                  <div>Continue to Checkout</div>
                  <div className="continue-button-arrow">
                    <RightIcon />
                  </div>
                </PillButton>
              </div>
              {!isDirectFlow && (
                <div className="d-flex justify-content-center mb-3 pb-2">
                  <SecondaryPillButton
                    className="continue-button more-info-button justify-content-between w-100"
                    onClick={handleNeedMoreInfo}
                  >
                    <div>Book a free consultation</div>
                    <div className="continue-button-arrow more-info-button-arrow">
                      <RightIcon />
                    </div>
                  </SecondaryPillButton>
                </div>
              )}
              {program === PROGRAMS.INSURANCE && (
                <p className="align-self-center fs-2">
                  Subscription automatically renews until cancelled.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </OnboardingContainer>
  )
}

export default withEligibility(PaymentPlans)
