"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventResource = void 0;
const BaseResource_1 = require("../BaseResource");
class EventResource extends BaseResource_1.Resource {
    /**
     * Each event data is rendered according to Source API version at its creation
     * time,       specified in event object api_version attribute (not according to
     * your current Source       API version or Source-Version header).
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/events/${id}`, {
            options,
        });
    }
    /**
     * List all stored events. Each event data is rendered according to Source API
     * version at its creation time, specified in event object api_version attribute
     * (not according       to your current Source API version or Source-Version
     * header).
     */
    list(params, options) {
        return this.source.request('GET', '/v1/events', {
            query: params,
            options,
        });
    }
}
exports.EventResource = EventResource;
