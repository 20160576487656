"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserResource = void 0;
const BaseResource_1 = require("../BaseResource");
class UserResource extends BaseResource_1.Resource {
    /**
     * Returns a list of users within the current account.
     *
     * The users returned are sorted by creation date, with the most recently added
     * users appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/users', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new user on the Source platform.
     *
     * The user will be sent an email inviting them to finish creating their account.
     * User accounts will not be active until they have followed the link in the
     * invitation email and set a password.
     *
     * Administrators and owners can create users.
     *
     * API keys can create users but cannot create with role 'developer',
     * 'administrator', or 'owner' or assign users to groups.
     *
     * Other user roles cannot create users.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/users', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieve a user by their unique identifier.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/users/${id}`, {
            options,
        });
    }
    /**
     * Updates the specified user by setting the values of the parameters passed. Any
     * parameters not provided will be left unchanged. For example, if you only provide
     * the first_name parameter, that only updates the user's first_name.
     *
     * All users can update their own name.
     *
     * API keys can update the name of users.
     *
     * Administrators and owners may update the name, email and role of other users.
     *
     * Administrators and owners may assign users to groups.
     *
     * Administrators and owners are not permitted to change their own role.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/users/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
}
exports.UserResource = UserResource;
