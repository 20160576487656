export const ErrorMessages = {
  INVALID_EMAIL: 'Must be a valid email',
  REQUIRED_FIELD: 'This field is required!',
  OPTION_REQUIRED: 'Select at least 1 option',
}

export const Links = {
  ContactEmail: `concierge@allarahealth.com`,
}
export const RelationshipToInsured = [
  { label: 'Self', value: 'Self' },
  { label: 'Child', value: 'Child' },
  { label: 'Spouse', value: 'Spouse' },
  { label: 'Other', value: 'Other' },
]
export const ALLOWED_TYPES = ['image/jpg', 'image/jpeg', 'image/png']
