import { InputContainerProps } from 'hoc/InputContainer'
import { memo } from 'react'
import { FormControlProps } from 'react-bootstrap'
import Select from '.'
import { STATES } from 'containers/Core/utils'

interface StateSelectProps extends FormControlProps, InputContainerProps {
  containerClassName?: string
}

const StateSelect: React.FC<StateSelectProps> = (props) => {
  return <Select {...props} options={STATES} />
}

export default memo(StateSelect)
