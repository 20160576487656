import React, { ReactNode } from 'react'
import { ddLog } from '../../utils/logs.utils'

interface IErrorBoundaryState {
  hasError: boolean
  errorMessage?: string
}

export default class ErrorBoundary extends React.Component<
  { children: ReactNode },
  IErrorBoundaryState
> {
  constructor(props: { children: ReactNode }) {
    super(props)
    this.state = { hasError: false, errorMessage: undefined }
  }

  static getDerivedStateFromError(): IErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error): void {
    // eslint-disable-next-line no-console
    console.error(error.message)
    ddLog('error', 'Error Boundary', {
      name: error.name,
      stack: error.stack,
      message: error.message,
    })
    // eslint-disable-next-line no-console
    console.error(error.stack)
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div className="d-flex flex-column align-items-center bg-light text-primary justify-content-center vh-100">
          <h4 className="mt-4 text-center">
            Something went wrong. Please reload the page and try again.
            <br />
            If the problem persists please contact Concierge or email{' '}
            <a href="mailto:hello@allarahealth.com">hello@allarahealth.com</a>.
          </h4>
        </div>
      )
    }

    return this.props.children
  }
}
