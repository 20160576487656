import React from 'react'
import { InputGroup, Input, InputRightElement, Button } from '@chakra-ui/react'

interface PasswordInputProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const PasswordInput = ({ onChange }: PasswordInputProps) => {
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)
  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type={show ? 'text' : 'password'}
        onChange={onChange}
        id="password"
        name="password"
      />
      <InputRightElement width="4.5rem">
        <Button h="1.75rem" size="sm" onClick={handleClick}>
          {show ? 'Hide' : 'Show'}
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}

export default PasswordInput
