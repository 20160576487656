import { InputContainerProps } from 'hoc/InputContainer'
import useEligibleInsurances from 'hooks/useEligibleInsurance'
import { memo, useMemo } from 'react'
import { FormControlProps } from 'react-bootstrap'
import Select from '.'

interface InsuranceProviderSelectProps
  extends FormControlProps,
    InputContainerProps {
  containerClassName?: string
}

const InsuranceProviderSelect: React.FC<InsuranceProviderSelectProps> = (
  props
) => {
  const { insurances } = useEligibleInsurances()
  const options = useMemo(() => {
    const providers = insurances.map((prov) => ({
      label: prov.title,
      value: prov.provider,
    })) //converts providers to options format
    providers.unshift({ value: '', label: 'Select' }) //add empty option to options
    providers.push({ value: 'other', label: 'Other' }) //add other option to end of options
    return providers
  }, [insurances])

  return <Select {...props} options={options} />
}

export default memo(InsuranceProviderSelect)
