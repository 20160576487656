import AddressAutocomplete from 'components/AddressAutocomplete'
import { PillButton } from 'components/Button'
import Loader from 'components/Loader'
import TextField from 'components/TextField'
import { RoutePaths } from 'containers/Core/Routes'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import { Formik, FormikHelpers } from 'formik'
import useSearchParams from 'hooks/useSearchParams'
import useEligibilityInfo from 'hooks/useUser/useEligibilityInfo'
import { memo, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { getPostalCode } from '../Payment/utils'
import { ReactComponent as RightIcon } from 'assets/images/chevron-right.svg'
import Select from 'components/Select'
import { Links } from './constants'
import { checkEligibilityFormSchema } from './utils'

import './styles.scss'
import AppLogo from 'components/AppLogo'

const rowProps = {
  className: 'py-1',
}
export interface CheckEligibilityForm {
  firstName: string
  lastName: string
  email: string
  dob: string
  gender: Gender | string
  zipCode: string
  termsOfUse: boolean
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  INTERSEX = 'INTERSEX',
}

const initialValues: CheckEligibilityForm = {
  firstName: '',
  lastName: '',
  zipCode: '',
  dob: '',
  email: '',
  gender: '',
  termsOfUse: false,
}

const DirectCheckEligibility: React.FC = () => {
  const history = useHistory()
  const { setAlertText } = useAlert()
  const searchParams = useSearchParams()
  const { setSelectedProgram } = useOnboardingContext()
  const { handleUpdateDirectCheckEligibility } = useEligibilityInfo()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const vip = searchParams.get('vip')

  const onSubmitHandler = (
    { firstName, lastName, email, dob, gender, zipCode }: CheckEligibilityForm,
    {}: FormikHelpers<CheckEligibilityForm>
  ) => {
    setIsLoading(true)
    handleUpdateDirectCheckEligibility({
      firstName,
      lastName,
      email,
      dob: new Date(dob),
      gender: gender,
      zipCode,
      isDirectSignUp: true,
    })
      .then((res) => {
        if (res?.data) {
          const vipQuery = vip ? `&vip=${vip}` : ''
          const route = RoutePaths.DIRECT_SIGNUP
          history.push(`${route}?email=${email}${vipQuery}`)
        } else {
          setAlertText(
            `Please try again. Error: ${res?.statusText ?? 'Unknown'}`,
            'Unable to process eligibility'
          )
        }
      })
      .catch((err) => {
        console.error(err)
        setAlertText('Unable to process eligibility', 'An Error occured')
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <div className="direct-eligibility-container">
      <Formik
        initialValues={initialValues}
        validationSchema={checkEligibilityFormSchema}
        onSubmit={onSubmitHandler}
      >
        {({
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          setValues,
        }) => {
          const {
            firstName,
            lastName,
            dob,
            email,
            gender,
            zipCode,
            termsOfUse,
          } = values

          const onZipCodeSelect = ({ address_components }: Result) => {
            const zipCode = getPostalCode(address_components)

            setValues({ ...values, zipCode })
          }

          return (
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div className="mt-5">
                <AppLogo width="135px" />
              </div>
              <form
                onSubmit={handleSubmit}
                className="checkout-form d-flex justify-content-center"
              >
                <div className="checkout-form-sub checkout-form-sub-1">
                  <h2 className="mt-5 fs-7 header">Confirm Eligibility</h2>
                  <Row {...rowProps}>
                    <Col>
                      <TextField
                        containerClassName="text-capitalize mb-2"
                        label="First Name"
                        controlId="firstName"
                        error={
                          errors.firstName && touched.firstName
                            ? errors.firstName
                            : ''
                        }
                        value={firstName}
                        onChange={handleChange}
                      />
                      <p className="font-italic fs-3 mt-2 mb-2">
                        Note: please use your full, legal first name. Later,
                        you&apos;ll have an apportunity to share your nickname
                        or preferred name with your providers
                      </p>
                    </Col>
                  </Row>
                  <Row {...rowProps}>
                    <Col>
                      <TextField
                        label="Last Name"
                        controlId="lastName"
                        error={
                          errors.lastName && touched.lastName
                            ? errors.lastName
                            : ''
                        }
                        value={lastName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <Row {...rowProps}>
                    <Col>
                      <TextField
                        containerClassName="text-capitalize"
                        label="Email"
                        controlId="email"
                        error={
                          errors.email && touched.email ? errors.email : ''
                        }
                        value={email}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row {...rowProps}>
                    <Col>
                      <TextField
                        type="date"
                        label="Date of Birth*"
                        controlId="dob"
                        value={dob}
                        error={errors.dob && touched.dob ? errors.dob : ''}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row {...rowProps} className="d-flex align-items-end">
                    <Col>
                      <Select
                        options={[
                          {
                            label: 'Select sex',
                            value: '',
                          },
                          {
                            label: 'Female',
                            value: 'FEMALE',
                          },
                          {
                            label: 'Male',
                            value: 'MALE',
                          },
                          {
                            label: 'Intersex',
                            value: 'INTERSEX',
                          },
                        ]}
                        label="Sex at Birth*"
                        controlId="gender"
                        value={gender}
                        error={
                          errors.gender && touched.gender ? errors.gender : ''
                        }
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row {...rowProps}>
                    <Col>
                      <AddressAutocomplete
                        label="Zip Code"
                        placeholder="Zip Code"
                        controlId="zipCode"
                        type="string"
                        error={
                          errors.zipCode && touched.zipCode
                            ? errors.zipCode
                            : ''
                        }
                        value={zipCode}
                        onChange={handleChange}
                        onSelect={onZipCodeSelect}
                        noArrow
                      />
                    </Col>
                  </Row>
                  <Form.Group className="d-flex mt-5" controlId="termsOfUse">
                    <Form.Check checked={termsOfUse} onChange={handleChange} />
                    <Form.Label className="fs-3 mx-3">
                      By checking this box, you agree to recieve emails from us
                      and to our{' '}
                      <a
                        href={Links.TermsOfUse}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of Use
                      </a>{' '}
                      and{' '}
                      <a
                        href={Links.PrivacyPolicy}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </Form.Label>
                  </Form.Group>

                  <Row {...rowProps} className="py-3">
                    <Col className="d-flex justify-content-center">
                      <PillButton
                        className="checkout-button position-relative justify-content-between w-100"
                        type="submit"
                        block
                        disabled={
                          isLoading ||
                          !(
                            firstName &&
                            lastName &&
                            zipCode &&
                            email &&
                            termsOfUse &&
                            dob &&
                            gender
                          )
                        }
                      >
                        {isLoading ? (
                          <Loader />
                        ) : (
                          <>
                            <div>Submit</div>
                            <div className="continue-button">
                              <RightIcon />
                            </div>
                          </>
                        )}
                      </PillButton>
                    </Col>
                  </Row>
                </div>
              </form>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

export default memo(DirectCheckEligibility)
