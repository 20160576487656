import { useStytch, useStytchSession } from '@stytch/react'
import {
  AppointmentDefinitions as AppointmentDefinitionsRoute,
  QueryKey as AppointmentsQueryKey,
} from 'api/appointments/routes'
import { useQuery } from 'react-query'

export type AppointmentDefinition = {
  id: string
  title: string
  description: string
  icon: string // URL
  buttonText?: string
}

interface UseAppointmentDefinitionsInterface {
  isLoading: boolean
  appointmentDefinitions?: AppointmentDefinition[]
}

const useAppointmentDefinitions = (): UseAppointmentDefinitionsInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchAppointmentDefinitions = async (): Promise<
    AppointmentDefinition[]
  > => {
    if (!session) {
      return []
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${AppointmentDefinitionsRoute}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          return []
        }
      })
      .catch(() => {
        return []
      })
  }

  const { isLoading, data: appointmentDefinitions } = useQuery(
    [AppointmentsQueryKey, 'definitions'],
    fetchAppointmentDefinitions,
    {
      staleTime: Infinity,
    }
  )

  return { isLoading, appointmentDefinitions }
}

export default useAppointmentDefinitions
