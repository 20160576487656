import {
  Member,
  Source,
  JWTAuthentication,
} from '@allara-health/source-health-client'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'

import { createContext, useContext } from 'react'

export interface SourceContextValue {
  /**
   * @private
   *
   * Token used for making API calls.
   *
   * Applications should not access this value directly. It will be provided to API calls
   * transparently by the application
   */
  readonly client: Source

  readonly member: Member | null
}

export const SourceContext = createContext<SourceContextValue | null>(null)

export function useSourceContext(): SourceContextValue {
  const context = useContext(SourceContext)

  if (!context) {
    throw new Error(
      'Could not find SourceElements context; You need to wrap your application in a <SourceElements> provider.'
    )
  }

  return context
}

export function useSourceClient(): Source {
  const { client } = useSourceContext()
  return client
}

export function useMember(): Member | null {
  const { member } = useSourceContext()
  return member
}

export interface DeprecatedSourceElementsProps {
  /**
   * @deprecated provide a token instead
   *
   * Provide an initialized Source client that elements can use
   */
  client: Source

  /**
   * Override the API base URL to pass to the client
   */
  baseUrl?: string

  children: React.ReactNode
}

export interface SourceElementsProps {
  /**
   * Provide a token that elements can use to create an API client
   */
  token: JWTAuthentication

  /**
   * Override the API base URL to pass to the API client
   */
  baseUrl?: string
  children: React.ReactNode
}

export const SourceElements: FunctionComponent<
  DeprecatedSourceElementsProps | SourceElementsProps
> = ({ children, baseUrl, ...rest }) => {
  const client = useMemo(() => {
    if ('client' in rest) {
      return rest.client
    } else {
      return new Source('', rest.token, { baseUrl })
    }
  }, [
    (rest as { client: Source }).client ||
      (rest as { token: JWTAuthentication }).token,
    baseUrl,
  ])

  const [member, setMember] = useState<Member | null>(null)
  useEffect(() => {
    client.members
      .retrieve('current', { expand: ['profile_image'] })
      .then((member) => {
        setMember(member)
      })
  }, [client])

  const value = useMemo<SourceContextValue>(
    () => ({
      client,
      member,
    }),
    [client, member]
  )

  return (
    <SourceContext.Provider value={value}>{children}</SourceContext.Provider>
  )
}
