import Loader from 'components/Loader'
import { memo, MouseEventHandler, useEffect, useState } from 'react'
import { ButtonProps } from 'react-bootstrap'

import './styles.scss'

export interface PillButtonProps extends ButtonProps {
  type?: 'button' | 'submit' | 'reset' | undefined
  isLoading?: boolean
  loaderSize?: 'sm' | 'md'
}

const PillButton: React.FC<PillButtonProps> = ({
  children,
  className,
  disabled,
  isLoading,
  loaderSize,
  type,
  size = 'lg',
  onClick,
}) => {
  const [clicked, setClicked] = useState<boolean>(false)

  useEffect(() => {
    if (!isLoading) {
      setClicked(false)
    }
  }, [isLoading])

  const onClickHandler: MouseEventHandler<HTMLElement> = (e) => {
    setClicked(true)

    if (onClick) {
      onClick(e)
    }
  }

  return (
    <button
      className={`pill-button pill-button-${size} d-flex align-items-center ${className}`}
      onClick={onClickHandler}
      type={type}
      disabled={disabled || (clicked && isLoading)}
    >
      {clicked && isLoading ? (
        <div className="position-relative">
          <Loader size={loaderSize} />
        </div>
      ) : (
        children
      )}
    </button>
  )
}

export default memo(PillButton)
