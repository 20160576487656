"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationResource = void 0;
const BaseResource_1 = require("../BaseResource");
class LocationResource extends BaseResource_1.Resource {
    /**
     * Retrieves the details of an existing location. You need only supply the unique
     * location identifier that was returned upon creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/locations/${id}`, {
            options,
        });
    }
    /**
     * Updates an existing location by its unique identifier.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/locations/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Deletes a location by its unique identifier.
     *
     * Once a location is deleted, it can no longer be used for searching availability
     * or booking appointments. Existing appointments which already exist at the
     * location will remain unaffected.
     */
    delete(id, options) {
        return this.source.request('DELETE', `/v1/locations/${id}`, {
            contentType: 'json',
            options,
        });
    }
    /**
     * Returns a list of locations within the current account.
     *
     * The locations returned are sorted by creation date, with the most recently added
     * location appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/locations', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new location where services are offered.
     *
     * You may add any number of locations at which services are offered. Each account
     * is currently limited to one virtual location, which contains information and
     * practice-wide availability schedules for your virtual visits.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/locations', {
            data: params,
            contentType: 'json',
            options,
        });
    }
}
exports.LocationResource = LocationResource;
