"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentResource = void 0;
const BaseResource_1 = require("../BaseResource");
class DocumentResource extends BaseResource_1.Resource {
    /**
     * Returns a list of documents within the current account.
     *
     * The documents returned are sorted by creation date, with the most recently added
     * document appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/documents', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new document for the member you specify. Use the [Files API](../file/)
     * to upload a file with a purpose of document to include in the document resource.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/documents', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing document. You need only supply the unique
     * document identifier that was returned upon creation or when listing documents.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/documents/${id}`, {
            options,
        });
    }
    /**
     * Updates the specified document by setting the values of the parameters passed.
     * Any parameters not provided will be left unchanged.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/documents/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Deletes the specified document. When a document is deleted, the associated file
     * is not guaranteed to remain stored by Source and can no longer be accessed by a
     * member.
     */
    delete(id, options) {
        return this.source.request('DELETE', `/v1/documents/${id}`, {
            contentType: 'json',
            options,
        });
    }
}
exports.DocumentResource = DocumentResource;
