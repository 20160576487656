"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountResource = void 0;
const BaseResource_1 = require("../BaseResource");
class AccountResource extends BaseResource_1.Resource {
    /**
     * Retrieves the details of an account.
     *
     * Supply the unique identifier of the account, or `current` to access your current
     * account.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/accounts/${id}`, {
            options,
        });
    }
    /**
     * Updates an account. At this time you can only update the account name and
     * subdomain.
     *
     * Any parameters that are not provided in the request will be left unchanged.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/accounts/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
}
exports.AccountResource = AccountResource;
