import React, { useCallback, useState } from 'react'
import { RoutePaths } from 'containers/Core/Routes'
import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  chakra,
  Text,
  useToast,
} from '@chakra-ui/react'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { Formik, Form } from 'formik'
import { useStytch } from '@stytch/react'
import AppLogo from 'components/AppLogo'
import PasswordInput from 'components/PasswordInput'
import { StytchAPIError } from '@stytch/vanilla-js'

interface FormData {
  password: string
}

const PasswordRequirements: React.FC<{ password: string }> = ({ password }) => {
  const requirements = [
    { label: '8 characters', isValid: password.length >= 8 },
    { label: 'Uppercase letter', isValid: /[A-Z]/.test(password) },
    { label: 'Lowercase letter', isValid: /[a-z]/.test(password) },
    {
      label: 'Special character',
      isValid: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    },
    { label: 'Number', isValid: /\d/.test(password) },
  ]

  return (
    <Box mt={2} mb={4}>
      <Flex>
        <Box flex="1">
          {requirements.slice(0, 3).map((req, index) => (
            <Flex key={index} alignItems="center" mb={2}>
              <chakra.span mr={2} color={req.isValid ? 'green.500' : 'red.500'}>
                {req.isValid ? <CheckIcon /> : <CloseIcon />}
              </chakra.span>
              <Text
                color={req.isValid ? 'green.500' : 'red.500'}
                fontSize="sm"
                mb={0}
              >
                {req.label}
              </Text>
            </Flex>
          ))}
        </Box>
        <Box flex="1">
          {requirements.slice(3).map((req, index) => (
            <Flex key={index} alignItems="center" mb={2}>
              <chakra.span mr={2} color={req.isValid ? 'green.500' : 'red.500'}>
                {req.isValid ? <CheckIcon /> : <CloseIcon />}
              </chakra.span>
              <Text
                color={req.isValid ? 'green.500' : 'red.500'}
                fontSize="sm"
                mb={0}
              >
                {req.label}
              </Text>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Box>
  )
}

const PasswordReset: React.FC = ({}) => {
  const [password, setPassword] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(false)

  const redirectUri = window.location.origin + RoutePaths.ONBOARDING
  const stytchClient = useStytch()
  const toast = useToast()

  const token = new URLSearchParams(window.location.search).get('token')

  const initialValues: FormData = {
    password: '',
  }

  const checkPasswordValidity = (password: string) => {
    const requirements = [
      password.length >= 8,
      /[A-Z]/.test(password),
      /[a-z]/.test(password),
      /[!@#$%^&*(),.?":{}|<>]/.test(password),
      /\d/.test(password),
    ]
    return requirements.every(Boolean)
  }

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    setFieldValue: Function
  ) => {
    const newPassword = e.target.value
    setPassword(newPassword)
    setFieldValue('password', newPassword)
    setIsPasswordValid(checkPasswordValidity(newPassword))
  }

  const resetPassword = useCallback(
    async (values: FormData) => {
      try {
        const response = await stytchClient.passwords.resetByEmail({
          token: token || '',
          password: values.password,
          session_duration_minutes: 60,
        })
        if (response.status_code === 200) {
          window.location.href = redirectUri
        } else {
          toast({
            title: 'Error',
            description: 'Something went wrong. Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
      } catch (error) {
        let description =
          'There was an error resetting your password. Please try again.'
        if (error instanceof StytchAPIError) {
          if (error.error_type === 'breached_password') {
            description =
              'Password is too common. Please try a different password.'
          }
        }

        toast({
          title: 'Error',
          description: description,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    },
    [stytchClient]
  )

  return (
    <ChakraProvider>
      <Flex
        justify="center"
        align="center"
        height="100vh"
        backgroundColor="var(--gray-extra-light)"
      >
        <Box bg="white" p={8} borderRadius="lg" boxShadow="lg" width="sm">
          <Center mb={50}>
            <AppLogo color={'var(--blue-bright)'} />
          </Center>
          <Center>
            <Heading color="gray.700" as="h4" size="m" mb={8}>
              Reset your password
            </Heading>
          </Center>
          <Formik initialValues={initialValues} onSubmit={resetPassword}>
            {({ setFieldValue }) => (
              <Form>
                <FormControl mb={4} isRequired>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <PasswordInput
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handlePasswordChange(e, setFieldValue)
                    }
                  />
                  <PasswordRequirements password={password} />
                </FormControl>

                <Button
                  type="submit"
                  backgroundColor={'var(--blue-light)'}
                  color="white"
                  width="full"
                  mt={4}
                  _hover={{
                    backgroundColor: 'var(--blue-dark-hover)',
                  }}
                  isDisabled={!isPasswordValid}
                >
                  Reset
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Flex>
    </ChakraProvider>
  )
}

export default PasswordReset
