import AppLogo from 'components/AppLogo'
import { PillButton } from 'components/Button'
import { memo } from 'react'

const BookConsultConfirmed: React.FC = () => {
  return (
    <div className="d-flex flex-column align-items-center bg-light vh-100">
      <div className="mt-8">
        <AppLogo width="150px" />
      </div>
      <h4 className="mt-8 text-center fs-8">
        Your consult has been scheduled successfully.
      </h4>
      <p className="mt-3 text-center fs-5">
        Please check your email for a calendar invite with instructions on how
        to join.
        <br />
        In the meantime, checkout our{' '}
        <a href="https://allarahealth.com/blog?utm_campaign=book-consult-complete&utm_source=calendly&utm_medium=web">
          blog
        </a>{' '}
        for more information and resources.
      </p>
      <a
        className="mt-4"
        href="https://allarahealth.com/blog?utm_campaign=book-consult-complete&utm_source=calendly&utm_medium=web"
      >
        <PillButton className="justify-content-center">Explore Blog</PillButton>
      </a>
    </div>
  )
}

export default memo(BookConsultConfirmed)
