import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'
import AlertModal from './AlertModal'

export interface AlertContextInterface {
  setAlertText: (text: string, title?: string, err?: unknown) => void
}

export const AlertContext = createContext<AlertContextInterface>(
  {} as AlertContextInterface
)

interface AlertProviderProps {
  children: ReactNode
}

export const AlertProvider: React.FC<AlertProviderProps> = memo(
  ({ children }) => {
    const [alertText, _setAlertText] = useState<string | null>(null)
    const [alertTitle, setAlertTitle] = useState<string | undefined>(undefined)

    const handleCloseAlert = useCallback(() => {
      _setAlertText(null)
    }, [_setAlertText])

    const setAlertText = useCallback(
      (text: string, title?: string, err?: unknown) => {
        _setAlertText(text)
        setAlertTitle(title)
        console.error(err)
      },
      [setAlertTitle, _setAlertText]
    )

    return (
      <AlertContext.Provider value={{ setAlertText }}>
        {children}
        <AlertModal
          open={alertText !== null}
          title={alertTitle}
          message={alertText ?? 'Info:'}
          close={handleCloseAlert}
        />
      </AlertContext.Provider>
    )
  }
)

export const useAlert = (): AlertContextInterface => useContext(AlertContext)
