import { useStytch, useStytchSession } from '@stytch/react'
import { Goals as GoalsRoute, QueryKey as GoalsQueryKey } from 'api/goals/goals'
import { Provider, User } from 'hooks/useUser/useUser'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

export type GoalCategoryEnum =
  | ''
  | 'Food Group'
  | 'Nutrition Mindfulness'
  | 'Eating Frequency'
  | 'Mental Health/Stress'
  | 'Self Care/Love'
  | 'Physical Activity'
  | 'Other'

export type GoalStatus = 'draft' | 'active' | 'complete' | 'paused' | 'deleted'

export type GoalCategory = {
  id: number
  name: GoalCategoryEnum
}

export type Goal = {
  id: string
  text: string
  frequencyPerWeek?: number
  category: GoalCategory
  status: GoalStatus
  user: User
  created: string // date
  createdBy: Provider
}

interface UseGoalsInterface {
  isLoading: boolean
  goals: Goal[]
  currentGoals: Goal[]
  completedGoals: Goal[]
}

const useGoals = (): UseGoalsInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchGoals = async (): Promise<Goal[]> => {
    if (!session) {
      return []
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${GoalsRoute}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          return []
        }
      })
      .catch(() => {
        return []
      })
  }

  const { isLoading, data } = useQuery(GoalsQueryKey, fetchGoals, {
    staleTime: Infinity,
  })

  const goals = useMemo(() => data ?? [], [data])

  const currentGoals = useMemo(
    () => goals.filter((g) => g.status === 'active'),
    [goals]
  )

  const completedGoals = useMemo(
    () => goals.filter((g) => g.status === 'complete'),
    [goals]
  )

  return { isLoading, goals, currentGoals, completedGoals }
}

export default useGoals
