import { JWTAuthentication } from '@allara-health/source-health-client'
import {
  SourceHealth as SourceHealthRoute,
  SourceHealthQueryKey,
} from 'api/source/routes'
import { useQuery } from 'react-query'
import { useStytch, useStytchSession } from '@stytch/react'

interface UseSourceMemberJwtInterface {
  error: unknown
  isLoading: boolean
  memberToken?: JWTAuthentication
}

const useSourceMemberJwt = (): UseSourceMemberJwtInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchJwt = async (): Promise<JWTAuthentication | undefined> => {
    if (!session) {
      return undefined
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${SourceHealthRoute}/member/jwt`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    ).then(async (res) => {
      const jwt = await res.text()
      return new JWTAuthentication(jwt)
    })
  }

  const { isLoading, error, data: memberToken } = useQuery(
    SourceHealthQueryKey,
    fetchJwt,
    { staleTime: 1000 * 60 * 14 } // JWT expires every 15min
  )

  return { error, isLoading, memberToken }
}

export default useSourceMemberJwt
