import { Thread } from '@allara-health/source-health-client'
import { ThreadsQueryKey } from 'api/source/routes'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import { useSourceContext } from 'context/SourceContext/SourceProvider'
import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import useThreads from './useThreads'

interface UseThreadInterface {
  thread?: Thread
  threadId?: string
  reopenThread: () => Promise<Thread | void>
  resolveThread: () => Promise<Thread | void>
}

interface ChatParams {
  threadId?: string
}

const useThread = (): UseThreadInterface => {
  const { threads } = useThreads()
  const { setAlertText } = useAlert()
  const queryClient = useQueryClient()
  const { source } = useSourceContext()
  const { threadId } = useParams<ChatParams>()

  const thread = useMemo(() => threads?.find((t) => t.id === threadId), [
    threads,
    threadId,
  ])

  const resolveThread = useCallback(async () => {
    if (!threadId) {
      return setAlertText(
        `Unable to resolve thread. No thread selected.`,
        'An Error Occurred'
      )
    } else {
      return source.communications.threads
        .update(threadId, { status: 'closed' })
        .then(() => queryClient.invalidateQueries(ThreadsQueryKey))
        .catch((err) =>
          setAlertText(
            `Unable to resolve thread.\n\n${err}`,
            'An Error Occurred'
          )
        )
    }
  }, [source, threadId])

  const reopenThread = useCallback(async () => {
    if (!threadId) {
      return setAlertText(
        `Unable to reopen thread. No thread selected.`,
        'An Error Occurred'
      )
    } else {
      return source.communications.threads
        .update(threadId, { status: 'awaiting_member' })
        .then(() => queryClient.invalidateQueries(ThreadsQueryKey))
        .catch((err) =>
          setAlertText(
            `Unable to reopen thread.\n\n${err}`,
            'An Error Occurred'
          )
        )
    }
  }, [source, threadId])

  return { thread, threadId, resolveThread, reopenThread }
}

export default useThread
