import { addMinutes } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

import momentTZ from 'moment-timezone'
import { toGCal, toICS } from './time.utils'
import { Appointment } from 'hooks/useAppointments/useAppointments'
import { Provider } from 'hooks/useUser/useUser'

type GetDownloadICSParams = {
  provider: Provider
  dateTime: Date
  timeZone: string
  appointment: Appointment
  appointmentDuration: number
}

type AddToGCalParams = GetDownloadICSParams

type GetCalendarDetailsParams = {
  seperator: string
} & GetDownloadICSParams

const getCalendarDetails = ({
  seperator,
  provider,
  dateTime,
  timeZone,
  appointment,
  appointmentDuration,
}: GetCalendarDetailsParams) => {
  const appointmentType = appointment.type
  const endDateTime = addMinutes(dateTime, appointmentDuration)
  const timeZoneShorthand = momentTZ.tz(timeZone).format('z')
  const timeZoneOffset = momentTZ.tz(timeZone).format('Z')
  const zonedDate = formatInTimeZone(dateTime, timeZone, 'EEEE, MMMM d, yyyy')
  const zonedStartDate = formatInTimeZone(dateTime, timeZone, 'h:mm	aaa')
  const zonedEndDate = formatInTimeZone(endDateTime, timeZone, 'h:mm	aaa')

  const providerName = `${provider.firstName} ${provider.lastName}`
  const videoCallUrl = appointment.videoCallLink ?? ''
  const title = `Allara ${appointmentType.reason} with ${providerName}`
  const description = [
    `Allara ${appointmentType.reason} with ${providerName}`,
    `Click to join: ${videoCallUrl}`,
    `${timeZoneShorthand} (GMT ${timeZoneOffset})`,
    `${zonedDate}`,
    `${zonedStartDate} - ${zonedEndDate}`,
  ].join(seperator)

  return {
    dateTime,
    endDateTime,
    title,
    description,
    location: videoCallUrl,
  }
}

export const getDownloadICS = ({
  provider,
  dateTime,
  timeZone,
  appointment,
  appointmentDuration,
}: GetDownloadICSParams) => {
  const appointmentType = appointment.type
  // Seperator used to join the description
  const ESCAPED_SEPARATOR =
    navigator.appVersion.indexOf('Win') !== -1 ? `\\r\\n` : `\\n`

  const calendarDetails = getCalendarDetails({
    seperator: ESCAPED_SEPARATOR,
    provider,
    dateTime,
    timeZone,
    appointment,
    appointmentDuration,
  })

  if (!calendarDetails || !appointment || !appointmentType) {
    return alert('Could not save ICS file')
  }

  // Download the ICS file
  const rawICS = toICS(`${appointment.id}@source`, calendarDetails)
  const blob = new Blob([rawICS])
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = `allara_${appointmentType.reason.replace(
    / /g,
    '_'
  )}_appointment.ics`
  document.body.appendChild(a)
  a.click()
  a.remove()
  window.URL.revokeObjectURL(url)
}

export const addToGCal = ({
  provider,
  dateTime,
  timeZone,
  appointment,
  appointmentDuration,
}: AddToGCalParams) => {
  // Seperator used to join the description
  const SEPARATOR = navigator.appVersion.indexOf('Win') !== -1 ? `\r\n` : `\n`

  const calendarDetails = getCalendarDetails({
    seperator: SEPARATOR,
    provider,
    dateTime,
    timeZone,
    appointment,
    appointmentDuration,
  })

  if (!calendarDetails) {
    return alert('Could not create event')
  }

  window.open(toGCal(calendarDetails), '_blank')
}
