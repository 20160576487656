import {
  AppointmentNotes as AppointmentNotesRoute,
  QueryKey as AppointmentsQueryKey,
} from 'api/appointments/routes'
import { User } from 'hooks/useUser/useUser'
import { useQuery } from 'react-query'
import { Appointment } from './useAppointments'
import { useStytch, useStytchSession } from '@stytch/react'

export type AppointmentNote = {
  id: string
  text: string
  status: 'draft' | 'signed'
  timestamp: string
  user: User
  appointment: Appointment
  attachments?: SourceDocument[]
}

export type SourceDocument = {
  id: string
  fileId: string
  fileName: string
  fileUrl: string
}

interface UseAppointmentNotesInterface {
  isLoading: boolean
  appointmentNotes?: AppointmentNote[]
}

const useAppointmentNotes = (): UseAppointmentNotesInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchAppointmentNotes = async (): Promise<AppointmentNote[]> => {
    if (!session) {
      return []
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${AppointmentNotesRoute}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          return []
        }
      })
      .catch(() => {
        return []
      })
  }

  const { isLoading, data: appointmentNotes } = useQuery(
    [AppointmentsQueryKey, 'notes'],
    fetchAppointmentNotes
  )

  return { isLoading, appointmentNotes }
}

export default useAppointmentNotes
