import { Payer } from '@allara-health/source-health-client'
import { useStytch, useStytchSession } from '@stytch/react'
import { PayersQueryKey, Payers as PayersRoute } from 'api/source/routes'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

interface UsePayersInterface {
  error: unknown
  isLoading: boolean
  payers: Payer[]
}

const usePayers = (): UsePayersInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchPayers = async (): Promise<Payer[]> => {
    if (!session) {
      return []
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${PayersRoute}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        const payers = await res.json()
        return payers as Payer[]
      })
      .catch(() => [])
  }

  const { isLoading, error, data } = useQuery(PayersQueryKey, fetchPayers, {
    staleTime: Infinity,
  })

  const payers = useMemo(
    () => data?.sort((a, b) => (a.name > b.name ? 1 : -1)) ?? [],
    [data]
  )

  return { isLoading, error, payers }
}

export default usePayers
