"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const SourceError_1 = require("../SourceError");
const utils_1 = require("../utils");
class FetchAdapter {
    request(request) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const { timeout } = (_a = request.options) !== null && _a !== void 0 ? _a : {};
            const controller = new AbortController();
            const timer = timeout ? setTimeout(() => controller.abort(), timeout) : null;
            try {
                const { headers, data } = this.serializeContent(request.data, request.contentType);
                const url = (0, utils_1.createUrl)(request.baseUrl, request.path, request.query);
                const response = yield fetch(url.toString(), {
                    signal: controller.signal,
                    method: request.method,
                    headers: Object.assign(Object.assign({}, headers), request.headers),
                    body: data,
                });
                const responseData = (yield response.json());
                if (responseData.object === 'error') { // eslint-disable-line
                    throw SourceError_1.SourceError.from(responseData);
                }
                return {
                    status: response.status,
                    data: responseData,
                    headers: Object.fromEntries([...response.headers.entries()]),
                };
            }
            catch (ex) {
                if (ex instanceof SourceError_1.SourceError) {
                    throw ex;
                }
                throw new SourceError_1.SourceError({
                    type: 'client_error',
                    code: 'invalid_response',
                    message: 'Invalid response received from the API',
                    cause: ex,
                });
            }
            finally {
                if (timer) {
                    clearTimeout(timer);
                }
            }
        });
    }
    serializeContent(content, contentType = 'json') {
        switch (contentType) {
            case 'json':
                return {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: JSON.stringify(content),
                };
            case 'multipart': {
                const values = (0, utils_1.toUrlEncoded)(content);
                const data = new FormData();
                for (const [key, value] of Object.entries(values)) {
                    const isBlob = value instanceof Blob || toString.call(value) === '[object Blob]';
                    data.append(key, isBlob ? value : String(value));
                }
                return {
                    data,
                };
            }
        }
    }
}
exports.default = FetchAdapter;
