import axios from 'axios'
import { OnboardingResponse as OnboardingResponseRoute } from 'api/common/routes'
import { useStytch, useStytchSession } from '@stytch/react'

interface UseOnboardingPageViewInterface {
  trackPageView: (page: string) => Promise<void>
}

const UseOnboardingPageView = (): UseOnboardingPageViewInterface => {
  const { session } = useStytchSession()
  const stytch = useStytch()

  const trackPageView = async (page: string) => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${OnboardingResponseRoute}/view`,
        { page },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .catch(() => {
        // Fail silently
        return
      })
  }

  return { trackPageView }
}

export default UseOnboardingPageView
