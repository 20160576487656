import { useStripe } from '@stripe/react-stripe-js'
import { useStytch, useStytchSession } from '@stytch/react'
import {
  AttachPaymentMethod,
  OneTimePayment,
  PaymentSubscription,
} from 'api/payment/routes'
import axios, { AxiosError } from 'axios'
import { PaymentPlan } from 'context/OnboardingContext/OnboardingProvider'

type PaymentIntentResponse = {
  id: string
  client_secret?: string
  customer: string
}

interface UsePaymentInterface {
  createPaymentIntent: (
    plan: PaymentPlan,
    promoCode?: string,
    isDirect?: boolean
  ) => Promise<PaymentIntentResponse>
  processCardPayment: (
    client_secret: string,
    paymentMethod?: string
  ) => Promise<undefined>
  attachPaymentMethod: (paymentMethodId: string) => Promise<undefined>
}

const usePayment = (): UsePaymentInterface => {
  const stripe = useStripe()
  const stytch = useStytch()
  const { session } = useStytchSession()

  /**
   * Creates a payment intent or subscription and returns a client_secret
   * @param priceId - the id of the stripe plan/price
   * @param recurring - true if creating a subscription, false for OTP
   * @param promoCode - (optional) promotional code to apply to the order
   * @returns client_secret on success. Rejects with ErrorResponse on error
   */
  const createPaymentIntent = async (
    plan: PaymentPlan,
    promoCode?: string,
    isDirect?: boolean
  ): Promise<PaymentIntentResponse> => {
    if (!session) {
      return Promise.reject({ error: 'You must be logged in' })
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    const apiRoute = plan.recurring ? PaymentSubscription : OneTimePayment
    const queryParams = isDirect ? `?direct=true` : ''
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${apiRoute}${queryParams}`,
        {
          priceId: plan.id,
          promoCode,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then((res) => res.data)
      .catch((err) => {
        const typedErr = err as AxiosError

        return Promise.reject({ error: typedErr.response?.data?.message })
      })
  }

  /**
   * Process the card payment
   * @param paymentIntentResponse
   * @param paymentMethodId
   * @returns undefined on success. Rejects with ErrorResponse on error
   */
  const processCardPayment = async (
    client_secret: string,
    paymentMethodId?: string
  ): Promise<undefined> => {
    if (client_secret) {
      if (!stripe) {
        return Promise.reject({
          error:
            'Could not establish connection with stripe to process your payment. Your card has not been charged. Please reload the page and try again.',
        })
      }

      const options = paymentMethodId
        ? { payment_method: paymentMethodId }
        : undefined
      const confirmedPayment = await stripe.confirmCardPayment(
        client_secret,
        options
      )

      if (confirmedPayment.error) {
        return Promise.reject({
          error: `Unable to process card payment. You have not been charged. Please try again. \n\n${confirmedPayment.error.message}`,
        })
      } else if (confirmedPayment.paymentIntent.status !== 'succeeded') {
        return Promise.reject({
          error:
            'Some error occurred processing payment. Your card has not been charged. Please contact try again.',
        })
      }
    }

    return
  }

  /**
   * Attach a payment method to a user
   * @param paymentMethodId
   * @returns undefined on success or throws error
   */
  const attachPaymentMethod = async (
    paymentMethodId: string
  ): Promise<undefined> => {
    if (!session) {
      return Promise.reject({ error: 'You must be logged in' })
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${AttachPaymentMethod(
          paymentMethodId
        )}`,
        undefined,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then(() => undefined)
  }

  return {
    createPaymentIntent,
    processCardPayment,
    attachPaymentMethod,
  }
}

export default usePayment
