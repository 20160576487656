import React from 'react'
import { Redirect } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import withAccount, { WithAccountProps } from 'hoc/withAccount'

function withAccountStatus<T extends WithAccountProps>(
  WrappedComponent: React.ComponentType<T>
): React.FC<Omit<T, 'user'>> {
  const displayName = `WithAccountStatus(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`

  const ComponentWithAccountStatus = withAccount(
    (props: T & WithAccountProps) => {
      if (props.user && props.user.accountStatus === 'CANCELLED') {
        return <Redirect to={RoutePaths.MANAGE_MEMBERSHIP} />
      }

      return <WrappedComponent {...props} />
    }
  )

  ComponentWithAccountStatus.displayName = displayName

  return ComponentWithAccountStatus
}

export default withAccountStatus
