import AppLogo from 'components/AppLogo'
import Loader from 'components/Loader'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import useSearchParams from 'hooks/useSearchParams'
import useNewPatient from 'hooks/useUser/newPatient'
import { memo, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { Amplitude } from 'utils/amplitude.utils'
import { generateAppointmentLink } from 'utils/appointments.utils'

const SchedulingRedirect: React.FC = () => {
  const searchParams = useSearchParams()
  const { isLoading } = useNewPatient()
  const { variantLabel } = useOnboardingContext()

  const appointmentTypeId = searchParams.get('type') as string
  const appointmentLink = generateAppointmentLink(appointmentTypeId)

  useEffect(() => Amplitude.bookAppointment(variantLabel), [])

  return isLoading ? (
    <Container fluid className="px-0 m-0 scheduling">
      <div className="mt-10 w-100 d-flex align-items-center flex-column">
        <AppLogo />
        <h3 className="text-center mt-4">
          Please wait while we setup your account
        </h3>
      </div>
      <Loader />
    </Container>
  ) : (
    <Redirect to={appointmentLink} />
  )
}

export default memo(SchedulingRedirect)
