"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldResource = void 0;
const BaseResource_1 = require("../BaseResource");
class FieldResource extends BaseResource_1.Resource {
    /**
     * Lists all fields belonging to the provided object.
     */
    list(object, params, options) {
        return this.source.request('GET', `/v1/objects/${object}/fields`, {
            query: params,
            options,
        });
    }
    /**
     * Creates a new field.
     *
     * Currently, Source only allows the creation of custom fields on a member
     * resource. Other resources will support fields in future API updates.
     */
    create(object, params, options) {
        return this.source.request('POST', `/v1/objects/${object}/fields`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing field on an object.
     */
    retrieve(object, id, options) {
        return this.source.request('GET', `/v1/objects/${object}/fields/${id}`, {
            options,
        });
    }
    /**
     * Updates an existing field, referenced by its unique identifier.
     *
     * Once a field is created, its neither its key nor data type can be modified. If
     * you need to change data types, you may create another field, migrate the data
     * using the API, and delete the first field.
     */
    update(object, id, params, options) {
        return this.source.request('POST', `/v1/objects/${object}/fields/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Deletes an existing field, referenced by its unique identifier.
     *
     * **Important**: Deleting a field is a destructive action. When deleting a field,
     * Source schedules a cleanup action that will remove any value provided to that
     * field, for any resource referencing the field. This action is irreversible.
     *
     * Please ensure you no longer need the data in a field before deleting it.
     */
    delete(object, id, options) {
        return this.source.request('DELETE', `/v1/objects/${object}/fields/${id}`, {
            contentType: 'json',
            options,
        });
    }
}
exports.FieldResource = FieldResource;
