import Loader from 'components/Loader'
import { memo, MouseEventHandler, useEffect, useState } from 'react'
import { PillButtonProps } from './PillButton'

import './styles.scss'

const SecondaryPillButton: React.FC<PillButtonProps> = ({
  children,
  className,
  disabled,
  isLoading,
  loaderSize,
  size = 'lg',
  onClick,
}) => {
  const [clicked, setClicked] = useState<boolean>(false)

  useEffect(() => {
    if (!isLoading) {
      setClicked(false)
    } else {
      setClicked(true)
    }
  }, [isLoading])

  const onClickHandler: MouseEventHandler<HTMLElement> = (e) => {
    setClicked(true)

    if (onClick) {
      onClick(e)
    }
  }

  return (
    <button
      className={
        `pill-button pill-button-${size} pill-button-secondary d-flex align-items-center position-relative ` +
        className
      }
      onClick={onClickHandler}
      disabled={disabled || (clicked && isLoading)}
    >
      {clicked && isLoading ? <Loader size={loaderSize} /> : children}
    </button>
  )
}

export default memo(SecondaryPillButton)
