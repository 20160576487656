import { PillButton } from 'components/Button'
import { RoutePaths } from 'containers/Core/Routes'
import { useHistory } from 'react-router-dom'
import InsuranceCoveredIcon from 'assets/images/insurance-covered.png'
import { ReactComponent as RightIcon } from 'assets/images/chevron-right.svg'
import OnboardingContainer from '../OnboardingContainer/index'

import './styles.scss'
import { useEffect, useMemo } from 'react'
import UseOnboardingPageView from 'hooks/useOnboardingPageView'
import { Amplitude } from 'utils/amplitude.utils'
import Linkify from 'components/Linkify'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ChakraProvider,
  Heading,
} from '@chakra-ui/react'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'

interface InsuranceCoverageProps {
  coverage: 'eligible' | 'ineligible' | 'unknown'
}

const InsuranceCoverage: React.FC<InsuranceCoverageProps> = ({ coverage }) => {
  const history = useHistory()
  const { trackPageView } = UseOnboardingPageView()
  const { variantLabel } = useOnboardingContext()

  const handleClick = () => {
    Amplitude.insuranceCoverage(
      coverage === 'eligible' ? 'covered' : 'testing-covered',
      variantLabel
    )
    history.push(`${RoutePaths.PROGRAMS}${window.location.search}`)
  }

  // Track page view
  useEffect(() => {
    trackPageView(coverage === 'eligible' ? 'coveredInsurance' : 'coveredLabs')
    Amplitude.insuranceCoverageLoaded(
      coverage === 'eligible'
        ? 'covered'
        : coverage === 'ineligible'
        ? 'testing-covered'
        : 'more-info',
      variantLabel
    )
  }, [])

  const title = useMemo(() => {
    switch (coverage) {
      case 'unknown':
        return 'We need more information'
    }
  }, [])

  const subtitle = useMemo(() => {
    switch (coverage) {
      case 'eligible':
        return 'Great news, we’re in network with your insurance'
      case 'unknown':
        return undefined
      case 'ineligible':
      default:
        return undefined
    }
  }, [])

  const text: string[] = useMemo(() => {
    switch (coverage) {
      case 'eligible':
        return [
          'We’ll bill all covered services like visits, labs, and prescriptions through your insurance. Your exact costs will depend on your plan coverage, copay, and deductible.',
        ]
      case 'unknown':
        return [
          "To better understand your eligibility, please reach out to Billing@allarahealth.com. Our team can help you navigate your coverage for Allara's services.",
          'To speed up the process, please include your state of residence and a photo of the front and back of your insurance card.',
        ]
      case 'ineligible':
      default:
        return [
          "You'll pay a subscription fee that includes the cost of your care (including your video visits with providers, messaging, etc...) as well as access to our platform and concierge support.",
          "We'll bill covered services like labs and prescriptions through your insurance. The amount you pay will depend on your specific plan coverage.",
        ]
    }
  }, [])

  const note = useMemo(() => {
    switch (coverage) {
      case 'ineligible':
        return '*Note- if you have a high deductible plan for labs or a closed network insurance provider (such as Kaiser), we have negotiated discounted lab rates for you and will work with you to understand your benefits and get the most affordable cost of care.'
      case 'eligible':
      case 'unknown':
    }
  }, [])

  const canContinue = useMemo(() => coverage !== 'unknown', [])

  return (
    <OnboardingContainer>
      <ChakraProvider>
        <div className="insurance-covered d-flex justify-content-center py-5">
          <div className="card d-flex flex-column align-items-center px-5 py-5">
            <img
              src={InsuranceCoveredIcon}
              className="card-image mb-2"
              alt="coverage-icon"
            />
            <p className="ff-inter-medium header">{title}</p>
            {subtitle && (
              <p className="ff-inter-medium text-center align-self-center sub-header">
                {subtitle}
              </p>
            )}
            {text.map((line, i) => (
              <Linkify key={i}>
                <p className="text-center ff-inter sub-header fw-400">{line}</p>
              </Linkify>
            ))}
            <div className="spacer-1"></div>
            {canContinue && (
              <>
                <div className="d-flex justify-content-center mb-3 w-100">
                  <PillButton
                    className="continue-button justify-content-between align-items-center"
                    onClick={handleClick}
                  >
                    <div>Continue</div>
                    <RightIcon />
                  </PillButton>
                </div>
                <p className="text-center ff-inter sub-header fs-2">{note}</p>
              </>
            )}
            {coverage === 'eligible' && (
              <div>
                <Heading as="h5" size="sm" noOfLines={1}>
                  FAQs
                </Heading>

                <Accordion defaultIndex={[0]} allowMultiple mt={2}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          What is Allara’s membership fee?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      Your membership fee gives you access to our platform which
                      enables our collaborative care model, member perks, and
                      concierge care. We offer two options for membership:
                      $20/month or $180/year. Our medical providers bill as
                      specialists and your nutrition visits will be with a
                      Registered Dietitian.
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          How much will I pay out of pocket?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      This depends on your individual insurance plan. We will
                      bill your insurance for services including visits, lab
                      work, imaging, and prescription medications. You will be
                      responsible for the cost of copays, coinsurance, or
                      deductible fees associated with your visits.
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          Need more help understanding your insurance coverage?{' '}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      Email us at billing@allarahealth.com and we are happy to
                      help.
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            )}
          </div>
        </div>
      </ChakraProvider>
    </OnboardingContainer>
  )
}

export default InsuranceCoverage
