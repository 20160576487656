import AppLogo from 'components/AppLogo'
import { WithAccountProps } from 'hoc/withAccount'
import withSubscription from 'hoc/withSubscription'
import { Box, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react'
import { Banner } from 'components/Banners'
import useBanner, { BannerConstants } from 'hooks/useBanner/useBanner'
import BlockContent from '@sanity/block-content-to-react'
import Navigation from '../Navigation'

const Header: React.FC<WithAccountProps> = ({ user }) => {
  const { banner } = useBanner(BannerConstants.DASHBOARD)
  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <Grid
      templateAreas={`"logo banner"`}
      gap={6}
      gridTemplateColumns={['8fr 1fr', '8fr 1fr', '1fr 8fr']}
      gridTemplateRows={'1fr'}
      pl="5"
      pr="5"
    >
      <GridItem area="logo" pt="12">
        <AppLogo />
      </GridItem>
      {!isMobile ? (
        <GridItem area="banner" pt="4">
          <Banner
            visible={banner?.bannerStatus ?? false}
            variant="warning"
            message={
              <BlockContent
                // Array of portable text blocks
                blocks={banner?.description}
                // Custom serializers for marks, blocks
                serializers={{
                  marks: (props: {
                    children: React.ReactNode | string | undefined
                  }) => {
                    return <span>{props.children}</span>
                  },
                }}
              />
            }
          />
        </GridItem>
      ) : (
        <GridItem area="banner" pt="4">
          <Navigation />
        </GridItem>
      )}
    </Grid>
  )
}

export default withSubscription(Header)
