import Container from 'react-bootstrap/Container'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import ErrorBoundary from './ErrorBoundary'
import Routes from './Routes'
import { OnboardingProvider } from 'context/OnboardingContext/OnboardingProvider'
import { useEffect } from 'react'
import { AlertProvider } from 'context/AlertContext/AlertContextProvider'
import { useFlags } from 'launchdarkly-react-client-sdk'
import '../../theme/main.scss'
import Downtime from './Downtime'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: 1000 * 60, // 60s
    },
  },
})

function App(): JSX.Element {
  const { temporaryDowntimeRedirect } = useFlags()

  // NOTE: Temporary Downtime Redirect Flag
  if (temporaryDowntimeRedirect) {
    return <Downtime />
  }

  useEffect(() => {
    function handleResize() {
      document.documentElement.style.setProperty(
        '--inner-height',
        `${document.documentElement.clientHeight - 75}px`
      )
      document.documentElement.style.setProperty(
        '--inner-height-md',
        `${document.documentElement.clientHeight - 75}px`
      )
      document.documentElement.style.setProperty(
        '--inner-height-lg',
        `${document.documentElement.clientHeight - 100}px`
      )
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Router>
      <ErrorBoundary>
        <AlertProvider>
          <QueryClientProvider client={queryClient}>
            <OnboardingProvider>
              <Container fluid className="Container">
                <Routes />
              </Container>
            </OnboardingProvider>
          </QueryClientProvider>
        </AlertProvider>
      </ErrorBoundary>
    </Router>
  )
}

export default App
