import { useStytch, useStytchSession } from '@stytch/react'
import {
  AppointmentTypeQueryKey,
  SourceAppointmentType as SourceAppointmentTypeRoute,
} from 'api/source/routes'
import { useQuery } from 'react-query'

export type Category =
  | 'Diagnostic'
  | 'Initial RD'
  | 'Initial MD'
  | 'Initial HMT'
  | 'Followup MD'
  | 'Followup RD'
  | 'Initial Nutrition Only'
  | 'Followup Nutrition Only'
  | 'Care Transfer MD'

interface UseAppointmentTypeIdInterface {
  appointmentTypeId?: string
  isLoading: boolean
}

const useAppointmentTypeId = (
  category: Category
): UseAppointmentTypeIdInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchAppointmentTypeId = async (): Promise<string | undefined> => {
    if (!session) {
      return undefined
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${SourceAppointmentTypeRoute(
        category
      )}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data.id
        } else {
          return undefined
        }
      })
      .catch(() => {
        return undefined
      })
  }

  const { isLoading, data: appointmentTypeId } = useQuery(
    [AppointmentTypeQueryKey, category],
    fetchAppointmentTypeId,
    { staleTime: Infinity }
  )

  return { isLoading, appointmentTypeId }
}

export default useAppointmentTypeId
