import { PrimaryButton } from 'components/Button'
import { Modal } from 'react-bootstrap'

interface AlertModalProps {
  open: boolean
  message: string
  title?: string
  close: () => void
}

const AlertModal: React.FC<AlertModalProps> = ({
  open,
  title,
  message,
  close,
}) => {
  return (
    <Modal
      onHide={close}
      show={open}
      size="sm"
      centered
      className="alert-modal"
    >
      <Modal.Header closeButton>
        {title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <PrimaryButton onClick={close} variant="green" size="sm">
          Dismiss
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  )
}

export default AlertModal
