import { useEffect, useState } from 'react'

const useSearchParams = (): URLSearchParams => {
  const [searchParams, setSearchParams] = useState<URLSearchParams>(
    new URLSearchParams(window.location.search)
  )

  useEffect(
    () => setSearchParams(new URLSearchParams(window.location.search)),
    [window.location.search]
  )

  return searchParams
}

export default useSearchParams
