import { useAppDispatch } from 'app/hooks'
import { setIsDrawerOpen } from 'features/dashboard/dashbordSlice'
import { LinkProps, Link, useLocation } from 'react-router-dom'

interface CustomLinkProps extends Omit<LinkProps, 'href'> {
  to: string
  badge?: boolean
  active?: boolean
  preventClose?: boolean
}

const CustomLink: React.FC<CustomLinkProps> = ({
  children,
  to = '',
  badge,
  active,
  className = '',
  preventClose = false,
  ...props
}) => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  const isActive = active ?? location.pathname == to

  const linkClassName = `spaced-capital-letters fs-5 ${
    isActive ? 'active' : ''
  } ${className}`

  const onClickHandler = () => {
    if (!preventClose) {
      dispatch(setIsDrawerOpen(false))
    }
  }

  return (
    <li className="my-5" onClick={onClickHandler}>
      <div className="d-flex align-items-center">
        {to.includes('http') || to.includes('.com') ? (
          <a className={linkClassName} href={to} {...props}>
            {children}
          </a>
        ) : (
          <Link className={linkClassName} to={to} {...props}>
            {children}
          </Link>
        )}
        {badge && <span className="chat-badge"></span>}
      </div>
    </li>
  )
}

export default CustomLink
