import { SecondaryPillButton } from 'components/Button'
import TextField from 'components/TextField'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import { usePromoCodeContext } from 'context/PromoCodeContext/PromoCodeProvider'
import useSearchParams from 'hooks/useSearchParams'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'

const CheckoutDetails: React.FC = () => {
  const searchParams = useSearchParams()
  const { selectedPlan } = useOnboardingContext()
  const { isLoading, promoCode, applyCode } = usePromoCodeContext()
  const [promoCodeTextValue, setPromoCodeTextValue] = useState<string>('')
  const [promoIsApplied, setPromoIsApplied] = useState<boolean>(false)
  const isVip = searchParams.get('vip') !== null
  const promoCodeFromUrl = searchParams.get('vip')
  const handlePromoCodeTextValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPromoIsApplied(false)
      setPromoCodeTextValue(e.target.value.toUpperCase())
    },
    []
  )

  const handleApply = useCallback(() => {
    setPromoIsApplied(true)
    applyCode(promoCodeTextValue)
  }, [promoCodeTextValue])

  // Automatically apply promo code if it exists
  useEffect(() => {
    const handleParamApply = async () => {
      if (
        promoCodeFromUrl !== null &&
        promoCodeFromUrl.length > 0 &&
        promoCodeFromUrl.toLowerCase() !== 'true'
      ) {
        setPromoCodeTextValue(promoCodeFromUrl)
        await applyCode(promoCodeFromUrl)
        setPromoIsApplied(true)
      }
    }

    handleParamApply()
  }, [promoCodeFromUrl])

  if (!selectedPlan) {
    return <></>
  }

  return (
    <div className="checkout-details d-block w-100 pb-3">
      <div className="d-flex flex-column">
        <div className="summary">
          <p className="h1">
            {selectedPlan.recurring ? ` ${selectedPlan.metadata.benefits}` : ''}
          </p>
          <p className="h1">
            <span
              className={`ff-inner fs-10 font-weight-bold ${
                promoCode?.isValid ? 'text-strike-through' : ''
              }`}
            >
              {selectedPlan ? `\$${selectedPlan.unit_amount / 100}` : ''}
            </span>
            {selectedPlan?.recurring && selectedPlan?.unit_amount !== 0 && (
              <span> / {selectedPlan.recurring.interval}</span>
            )}
          </p>

          {isVip && promoCode?.isValid && promoCode.discount && (
            <p className="h1">
              {promoCode.discount.newAmount}
              {promoCode.discount.subtitle ? '*' : ''}
            </p>
          )}
        </div>

        {isVip && (
          <div className="mt-3">
            <p className="mb-0 ff-inter-medium subtitle">Promo Code</p>
            <span className="text-style">
              If you have a promo code, please enter it in the field below to
              apply it to your purchase
            </span>
            <p className="mt-3 mb-0 ff-inter-medium subtitle subtitle-opacity">
              Promo Code
            </p>
            <div className="d-flex justify-content-between pt-2 w-100">
              <div className="d-flex flex-row w-100 position-relative ">
                <TextField
                  className="w-100 promo-input"
                  placeholder="Promo Code"
                  controlId="promoCodeTextValue"
                  value={promoCodeTextValue}
                  onChange={handlePromoCodeTextValueChange}
                  containerClassName={'promo-input'}
                />
                <SecondaryPillButton
                  className="promo-code-apply-button justify-content-center"
                  size="sm"
                  loaderSize="sm"
                  isLoading={isLoading}
                  disabled={!promoCodeTextValue || promoIsApplied}
                  onClick={handleApply}
                >
                  {promoIsApplied ? 'Applied!' : 'Apply'}
                </SecondaryPillButton>
              </div>
            </div>
            {promoCode?.error && (
              <p className="text-danger pt-2 mb-0">{promoCode.error}</p>
            )}
          </div>
        )}

        {selectedPlan?.unit_amount === 0 && (
          <div className="mt-3">
            <span className="font-weight-bold">
              Why do we ask for credit card information?
            </span>
            <br />
            We keep your card on file to charge for any fees that are not
            covered by your insurance or that your insurance assigns to you such
            as copayments, coinsurance, and deductibles.
          </div>
        )}
      </div>
    </div>
  )
}

export default CheckoutDetails
