import { Box, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react'
import CurrentMembershipCard from './CurrentMembershipCard'
import InvoicesList from './InvoicesList'

const CurrentMembership = () => {
  const templateAreas = useBreakpointValue({
    base: `"membership" "invoices"`,
    md: `"membership invoices"`,
  })
  return (
    <>
      <Box borderWidth="1px" borderRadius="lg">
        <Grid
          templateAreas={templateAreas}
          templateColumns={{ base: '1fr', md: '1fr 1fr' }}
        >
          <GridItem area="membership">
            <CurrentMembershipCard />
          </GridItem>
          <GridItem area="invoices">
            <InvoicesList />
          </GridItem>
        </Grid>
      </Box>
    </>
  )
}

export default CurrentMembership
