"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationPreferencesResource = void 0;
const BaseResource_1 = require("../BaseResource");
class NotificationPreferencesResource extends BaseResource_1.Resource {
    retrieveForMember(recipient, options) {
        return this.source.request('GET', `/v1/members/${recipient}/notification_preferences`, {
            options,
        });
    }
    updateForMember(recipient, params, options) {
        return this.source.request('POST', `/v1/members/${recipient}/notification_preferences`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    retrieveForUser(recipient, options) {
        return this.source.request('GET', `/v1/users/${recipient}/notification_preferences`, {
            options,
        });
    }
    updateForUser(recipient, params, options) {
        return this.source.request('POST', `/v1/users/${recipient}/notification_preferences`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
}
exports.NotificationPreferencesResource = NotificationPreferencesResource;
