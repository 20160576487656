import { memo, ReactElement } from 'react'
import {
  Alert,
  useDisclosure,
  AlertDescription,
  CloseButton,
  Button,
  Flex,
} from '@chakra-ui/react'

interface BannerProps {
  message: string | ReactElement
  visible: boolean
  variant: 'info' | 'warning' | 'success' | 'error' | 'loading' | undefined
}

const Banner = ({ message, visible, variant }: BannerProps) => {
  const { isOpen: isVisible, onClose, onOpen } = useDisclosure({
    defaultIsOpen: true,
  })

  if (!visible) {
    return null
  }

  return isVisible ? (
    <Alert
      status={variant}
      variant="subtle"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      mt={2}
    >
      <AlertDescription mr={2}>{message}</AlertDescription>
      <CloseButton
        position="absolute"
        right="8px"
        top="8px"
        onClick={onClose}
      />
    </Alert>
  ) : (
    <Flex justifyContent="flex-end">
      <Button onClick={onOpen} variant="ghost" size="sm" mt={2}>
        Show banner
      </Button>
    </Flex>
  )
}

export default memo(Banner)
