"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntentResource = void 0;
const BaseResource_1 = require("../BaseResource");
class IntentResource extends BaseResource_1.Resource {
    /**
     * Returns a list of intents within the current account.
     *
     * The intents returned are sorted by creation date, with the most recently added
     * intent appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/intents', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new intent of the type you specify. You must specify configuration
     * within the intent that corresponds  to the intent type you create. You can
     * create as many intents as you wish for a member.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/intents', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves an existing intent by its unique identifier or its secret.
     *
     * Intents can be accessed by users in your organization, and members can access
     * their own intents. Additionally, intents can be accessed by passing the intent
     * secret as a parameter.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/intents/${id}`, {
            options,
        });
    }
    /**
     * Cancels an existing intent.
     *
     * Members can no longer access canceled intents and can optionally be filtered out
     * when displaying a list of intents.  Since existing intents cannot be updated,
     * you can instead cancel an intent and create a new intent to replace it.
     */
    cancel(id, options) {
        return this.source.request('POST', `/v1/intents/${id}/cancel`, {
            contentType: 'json',
            options,
        });
    }
}
exports.IntentResource = IntentResource;
