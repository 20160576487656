import {
  PaymentPlan,
  useOnboardingContext,
} from 'context/OnboardingContext/OnboardingProvider'
import { useCallback } from 'react'

interface SelectPlanProps {
  plan: PaymentPlan
  isLast: boolean
}

const SelectPlan: React.FC<SelectPlanProps> = ({ plan, isLast }) => {
  const { selectedPlan, setSelectedPlan } = useOnboardingContext()

  const handleSelectPlan = useCallback(() => {
    setSelectedPlan(plan)
  }, [])

  return (
    <>
      <div key={plan.id} className="d-flex flex-column my-3 ml-3">
        <div className="d-flex align-items-center">
          <input
            type="radio"
            id={plan.id}
            name="plan"
            value={plan.id}
            onChange={handleSelectPlan}
            checked={plan.id === selectedPlan?.id}
          />
          <label
            htmlFor={plan.id}
            className={`fs-3 mb-0 ml-3 ${
              plan.id === selectedPlan?.id ? 'ff-inter-bold' : 'ff-inter'
            }`}
          >
            {plan.metadata.benefits}
          </label>
        </div>
        <div>
          {plan.metadata.savings && (
            <div className="pricing-plans-savings ff-inter fs-2 mt-2 text-center">
              {plan.metadata.savings}
            </div>
          )}
        </div>
      </div>
      {!isLast && <hr className="m-0" />}
    </>
  )
}

export default SelectPlan
