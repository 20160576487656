import { memo } from 'react'
import Goals from './Goals'
import Insurance from './Insurance'
import Labs from './Labs'
import PastAppointments from './PastAppointments'
import useBanner, { BannerConstants } from 'hooks/useBanner/useBanner'
import { WithAccountProps } from 'hoc/withAccount'
import { Box, Flex, Text, Link } from '@chakra-ui/react'

import './styles.scss'

const MyHealth: React.FC<WithAccountProps> = (props) => {
  const { banner } = useBanner(BannerConstants.DASHBOARD)

  return (
    <div
      className={`myhealth-container mb-5 ${
        banner?.bannerStatus ? 'pt-0' : 'pt-8'
      }`}
    >
      <p className="h1 mb-0 mt-2 fs-14">My Health</p>
      {props.user.accountStatus === 'CANCELLED' && (
        <Box
          backgroundColor={'var(--gray-extra-light)'}
          borderRadius="lg"
          width="50%"
          mt={4}
        >
          <Flex alignItems="center" p={4}>
            <Text mb={0}>
              If you would like a copy of your chart history, please email{'  '}
              <Link
                href="mailto:concierge@allarahealth.com"
                color={'var(--blue-light)'}
              >
                Patient Concierge.
              </Link>
            </Text>
          </Flex>
        </Box>
      )}
      <PastAppointments />
      <Labs />
      <Goals />
      <Insurance {...props} />
    </div>
  )
}

export default memo(MyHealth)
