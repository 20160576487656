import { BaseSyntheticEvent, useEffect, useMemo, useState } from 'react'
import OnboardingContainer from '../OnboardingContainer'
import ReactTouchEvents from 'react-touch-events'
import { WaitlistUser } from 'hooks/useUser/useEligibilityInfo'
import { Program } from 'context/OnboardingContext/OnboardingProvider'
import withEligibility, { WithEligibilityProps } from 'hoc/withEligibility'
import { ReactComponent as ArrowIcon } from 'assets/images/arrow-left.svg'
import InsuranceIcon from 'assets/images/insurance-plan.svg'
import DiagnosticIcon from 'assets/images/science-white.png'
import NutritionIcon from 'assets/images/food-white.png'
import PCOSIcon from 'assets/images/heart-shield-white.png'

import ProgramCard from './program-card'

import './styles.scss'
import UseOnboardingPageView from 'hooks/useOnboardingPageView'
import { PROGRAMS } from 'utils/program.utils'

interface ProgramsProps {
  recommended?: Program
  eligible: Program[]
}

interface ProgramsBaseProps extends WithEligibilityProps {
  showHidden?: boolean
}

const Programs: React.FC<ProgramsBaseProps> = ({
  waitlistUser,
  showHidden,
}) => {
  const { trackPageView } = UseOnboardingPageView()
  const recommended = getRecommendedProgram(waitlistUser)
  const eligiblePrograms = waitlistUser.eligibleProducts
    .filter((p) => (showHidden ? true : !p.hidden))
    .map((p) => p.type)
    .filter(function (value, index, array) {
      return array.indexOf(value) === index
    }) // Remove deuplicates because two nutrition-only

  const mobileOrderedPrograms = useMemo(
    () =>
      recommended
        ? [recommended, ...eligiblePrograms.filter((p) => p !== recommended)]
        : eligiblePrograms,
    [recommended, eligiblePrograms]
  )

  const desktopOrderedPrograms = useMemo(() => {
    if (!recommended) {
      return eligiblePrograms
    }

    const otherPrograms = eligiblePrograms.filter((p) => p !== recommended)

    if (otherPrograms.length === 1) {
      return [recommended, otherPrograms[0]]
    } else if (otherPrograms.length === 2) {
      return [otherPrograms[0], recommended, otherPrograms[1]]
    } else if (otherPrograms.length > 2) {
      return [recommended, ...otherPrograms]
    } else {
      return [recommended]
    }
  }, [recommended, eligiblePrograms])

  // Track page view
  useEffect(() => {
    trackPageView('programs')
  }, [])

  return (
    <OnboardingContainer>
      <ProgramsDesktop
        recommended={recommended}
        eligible={desktopOrderedPrograms}
      />
      <ProgramsMobile
        recommended={recommended}
        eligible={mobileOrderedPrograms}
      />
    </OnboardingContainer>
  )
}

const ProgramsDesktop: React.FC<ProgramsProps> = ({
  recommended,
  eligible,
}) => {
  return (
    <div className={`d-none d-lg-flex justify-content-center mb-6 mx-4 mt-6`}>
      {eligible.map((program) =>
        getProgramCard(program, program === recommended)
      )}
    </div>
  )
}

const ProgramsMobile: React.FC<ProgramsProps> = ({ recommended, eligible }) => {
  const [screen, setScreen] = useState<number>(0)

  const handleSwipe = (_: BaseSyntheticEvent, direction: string) => {
    switch (direction) {
      case 'left':
        return handleSwipeLeft()
      case 'right':
        return handleSwipeRight()
    }
  }

  const handleSwipeLeft = () => setScreen((n) => (n + 1) % eligible.length)
  const handleSwipeRight = () =>
    setScreen((n) => (n === 0 ? eligible.length - 1 : n - 1))

  const currentCard = useMemo(
    () =>
      getProgramCard(eligible[screen], eligible[screen] === recommended, true),
    [screen]
  )

  const handleChangeScreen = (direction: 'left' | 'right') => {
    switch (direction) {
      case 'left':
        return setScreen(screen === 0 ? eligible.length - 1 : screen - 1)
      case 'right':
        return setScreen(screen + 1 === eligible.length ? 0 : screen + 1)
    }
  }

  return (
    <ReactTouchEvents onSwipe={handleSwipe} swipeTolerance={30}>
      <div className="d-flex d-lg-none flex-row justify-content-center align-items-start">
        {eligible.length > 1 && (
          <div
            className="navigation-arrow"
            onClick={() => handleChangeScreen('left')}
          >
            <ArrowIcon />
          </div>
        )}
        <div className="d-flex d-lg-none flex-column justify-content-center mb-0 mt-4">
          {eligible.length > 1 && (
            <div className="d-flex justify-content-center mb-7 dots">
              {Array.from(Array(eligible.length).keys()).map((i) => (
                <div
                  key={i}
                  onClick={() => setScreen(i)}
                  className={`pagination-dot${i === screen ? ' active' : ''}`}
                />
              ))}
            </div>
          )}
          <div className="d-flex justify-content-center mb-6">
            {currentCard}
          </div>
        </div>
        {eligible.length > 1 && (
          <div
            className="navigation-arrow navigation-arrow-right"
            onClick={() => handleChangeScreen('right')}
          >
            <ArrowIcon />
          </div>
        )}
      </div>
    </ReactTouchEvents>
  )
}

const getProgramCard = (
  program: Program,
  isRecommended = false,
  isMobile = false
) => {
  switch (program) {
    case PROGRAMS.FULL_PROGRAM:
      return (
        <ProgramCard
          program={program}
          subTitle="Monthly subscription with 3-6 Mo. Commitment"
          icon={PCOSIcon}
          key={program}
          isRecommended={isRecommended}
          isMobile={isMobile}
        />
      )
    case PROGRAMS.NUTRITION_ONLY:
      return (
        <ProgramCard
          program={program}
          subTitle="Monthly Subscription"
          icon={NutritionIcon}
          key={program}
          isRecommended={isRecommended}
          isMobile={isMobile}
        />
      )
    case PROGRAMS.DIAGNOSTIC:
      return (
        <ProgramCard
          program={program}
          icon={DiagnosticIcon}
          key={program}
          isRecommended={isRecommended}
          isMobile={isMobile}
          subTitle="One time payment"
        />
      )
    case PROGRAMS.INSURANCE:
      return (
        <ProgramCard
          key={program}
          program="insurance"
          secondaryInfoTitle="Your insurance will be billed for:"
          subTitle="Monthly or annual subscription"
          isRecommended={isRecommended}
          isMobile={isMobile}
          icon={InsuranceIcon}
        />
      )
    case PROGRAMS.INSURANCE_DIAGNOSTIC:
      return (
        <ProgramCard
          key={program}
          program={program}
          icon={DiagnosticIcon}
          isRecommended={isRecommended}
          isMobile={isMobile}
          secondaryInfoTitle="Your insurance will be billed for:"
          subTitle="One time payment"
        />
      )
  }
}

const getRecommendedProgram = (
  waitlistUser: WaitlistUser
): Program | undefined => {
  const isEligible = (program: Program) => {
    const found = waitlistUser.eligibleProducts.find((p) => p.type === program)
    return found !== undefined
  }

  // If only eligible for one program then recommend it
  if (waitlistUser.eligibleProducts.length === 1 || !waitlistUser.reason) {
    return undefined
    // return waitlistUser.eligibleProducts[0].type
  }

  // Recommend program based on what brings you to Allara
  if (
    waitlistUser.reason.value === 'treatment' &&
    isEligible(PROGRAMS.FULL_PROGRAM)
  ) {
    return PROGRAMS.FULL_PROGRAM
  } else if (
    waitlistUser.reason.value === 'treatment' &&
    isEligible(PROGRAMS.INSURANCE)
  ) {
    return PROGRAMS.INSURANCE
  } else if (
    (waitlistUser.reason.value === 'understand_health' ||
      waitlistUser.reason.value === 'answers') &&
    isEligible(PROGRAMS.DIAGNOSTIC)
  ) {
    return PROGRAMS.DIAGNOSTIC
  } else if (
    (waitlistUser.reason.value === 'understand_health' ||
      waitlistUser.reason.value === 'answers') &&
    isEligible(PROGRAMS.INSURANCE_DIAGNOSTIC)
  ) {
    return PROGRAMS.INSURANCE_DIAGNOSTIC
  } else if (
    waitlistUser.reason.value === 'learn_more' &&
    isEligible(PROGRAMS.FULL_PROGRAM)
  ) {
    return PROGRAMS.FULL_PROGRAM
  } else if (
    waitlistUser.reason.value === 'learn_more' &&
    isEligible(PROGRAMS.INSURANCE)
  ) {
    return PROGRAMS.INSURANCE
  }

  // Defaults
  if (isEligible(PROGRAMS.FULL_PROGRAM)) {
    return PROGRAMS.FULL_PROGRAM
  } else if (isEligible(PROGRAMS.INSURANCE)) {
    return PROGRAMS.INSURANCE
  } else {
    return waitlistUser.eligibleProducts[0].type
  }
}

export default withEligibility(Programs)
