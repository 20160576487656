"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvailabilityResource = void 0;
const BaseResource_1 = require("../../BaseResource");
class AvailabilityResource extends BaseResource_1.Resource {
    /**
     * Retrieves the availability schedule for a user.
     *
     * Each user in Source has an availability schedule created for them by default.
     * The availability schedules are unique for each environment, so test and live
     * mode will have different availability objects.
     */
    retrieveForUser(user, options) {
        return this.source.request('GET', `/v1/users/${user}/availability`, {
            options,
        });
    }
    /**
     * Updates an availability schedule for a user.
     *
     * You'll need to update a user's availability schedule to make them bookable for
     * appointments in Source.
     */
    updateForUser(user, params, options) {
        return this.source.request('POST', `/v1/users/${user}/availability`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the availability schedule for a location.
     */
    retrieveForLocation(location, options) {
        return this.source.request('GET', `/v1/locations/${location}/availability`, {
            options,
        });
    }
    /**
     * Updates an availability schedule for a location.
     */
    updateForLocation(location, params, options) {
        return this.source.request('POST', `/v1/locations/${location}/availability`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
}
exports.AvailabilityResource = AvailabilityResource;
