import { PrecheckResponse } from '@allara-health/source-health-client'
import { PrecheckQueryKey } from 'api/source/routes'
import { useSchedulingContext } from 'context/SchedulingContext/SchedulingProvider'
import { useSourceContext } from 'context/SourceContext/SourceProvider'
import useMember from 'hooks/useUser/useMember'
import { useQuery } from 'react-query'

interface useSourcePrecheckInterface {
  result?: PrecheckResponse
  isLoading: boolean
}

/**
 * Uses the Source SDK to perform an availability precheck
 * @returns
 */
const useSourcePrecheck = (): useSourcePrecheckInterface => {
  const { source } = useSourceContext()
  const { appointmentType } = useSchedulingContext()
  const { member, isLoading: isMemberLoading } = useMember()

  const performPrecheck = async (): Promise<PrecheckResponse | undefined> => {
    if (!appointmentType?.id || !member?.id || !member?.license_region) {
      return {
        result: false,
      }
    }

    const response = await source.scheduling.slots.performPrecheck({
      appointment_type: appointmentType.id,
      member: member.id,
      license_region: member.license_region,
    })
    return response
  }

  const { data: result, isLoading } = useQuery(
    [PrecheckQueryKey, appointmentType?.id, member?.id, member?.license_region],
    performPrecheck,
    {
      enabled: !isMemberLoading,
    }
  )

  return { result, isLoading }
}

export default useSourcePrecheck
