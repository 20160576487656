import { Col } from 'react-bootstrap'
import { Route, Switch } from 'react-router'
import { Redirect } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import { WithAccountProps } from 'hoc/withAccount'
import Profile from '../Profile'
import Dashboard from '../Dashboard'
import Chat from '../Chat'
import MyHealth from '../MyHealth'
import CreateThread from '../Chat/CreateThread'
import { memo } from 'react'

const Content: React.FC<WithAccountProps> = (props) => {
  return (
    <Col
      className="position-relative max-height-screen pt-lg-5 p-3 p-lg-5"
      as="main"
    >
      <Switch>
        <Route path={RoutePaths.DASHBOARD_PROFILE}>
          <Profile {...props} />
        </Route>
        <Route path={RoutePaths.MANAGE_MEMBERSHIP}>
          <Profile {...props} />
        </Route>
        <Route path={RoutePaths.MANAGE_MEMBERSHIP_CANCEL}>
          <Profile {...props} />
        </Route>
        <Route path={RoutePaths.DASHBOARD_MYHEALTH}>
          <MyHealth {...props} />
        </Route>
        <Route path={RoutePaths.DASHBOARD_NEW_THREAD}>
          <CreateThread />
        </Route>
        <Route path={`${RoutePaths.DASHBOARD_CHAT}/:threadId?`}>
          <Chat />
        </Route>
        <Route path={`${RoutePaths.DASHBOARD_CONTENT_LIBRARY}/:contentType?`}>
          {/* <Library /> */}
          <Redirect to={RoutePaths.DASHBOARD} />
        </Route>
        <Route path={RoutePaths.DASHBOARD}>
          <Dashboard {...props} />
        </Route>
        <Route>
          <Redirect to={RoutePaths.DASHBOARD} />
        </Route>
      </Switch>
    </Col>
  )
}

export default memo(Content)
