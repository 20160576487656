import { useStytch, useStytchSession } from '@stytch/react'
import {
  HowDidYouHear as HowDidYouHearRoute,
  OnboardingResponse as OnboardingResponseRoute,
  ShareHowDidYouHear as ShareHowDidYouHearRoute,
} from 'api/common/routes'
import axios from 'axios'
import { useEffect, useState } from 'react'

export interface HowDidYouHear {
  id: number
  value: string
}

interface UseHowDidYouHearInterface {
  howDidYouHear: HowDidYouHear[] | undefined
  shareHowDidYouHear: (values: HowDidYouHear[]) => void
  updateOnboardingHowDidYouHear: (referredBy: string) => void
}

const useHowDidYouHear = (): UseHowDidYouHearInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()
  const [howDidYouHear, setHowDidYouHear] = useState<
    HowDidYouHear[] | undefined
  >(undefined)

  const fetchHowDidYouHear = async (): Promise<HowDidYouHear[]> => {
    return fetch(`${process.env.REACT_APP_SERVER_URL}${HowDidYouHearRoute}`)
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          return []
        }
      })
      .catch(() => [])
  }

  const shareHowDidYouHear = async (howDidYouHear: HowDidYouHear[]) => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios.patch(
      `${process.env.REACT_APP_SERVER_URL}${ShareHowDidYouHearRoute}`,
      { howDidYouHear },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
  }

  const updateOnboardingHowDidYouHear = async (referredBy: string) => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios.patch(
      `${process.env.REACT_APP_SERVER_URL}${OnboardingResponseRoute}`,
      {
        onboardingResponse: {
          referredBy: referredBy,
        },
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
  }

  useEffect(() => {
    fetchHowDidYouHear().then((hdyh) => {
      const sortedHdyh = hdyh.sort((a, b) => {
        if (a.value === 'Other' && b.value !== 'Other') {
          return 1
        }

        if (b.value === 'Other' && a.value !== 'Other') {
          return -1
        }

        return a.id - b.id
      })
      setHowDidYouHear(sortedHdyh)
    })
  }, [])

  return {
    howDidYouHear,
    shareHowDidYouHear,
    updateOnboardingHowDidYouHear,
  }
}

export default useHowDidYouHear
