import { Source } from '@allara-health/source-health-client'
import { SourceHealthQueryKey } from 'api/source/routes'
import AppLogo from 'components/AppLogo'
import Loader from 'components/Loader'
import useSourceMemberJwt from 'hooks/useSourceMemberJwt'
import { createContext, useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useQueryClient } from 'react-query'
import { SourceElements } from './SourceElements'

interface SourceContextInterface {
  source: Source
}

export const SourceContext = createContext<SourceContextInterface>(
  {} as SourceContextInterface
)

interface SourceProviderProps {
  children: React.ReactNode
}

export const SourceProvider: React.FC<SourceProviderProps> = ({ children }) => {
  const queryClient = useQueryClient()
  const { error, isLoading, memberToken } = useSourceMemberJwt()
  const [source, setSource] = useState<Source | undefined>(undefined)

  useEffect(() => {
    if (!error && !isLoading && memberToken && !source) {
      setSource(
        new Source(
          process.env.REACT_APP_SOURCE_BASE_URL ??
            'https://source-api.staging.source-api.allara-aws.net',
          memberToken
        )
      )
    } else if (!error && !isLoading && memberToken && source) {
      source.configuration.setAuthentication(memberToken)
    }
  }, [error, isLoading, source, memberToken])

  // Refresh token every 15 minutes
  useEffect(() => {
    const FIFTEEN_MIN = 1000 * 60 * 15
    setTimeout(
      () => queryClient.invalidateQueries(SourceHealthQueryKey),
      FIFTEEN_MIN
    )
  }, [memberToken])

  if (!source || isLoading) {
    return <Loader />
  }

  return (
    <SourceContext.Provider value={{ source }}>
      {!source ? (
        <Container className="d-flex flex-column justify-content-center align-items-center height-full">
          <AppLogo className="position-absolute top-3 " />
          <p className="h1 text-center text-danger pb-6">
            Something went wrong
          </p>
          <p className="text-center fs-6">Please try refreshing the page</p>
        </Container>
      ) : (
        <SourceElements client={source}>{children}</SourceElements>
      )}
    </SourceContext.Provider>
  )
}

export const useSourceContext = (): SourceContextInterface =>
  useContext(SourceContext)
