"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileResource = void 0;
const BaseResource_1 = require("../BaseResource");
class FileResource extends BaseResource_1.Resource {
    /**
     * Uploads a file to Source by sending a `multipart/form-data` request containing
     * the file to upload and any additional metadata.
     *
     * Files must be uploaded through the files API before they can be attached to
     * messages, set as profile photos, or otherwise used by the Source platform.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/files', {
            data: params,
            contentType: 'multipart',
            options,
        });
    }
    /**
     * Retrieves the details of an existing file. You need only supply the unique file
     * identifier that was returned upon file creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/files/${id}`, {
            options,
        });
    }
}
exports.FileResource = FileResource;
