import { useStripe } from '@stripe/react-stripe-js'
import { ChakraPrimaryButton } from 'components/Button'
import { RoutePaths } from 'containers/Core/Routes'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import { usePromoCodeContext } from 'context/PromoCodeContext/PromoCodeProvider'
import usePayment from 'hooks/usePayment/usePayment'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { JoinModalProps } from '.'
import { PROGRAMS } from 'utils/program.utils'

/**
 * This is a new sub component to ensure it has access to
 * the promo code context
 */
const JoinModalConfirm: React.FC<JoinModalProps> = ({
  promo,
  currentProgram,
  program,
  handleClose,
  onSuccess,
}) => {
  const stripe = useStripe()
  const { setAlertText } = useAlert()
  const { selectedPlan } = useOnboardingContext()
  const { promoCode, applyCode } = usePromoCodeContext()
  const { createPaymentIntent, processCardPayment } = usePayment()
  const [termsOfUse, setTermsOfUse] = useState<boolean>(false)
  const [isProcessingPayment, setIsProcessingPayment] = useState<boolean>(false)

  const toggleTermsOfUse = () => setTermsOfUse((x) => !x)

  const onSubmit = useCallback(async () => {
    if (!selectedPlan) {
      return setAlertText(
        `You have not selected a plan yet. Please return to ${process.env.REACT_APP_URL}/${RoutePaths.PAYMENT_PLANS}${window.location.search}`,
        'Hang on a second'
      )
    }

    if (!stripe) {
      return setAlertText(
        'Unable to establish connection with Stripe to process payment. Your card has not been charged. Please reload the page and try again.',
        'An Error Occurred'
      )
    }

    setIsProcessingPayment(true)
    return createPaymentIntent(selectedPlan, promo)
      .then(({ client_secret }) =>
        client_secret ? processCardPayment(client_secret) : undefined
      )
      .then(() => {
        handleClose()
        onSuccess()
      })
      .catch(({ error }) => setAlertText(error, 'An Error Occurred', error))
      .finally(() => setIsProcessingPayment(false))
  }, [selectedPlan])

  useEffect(() => {
    if (promo && selectedPlan) {
      applyCode(promo)
    }
  }, [selectedPlan, promo])

  const is_insuranceDx_to_insurance_full = useMemo(() => {
    return (
      currentProgram === PROGRAMS.INSURANCE_DIAGNOSTIC &&
      program === PROGRAMS.INSURANCE
    )
  }, [currentProgram, program, selectedPlan])
  return (
    <>
      {!is_insuranceDx_to_insurance_full && selectedPlan && (
        <p className="fs-5 mb-3 mw-300 text-center">
          <span className={promoCode?.isValid ? 'text-strike-through' : ''}>
            ${selectedPlan.unit_amount / 100}
            {selectedPlan.recurring
              ? `/${selectedPlan.recurring.interval}`
              : ''}
          </span>
          {promoCode?.isValid && promoCode.discount && (
            <>
              {'  '}
              {promoCode.discount.newAmount} {promoCode.discount.subtitle}
            </>
          )}
        </p>
      )}
      {is_insuranceDx_to_insurance_full && selectedPlan && (
        <p className="font-weight-bold text-center">
          ${selectedPlan.unit_amount / 100}
          {selectedPlan.recurring ? `/${selectedPlan.recurring.interval}` : ''}
        </p>
      )}
      <p className="fs-4 pb-3">
        {is_insuranceDx_to_insurance_full
          ? 'Please note: We will keep your card on file to charge for any fees that are not covered by your insurance or that your insurance assigns to you such as copayments, coinsurance, and deductibles. You can update your billing information in the Profile section of your dashboard (only on the web)'
          : 'We will process your payment with the card on file - you can update this information in the Profile section of your account.'}
      </p>

      <Form.Group
        className="d-flex align-items-center pt-3"
        controlId="termsOfUse"
      >
        <Form.Check checked={termsOfUse} onChange={toggleTermsOfUse} />
        <p className="fs-4 mb-0 mx-3">
          {is_insuranceDx_to_insurance_full
            ? 'By checking this box you acknowledge that your payment information will be kept on file for outstanding charges or patient responsibility.'
            : ' By checking this box, you agree to our subscription fee which will auto bill until cancelled.'}
        </p>
      </Form.Group>

      <ChakraPrimaryButton
        onClick={onSubmit}
        isLoading={isProcessingPayment}
        disabled={isProcessingPayment || !termsOfUse}
      >
        {is_insuranceDx_to_insurance_full
          ? 'Confirm & continue'
          : 'Confirm & pay'}
      </ChakraPrimaryButton>
    </>
  )
}

export default memo(JoinModalConfirm)
