import useGoals from 'hooks/useGoals'
import { memo } from 'react'
import { ReactComponent as GoalIcon } from 'assets/images/goals.svg'

const CurrentGoals: React.FC = () => {
  const { currentGoals } = useGoals()

  return (
    <>
      <p className="spaced-capital-letters fs-3 d-flex align-items-center">
        <GoalIcon className="goals-icon" />
        Current Goals
      </p>
      {currentGoals.length ? (
        <div>
          {currentGoals.map((goal, i) =>
            goal.frequencyPerWeek ? (
              <p key={goal.id}>
                {i + 1}. You will {goal.text.toLowerCase()}{' '}
                {goal.frequencyPerWeek} days per week
              </p>
            ) : (
              <p key={goal.id}>
                {i + 1}. {goal.text}
              </p>
            )
          )}
        </div>
      ) : (
        <p className="mt-4">
          You currently don&apos;t have any active goals.
          <br />
          You will work with your dietician to set goals.
        </p>
      )}
    </>
  )
}

export default memo(CurrentGoals)
