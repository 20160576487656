import { Container } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import Loader from 'components/Loader'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import { WithAccountProps } from 'hoc/withAccount'
import useUser from 'hooks/useUser/useUser'
import { RoutePaths } from 'containers/Core/Routes'
import useInsurance from 'hooks/useInsuranceCard'
import { isInsurancePatient, isPatient } from 'utils/program.utils'
import useSearchParams from 'hooks/useSearchParams'

function withSubscription<T extends WithAccountProps>(
  WrappedComponent: React.ComponentType<T>
): React.FC<Omit<T, 'user'>> {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'

  // Creating the inner component. The calculated Props type here is where the magic happens.
  const ComponentWithSubscription: React.FC<Omit<T, 'user'>> = (props) => {
    const { paymentDone, insuranceDone, acceptedTerms } = useOnboardingContext()
    const { user, userError, isLoading } = useUser()
    const { insurance, isLoading: isLoadingInsurance } = useInsurance()
    const searchParams = useSearchParams()
    const isRecheck = searchParams.get('insurance-recheck') !== null

    if (isLoading) {
      return <Loader />
    } else if (userError) {
      console.error('User Object Error:', userError)

      return (
        <Container className="d-flex flex-column justify-content-center height-full">
          <p className="h1 text-danger text-center">
            Some error occurred! Please try again later.
          </p>
        </Container>
      )
    } else if (!user) {
      return <Loader />
    } else if (user.accountStatus === 'CANCELLED') {
      return <WrappedComponent {...(props as T)} user={user} />
    } else if (user.accountDeactivated && !paymentDone) {
      if (isRecheck || !user.waitlistUser.insuranceProvider) {
        return (
          <Redirect
            to={RoutePaths.HAVE_INSURANCE + `/${window.location.search}`}
          />
        )
      }

      return <Redirect to={`/${window.location.search}`} />
    } else if (
      isPatient(user) &&
      !user.termsAndConditionSigned &&
      !acceptedTerms
    ) {
      return <Redirect to={RoutePaths.CONSENT_TO_TELEHEALTH} />
    } else if (isInsurancePatient(user) && !insuranceDone) {
      if (isLoadingInsurance) {
        return <Loader />
      } else if (!insurance) {
        return <Redirect to={RoutePaths.INSURANCE_INFO} />
      }
    }

    return <WrappedComponent {...(props as T)} user={user} />
  }

  ComponentWithSubscription.displayName = `withSubscription(${displayName})`

  return ComponentWithSubscription
}

export default withSubscription
