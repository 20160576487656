"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueResource = void 0;
const BaseResource_1 = require("../BaseResource");
class QueueResource extends BaseResource_1.Resource {
    /**
     * Returns a list of queues within the current account. The queues returned are
     * sorted by creation date, with the most recently added queue appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/queues', {
            query: params,
            options,
        });
    }
    /**
     * Creates a queue, which describes related tasks that are typically completed by a
     * group of users. You can use queues alongside groups to configure how tasks are
     * routed among a member's care team.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/queues', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing queue. You need only supply the unique
     * queue identifier that was returned upon creation or that is referenced by a task
     * definition.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/queues/${id}`, {
            options,
        });
    }
    /**
     * Updates the specified queue by setting the values of the parameters passed. Any
     * parameters not provided will be left unchanged.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/queues/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Deletes the specified queue. When the queue is deleted, all open tasks and task
     * definitions referencing the queue will be updated with a replacement queue. You
     * can optionally specify the replacement queue for open tasks, otherwise Source
     * will use the current default queue. Note that for the short period of time
     * between when the queue is deleted and open tasks are updated with a replacement
     * queue, open tasks may reference the now deleted queue.
     */
    delete(id, params, options) {
        return this.source.request('DELETE', `/v1/queues/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
}
exports.QueueResource = QueueResource;
