import { Appointment } from '@allara-health/source-health-client'
import { AppointmentQueryKey } from 'api/source/routes'
import { useSourceContext } from 'context/SourceContext/SourceProvider'
import { useQuery } from 'react-query'

interface UseSourceAppointmentInterface {
  appointment?: Appointment
  isLoading: boolean
}

const UseSourceAppointment = (
  appointmentId?: string
): UseSourceAppointmentInterface => {
  const { source } = useSourceContext()

  const fetchAppointment = async (): Promise<Appointment | undefined> => {
    if (!appointmentId) {
      return
    }

    return source.scheduling.appointments
      .retrieve(appointmentId, {
        expand: ['appointment_type', 'member', 'participants.participant'],
      })
      .catch(() => undefined)
  }

  const { data: appointment, isLoading } = useQuery(
    AppointmentQueryKey,
    fetchAppointment
  )

  return { appointment, isLoading }
}

export default UseSourceAppointment
