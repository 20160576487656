import { useStytch, useStytchSession } from '@stytch/react'
import { EligibleInsurances, QueryKey } from 'api/insurance/routes'
import { useQuery } from 'react-query'

export interface Insurance {
  title: string
  provider: string
}

interface UseEligibleInsuranceInterface {
  isLoading: boolean
  error: unknown
  insurances: Insurance[]
}

const useEligibleInsurances = (): UseEligibleInsuranceInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchEligibleInsurances = async (): Promise<Insurance[]> => {
    if (!session) {
      return []
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${EligibleInsurances}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json()
        } else {
          return []
        }
      })
      .catch(() => {
        return []
      })
  }

  const { isLoading, error, data } = useQuery(
    QueryKey,
    fetchEligibleInsurances,
    { staleTime: Infinity }
  )

  return {
    isLoading,
    error,
    insurances: data ?? [],
  }
}

export default useEligibleInsurances
