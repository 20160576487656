import { Category } from 'hooks/useAppointments/useAppointmentTypeId'

export const SourceMessaging = '/api/v1/source-messaging'
export const SourceHealth = '/api/v1/source'
export const SourceAppointmentType = (category: Category) =>
  `${SourceHealth}/appointment-type/${category}`

export const Payers = `${SourceHealth}/payers`
export const Threads = `${SourceHealth}/threads`
export const TimeZone = `${SourceHealth}/timezone`
export const Subjects = `${SourceMessaging}/subjects`
export const CreateThread = `${SourceMessaging}/create`
export const SendMessage = `${SourceMessaging}/send`
export const UploadFile = `${SourceMessaging}/upload-file`

export const MemberQueryKey = 'member'
export const PayersQueryKey = 'payers'
export const ThreadsQueryKey = 'threads'
export const SubjectsQueryKey = 'subjects'
export const AppointmentQueryKey = 'appointment'
export const SourceHealthQueryKey = 'source'
export const AppointmentTypeQueryKey = 'appointment-type'

export const SchedulingQueryKey = 'scheduling'
export const PrecheckQueryKey = 'precheck'
