import { Spinner, Center } from '@chakra-ui/react'
import './LoadingState.scss'

const LoadingState = () => {
  return (
    <div className="loading-state">
      <Center>{<Spinner size="xl" />}</Center>
    </div>
  )
}

export default LoadingState
