"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceConfiguration = void 0;
const authentication_1 = require("./authentication");
class SourceConfiguration {
    constructor(values) {
        var _a, _b;
        this._interceptors = [];
        this._baseUrl = values.baseUrl;
        this._authentication =
            typeof values.authentication === 'undefined'
                ? authentication_1.ApiKeyAuthentication.fromEnvironment()
                : values.authentication;
        this._requestOptions = (_a = values.defaultRequestOptions) !== null && _a !== void 0 ? _a : {};
        this._interceptors = (_b = values.requestInterceptors) !== null && _b !== void 0 ? _b : [];
    }
    setAuthentication(authentication) {
        this._authentication = authentication;
    }
    getAuthentication() {
        return this._authentication;
    }
    setBaseUrl(baseUrl) {
        this._baseUrl = baseUrl;
    }
    getBaseUrl() {
        return this._baseUrl;
    }
    setRequestOptions(options) {
        this._requestOptions = options;
    }
    getRequestOptions() {
        return this._requestOptions;
    }
    addRequestInterceptor(interceptor) {
        this._interceptors = this._interceptors.concat(interceptor);
    }
    removeInterceptor(interceptor) {
        this._interceptors = this._interceptors.filter((value) => value !== interceptor);
    }
    getInterceptors() {
        return this._interceptors;
    }
}
exports.SourceConfiguration = SourceConfiguration;
