/* eslint-disable react/no-unescaped-entities */

import { memo } from 'react'
import StaticInsuranceTimeline from './StaticTimeline'

import './styles.scss'
import { WithAccountProps } from 'hoc/withAccount'
import useAppointments from 'hooks/useAppointments/useAppointments'
import { Loading } from 'components/Loading'

export interface TimelineItem {
  bg: string
  flex: number
  text: string
  title: string
  description: string
  additionalTitle?: string
  additionalDescription?: string
}
const TIMELINE_ITEMS = {
  insurance: [
    {
      bg: 'var(--periwinkle-80)',
      flex: 1,
      text: 'Week 1',
      title: 'Meet your medical provider',
      description:
        "Kickstart your health journey with Allara! In your first visit, you'll partner with your provider to explore your health history, discuss goals, and plan next steps—including any recommended labs or imaging for a comprehensive picture of your current health.",
    },
    {
      bg: 'var(--periwinkle-60)',
      flex: 2,
      text: 'Week 2-3',
      title: 'Meet your registered dietitian',
      description:
        "Connect with your RD for an introduction to Allara's nutrition program and to share your current lifestyle and nutrition habits. At this first appointment, you'll also work with your RD to craft achievable goals that you can track via our mobile app.",
      additionalTitle: 'Complete labs or imaging',
      additionalDescription:
        "Your medical provider may recommend specific labs and/or imaging to paint a more complete picture of your health. We'll guide you through the process, ensuring you have all the information you need, every step of the way.",
    },
    {
      bg: 'var(--periwinkle-50)',
      flex: 1,
      text: 'First month',
      title: 'Book a follow-up with your medical provider',
      description:
        "Once you indicate in your To Do's that you've completed your labs, you can schedule your follow-up appointment. In this appointment, your provider will help you decode your results and suggest an ongoing care plan.",
    },
    {
      bg: 'var(--periwinkle-40)',
      flex: 1,
      text: 'Ongoing',
      title: 'Continue medical and nutrition care as needed',
      description:
        'You\'ll discuss a follow-up frequency with your providers tailored to your specific needs. Your dashboard will keep you on track with timely "To Do" reminders when it\'s time to follow up with your medical provider or RD.',
    },
  ],
  'full-program': [
    {
      bg: 'var(--periwinkle-80)',
      flex: 1,
      text: 'Week 1',
      title: 'Meet your medical provider',
      description:
        "Kickstart your health journey with Allara! In your first visit, you'll partner with your provider to explore your health history, discuss goals, and plan next steps—including any recommended labs or imaging for a comprehensive picture of your current health.",
    },
    {
      bg: 'var(--periwinkle-60)',
      flex: 2,
      text: 'Week 2-3',
      title: 'Complete labs or imaging',
      description:
        'Your medical provider may recommend specific labs and/or imaging to paint a more complete picture of your health. We’ll guide you through the process, ensuring you have all the information you need, every step of the way. ',
      additionalTitle: 'Book a follow-up with your medical provider',
      additionalDescription:
        'Once you indicate in your To Do’s that you’ve completed your labs, you can schedule your follow-up appointment. In this appointment, your provider will help you decode your results and suggest an ongoing care plan.',
    },
    {
      bg: 'var(--periwinkle-50)',
      flex: 1,
      text: 'First month',
      title: 'Meet your registered dietitian',
      description:
        'Connect with your RD for an introduction to Allara’s nutrition program and to share your current lifestyle and nutrition habits. At this first appointment, you’ll also work with your RD to craft achievable goals that you can track via our mobile app.',
    },
    {
      bg: 'var(--periwinkle-40)',
      flex: 1,
      text: 'Ongoing',
      title: 'Continue medical and nutrition care as needed',
      description:
        "When it's time for a follow up visit, you'll see a prompt in your dashboard to book an appointment. For Nutrition visits, this about every month or every other month, and for Medical visits this is about every 3 months.",
    },
  ],
  'nutrition-only': [
    {
      bg: 'var(--periwinkle-80)',
      flex: 2,
      text: '1-2 weeks',
      title: 'Meet your registered dietitian',
      description:
        'Connect with your RD for an introduction to Allara’s nutrition program and to share your current lifestyle and nutrition habits. At this first appointment, you’ll also work with your RD to craft achievable goals that you can track via our mobile app.',
      additionalTitle: 'Download the mobile app to track progress on goals',
      additionalDescription:
        'Downloading Allara’s mobile app enables you to track progress on health and lifestyle goals that you set with your RD.',
    },
    {
      bg: 'var(--periwinkle-60)',
      flex: 1,
      text: 'Ongoing',
      title: 'Follow up with your RD',
      description:
        'You’ll have regular video check-ins with your registered dietitian to get one-on-one support and make any necessary adjustments to your care. ',
    },
  ],
}

const Timeline: React.FC<WithAccountProps> = ({ user }) => {
  const timelineItems =
    TIMELINE_ITEMS[
      (user?.stripeCustomer?.product?.type as keyof typeof TIMELINE_ITEMS) ??
        'insurance'
    ]

  const {
    hasBookedNumberRDAppointments,
    hasBookedNumberMDAppointments,
    isLoading,
  } = useAppointments()

  if (isLoading) {
    return <Loading />
  }

  if (
    user?.stripeCustomer?.product?.type === 'nutrition-only' &&
    hasBookedNumberRDAppointments(3)
  ) {
    return null
  }

  if (
    (user?.stripeCustomer?.product?.type === 'full-program' ||
      user?.stripeCustomer?.product?.type === 'insurance') &&
    hasBookedNumberMDAppointments(4)
  ) {
    return null
  }

  return <StaticInsuranceTimeline timelineItems={timelineItems} />
}

export default memo(Timeline)
