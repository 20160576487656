"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagResource = void 0;
const BaseResource_1 = require("../BaseResource");
class TagResource extends BaseResource_1.Resource {
    /**
     * Returns a list of tags within the current account. The tags returned are sorted
     * by creation date, with the most recently added tag appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/tags', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new tag. You can create a new tag with a unique name and a display
     * color of your choice. If a tag already exists with the same name (case
     * insensitive), an error is returned.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/tags', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieve a tag by its unique identifier.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/tags/${id}`, {
            options,
        });
    }
    /**
     * Updates the specified tag by setting the values of the parameters passed. Any
     * parameters not provided will be left unchanged.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/tags/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Deletes the specified tag. A deleted tag is removed from all members to which it
     * relates.
     */
    delete(id, options) {
        return this.source.request('DELETE', `/v1/tags/${id}`, {
            contentType: 'json',
            options,
        });
    }
    /**
     * Archives the specified tag. An archived tag can be viewed on any member it
     * relates to but cannot be added to additional members.
     */
    archive(id, options) {
        return this.source.request('POST', `/v1/tags/${id}/archive`, {
            contentType: 'json',
            options,
        });
    }
    /**
     * Unarchives the specified tag. Once unarchived, the tag can be reapplied to
     * additional members.
     */
    unarchive(id, options) {
        return this.source.request('POST', `/v1/tags/${id}/unarchive`, {
            contentType: 'json',
            options,
        });
    }
}
exports.TagResource = TagResource;
