import { ComponentType, FunctionComponent, useRef } from 'react'
import { useThreadContext } from '../../../context/Threads/ThreadContext'
import {
  InfiniteScrollPaginator,
  InfiniteScrollPaginatorProps,
} from '../../../components/Paginator/InfiniteScrollPaginator'
import { useScrollPosition } from '../../../hooks/useScrollPosition'
import CustomMessage from './CustomMessage'
import { LoadingProps } from 'components/Loading/types'

export interface AvatarProps {
  file: File | null
  size?: number
}
export interface LoadingErrorProps {
  error?: Error
}

export interface MessageListProps {
  /**
   * Component that is responsible for rendering user avatars
   */
  AvatarComponent?: ComponentType<AvatarProps>

  /**
   * Custom component to use for the paginator (defaults to infinite scroll)
   */
  PaginatorComponent?: ComponentType<InfiniteScrollPaginatorProps>

  /**
   * Custom component to use for the loading indicator
   */
  LoadingComponent?: ComponentType<LoadingProps>

  /**
   * Custom component to use for the error message
   */
  LoadingErrorComponent?: ComponentType<LoadingErrorProps>

  MessageComponent: typeof CustomMessage
}

export const MessageList: FunctionComponent<MessageListProps> = ({
  LoadingComponent,
  PaginatorComponent: Paginator = InfiniteScrollPaginator,
  MessageComponent = CustomMessage,
  AvatarComponent,
}) => {
  const listRef = useRef<HTMLDivElement>(null)
  const {
    messages,
    isLoading,
    hasMoreMessages,
    fetchMoreMessages,
  } = useThreadContext()

  useScrollPosition({
    messages,
    listRef,
  })

  return (
    <div>
      <Paginator
        isLoading={isLoading}
        isReversed={true}
        hasNextPage={hasMoreMessages}
        scrollableRef={listRef}
        fetchNextPage={fetchMoreMessages}
        LoadingComponent={LoadingComponent}
      >
        {messages.map((message, index) => (
          <MessageComponent
            key={index}
            message={message}
            AvatarComponent={AvatarComponent}
          />
        ))}
      </Paginator>
    </div>
  )
}

export default MessageList
