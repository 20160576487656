import { Appointment } from 'hooks/useAppointments/useAppointments'
import useAppointmentDefinitions from 'hooks/useAppointments/useAppointmentDefinitions'
import React, { useMemo, useState } from 'react'
import { formatAppointmentText } from 'utils/appointments.utils'
import { RoutePaths } from 'containers/Core/Routes'
import MultiActionCard, { ActionProperties } from './MultiActionCard'
import CalendarModal from './Modals/CalendarModal'
import { useDisclosure } from '@chakra-ui/react'
import useMember from 'hooks/useUser/useMember'
import AppointmentNoteModal from '../MyHealth/PastAppointments/AppointmentNoteModal'
import useAppointmentNotes, {
  AppointmentNote,
} from 'hooks/useAppointments/useAppointmentNotes'

interface AppointmentCardProps {
  appointment: Appointment
}

const AppointmentCard: React.FC<AppointmentCardProps> = ({ appointment }) => {
  const { appointmentDefinitions } = useAppointmentDefinitions()
  const { member } = useMember()
  const { appointmentNotes } = useAppointmentNotes()
  const note = useMemo(
    () => appointmentNotes?.find((n) => n.appointment.id === appointment.id),
    [appointmentNotes]
  )
  const [showNote, setShowNote] = useState<AppointmentNote | null>(null)
  const hideShowNoteDialog = () => setShowNote(null)

  const handleOpenNote = () => {
    if (note) {
      setShowNote(note)
    }
  }

  const {
    isOpen: isCalendarModalOpen,
    onOpen: calendarModalOnOpen,
    onClose: calendarModalOnClose,
  } = useDisclosure()

  const getCode = () => {
    if (appointment.isCancelled) {
      return 'cancelled'
    } else if (new Date(appointment.time) > new Date()) {
      return 'scheduled'
    } else {
      return 'complete'
    }
  }

  const code = getCode()

  // timzeone can be both null or undefined. Just shorten to undefined
  const memberTimezone = member?.time_zone || undefined

  const appointmentDefinition = useMemo(() => {
    return appointmentDefinitions?.find((d) => d.id === code)
  }, [appointmentDefinitions])

  const appointmentAction: ActionProperties = {
    buttonLink: {
      link: `${RoutePaths.SCHEDULING}?appointment=${appointment.sourceId}`,
      target: '_self',
    },
    buttonText: appointmentDefinition?.buttonText ?? '',
    disableButton: false,
  }

  const calendarAction: ActionProperties = {
    buttonText: 'Add to calendar',
    disableButton: false,
    handleClick: () => calendarModalOnOpen(),
    isLink: true,
  }

  const appointmentSummaryAction: ActionProperties = {
    buttonText: 'Appointment summary',
    disableButton: false,
    handleClick: () => handleOpenNote(),
  }

  const getButtonActions = () => {
    if (code === 'scheduled') {
      return [appointmentAction, calendarAction]
    } else if (code == 'complete') {
      return note ? [appointmentSummaryAction] : []
    } else {
      return []
    }
  }

  const actions = appointmentDefinition?.buttonText ? getButtonActions() : []

  if (!appointmentDefinition) {
    return <></>
  } else {
    const title = formatAppointmentText(
      appointmentDefinition.title,
      appointment
    )
    let description = formatAppointmentText(
      appointmentDefinition.description,
      appointment
    )

    /// renderCardDescription looks for \\n to add a <br>
    if (!note && getCode() === 'complete') {
      description =
        description +
        '\\n' +
        'A summary of your appointment will be available here shortly.'
    }

    return (
      <>
        <MultiActionCard
          icon={appointmentDefinition.icon}
          title={title}
          description={description}
          actions={actions}
        />
        <CalendarModal
          isOpen={isCalendarModalOpen}
          onClose={calendarModalOnClose}
          appointment={appointment}
          timeZone={memberTimezone}
        />
        <AppointmentNoteModal note={showNote} onHide={hideShowNoteDialog} />
      </>
    )
  }
}

export default AppointmentCard
