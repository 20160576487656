import useGoals from 'hooks/useGoals'
import { memo } from 'react'
import { useState } from 'react'
import CompletedGoals from './CompletedGoals'
import CurrentGoals from './CurrentGoals'

const Goals: React.FC = () => {
  const { completedGoals } = useGoals()
  const [showCompletedGoals, setShowCompletedGoals] = useState<boolean>(false)
  const toggleCompletedGoals = () =>
    setShowCompletedGoals((current) => !current)

  return (
    <>
      <p className="spaced-capital-letters mt-4">My Goals</p>
      <div className="goals-container pt-4 px-3 pb-2">
        {showCompletedGoals ? <CompletedGoals /> : <CurrentGoals />}
        {completedGoals.length !== 0 && (
          <p
            onClick={toggleCompletedGoals}
            className="spaced-capital-letters btn-text fs-1 pl-2 mb-0"
          >
            {showCompletedGoals ? 'Return to Current Goals' : 'View Past Goals'}
          </p>
        )}
      </div>
    </>
  )
}

export default memo(Goals)
