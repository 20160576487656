import React from 'react'
import { Form, FormGroupProps } from 'react-bootstrap'

export interface InputContainerProps extends FormGroupProps {
  label?: string
  error?: React.ReactNode
  helperText?: string
}

type Ref = HTMLDivElement

const InputContainer = React.forwardRef<Ref, InputContainerProps>(
  (
    { label, className = '', error, children, helperText, ...containerProps },
    ref
  ) => {
    return (
      <Form.Group
        className={`text-left ${className}`}
        {...containerProps}
        ref={ref}
      >
        {label && <Form.Label>{label}</Form.Label>}
        {children}
        {error ? (
          <Form.Text className="text-danger">{error}</Form.Text>
        ) : (
          helperText && (
            <Form.Text className="fs-1 text-dark">{helperText}</Form.Text>
          )
        )}
      </Form.Group>
    )
  }
)

export default InputContainer
