import { ChakraPrimaryButton, PrimaryButton } from 'components/Button'
import { HStack } from '@chakra-ui/react'
import useActions from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'
import { AppStoreUrl, PlayStoreUrl } from '../../constants'
import { CardFormProps } from '.'

const DownloadAppForm: React.FC<CardFormProps> = ({ action }) => {
  const { handleCompleteAction } = useActions()

  const handleClick = useCallback(() => {
    handleCompleteAction(action.id)
  }, [action.id])

  return (
    <HStack>
      <ChakraPrimaryButton onClick={handleClick}>
        <a
          className="text-white"
          target="_blank'"
          href={AppStoreUrl}
          rel="noreferrer"
        >
          Download iOS
        </a>
      </ChakraPrimaryButton>
      <ChakraPrimaryButton onClick={handleClick}>
        <a
          className="text-white"
          target="_blank"
          href={PlayStoreUrl}
          rel="noreferrer"
        >
          Download Android
        </a>
      </ChakraPrimaryButton>
    </HStack>
  )
}

export default DownloadAppForm
