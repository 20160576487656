import { Form, FormControlProps } from 'react-bootstrap'

import InputContainer, { InputContainerProps } from 'hoc/InputContainer'

import './styles.scss'
import { memo } from 'react'

interface TextFieldProps extends FormControlProps, InputContainerProps {
  autoFocus?: boolean
  placeholder?: string
  containerClassName?: string
  onBlur?: (event: FocusEvent) => void
  noArrow?: boolean
  maxLength?: number
}

const TextField: React.FC<TextFieldProps> = ({
  autoFocus,
  label,
  controlId = 'form-control',
  containerClassName = '',
  placeholder,
  value,
  error,
  type = 'text',
  onBlur,
  onChange,
  noArrow,
  helperText,
  maxLength,
  ...inputProps
}) => {
  return (
    <InputContainer
      controlId={controlId}
      className={`text-field ${
        noArrow ? 'no-arrow' : ''
      } ${containerClassName}`}
      label={label}
      error={error}
      helperText={helperText}
    >
      <Form.Control
        autoFocus={autoFocus}
        maxLength={maxLength}
        size="lg"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        onBlur={onBlur}
        {...inputProps}
      />
    </InputContainer>
  )
}

export default memo(TextField)
