import { RoutePaths } from 'containers/Core/Routes'
import {
  SchedulingProvider,
  SchedulingScreen,
} from 'context/SchedulingContext/SchedulingProvider'
import { SourceProvider } from 'context/SourceContext/SourceProvider'
import withSubscription from 'hoc/withSubscription'
import useSearchParams from 'hooks/useSearchParams'
import { Col, Container, Row } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import Navigation from './Navigation'
import SchedulingRouter from './SchedulingRouter'
import withAccountStatus from 'hoc/withAccountStatus'
import './styles.scss'
import useAppointments from 'hooks/useAppointments/useAppointments'
import Loader from 'components/Loader'

const Scheduling: React.FC = () => {
  const searchParams = useSearchParams()
  const {
    isEligibleToChooseAppointmentType,
    isAppointmentChoiceEligibilityLoading,
  } = useAppointments()

  const appointmentId = searchParams.get('appointment')
  const appointmentTypeId = searchParams.get('appointmentType')
  const isGenericAppointment = searchParams.get('genericAppointment') === 'TRUE'

  // At least one must be set
  if (!appointmentTypeId && !appointmentId) {
    return <Redirect to={RoutePaths.DASHBOARD} />
  }

  if (isAppointmentChoiceEligibilityLoading) {
    return <Loader />
  }

  const shouldAllowAppointmentTypeChoice =
    isEligibleToChooseAppointmentType && isGenericAppointment

  const initialSchedulingScreen: SchedulingScreen = shouldAllowAppointmentTypeChoice
    ? 'appointmentTypeSelect'
    : 'location'
  const initialAppointmentTypeId = appointmentTypeId ?? undefined
  return (
    <Container fluid className="px-0 m-0 scheduling">
      <Row className="h-100">
        <SourceProvider>
          <SchedulingProvider
            appointmentId={appointmentId ?? undefined}
            initialAppointmentTypeId={
              shouldAllowAppointmentTypeChoice
                ? undefined
                : initialAppointmentTypeId
            }
            initialScreen={initialSchedulingScreen}
          >
            <Col md={12} lg={4} className="navigation">
              <Navigation />
            </Col>
            <Col md={12} lg={8} className="screen">
              <SchedulingRouter />
            </Col>
          </SchedulingProvider>
        </SourceProvider>
      </Row>
    </Container>
  )
}

export default withAccountStatus(withSubscription(Scheduling))
