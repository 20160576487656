export const NUTRITION_FEATURES = [
  '30-min video consultation with your Registered Dietitian every 2 months',
  'Unlimited text messaging with your RD for daily check-ins',
  'Access to a private community of Allara Members',
]

export const PCOS_FEATURES = [
  'A personalized care plan that addresses nutrition, lifestyle, and medication if appropriate',
  'Comprehensive testing, including hormonal and metabolic panels or imaging, as needed',
  '1:1 video visits with members of your care team',
  'Quarterly check ins with Registered Dietitian',
  'Quarterly check ins with OB-GYN specialist',
  'Exclusive perks from partner brands & discounts on Allara supplements',
]

export const PCOS_TRANSITION_FEATURES = [
  '30-min video onboarding with your Registered Dietitian',
  'Quarterly video check-ins with your Medical Provider and RD',
  'Advanced lab testing*',
  'Prescriptions as needed*',
  'Access to a private community of Allara Members',
]

export const DIAGNOSTIC_FEATURES = [
  '15-minute initial evaluation with a medical provider to discuss medical history and symptoms',
  'Comprehensive testing: including hormonal and metabolic panels or imaging, as needed',
  '30 min video consult with medical provider to discuss results',
  'Actionable next steps, decided in collaboration with medical provider',
  'Discounted first month of full membership',
]

export const INSURANCE_HMT_FEATURES = [
  '15-minute initial evaluation with a medical provider to discuss medical history and symptoms',
  'Comprehensive testing: including hormonal and metabolic panels or imaging, as needed',
  '30 min video consult with medical provider to discuss results',
  'Actionable next steps, decided in collaboration with medical provider',
  'Discounted first month of full membership',
]

export const INSURANCE_FEATURES = [
  'A members-only app for messaging, appointment scheduling, recipes, goal tracking, and more',
  'Personalized nutrition and lifestyle plans',
  'Messaging with your care team (medical provider and dietitian)',
  'Concierge service to handle insurance, Rx refills, navigating referrals, and more',
  'Access to advanced diagnostic testing',
  'Education and community events',
  'Exclusive perks from partner brands & discounts on Allara supplements',
]

export const MNC_FEATURES = [
  '8 weeks of live lectures and discussions with two class times: Tuesdays 7-8pm EST or Wednesday 8-9pm EST',
  'Access to the recorded lectures and discussions',
  'Access to a private forum with other members of the course',
  'Private forum to interact with your instructor Felice Ramallo, Allara Lead Dietitian, MSCN, RDN',
  'Exclusive perks from partner brands & discounts on Allara supplements',
]

export const INSURANCE_SECONDARY_INFO = [
  'Lab tests & prescription',
  'Video visits with doctors & NPs',
  'Dietitian visits (if covered in your plan)',
]

export const INSURANCE_HMT_SECONDARY_INFO = [
  'Lab tests',
  'Video visits with doctors & NPs',
]

export const MNC_SECONDARY_INFO = [
  'Unpacking Diet Culture',
  'Rediscovering Hunger and Fullness',
  'Minful Nourishment',
  'Connecting With Your Body',
  'Finding the Joy in Eating',
]
