import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Redirect } from 'react-router-dom'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import CheckoutForm from './CheckoutForm'
import { RoutePaths } from 'containers/Core/Routes'
import OnboardingContainer from '../OnboardingContainer'
import { PromoCodeProvider } from 'context/PromoCodeContext/PromoCodeProvider'

import './styles.scss'
import UseOnboardingPageView from 'hooks/useOnboardingPageView'
import { useEffect } from 'react'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
)

interface PaymentProps {
  isDirectFlow?: boolean
}

const Payment: React.FC<PaymentProps> = ({ isDirectFlow }) => {
  const { selectedPlan } = useOnboardingContext()
  const { trackPageView } = UseOnboardingPageView()
  // Track page view
  useEffect(() => {
    trackPageView('payment')
  }, [])

  if (!selectedPlan) {
    return (
      <Redirect
        to={`${
          isDirectFlow
            ? RoutePaths.DIRECT_PAYMENT_PLANS
            : RoutePaths.PAYMENT_PLANS
        }${window.location.search}`}
      />
    )
  }

  return (
    <PromoCodeProvider plan={selectedPlan}>
      <OnboardingContainer>
        <div className="payment mt-6 mt-sm-8 px-4 px-sm-8">
          <Elements stripe={stripePromise}>
            <CheckoutForm isDirectFlow={isDirectFlow} />
          </Elements>
        </div>
      </OnboardingContainer>
    </PromoCodeProvider>
  )
}

export default Payment
