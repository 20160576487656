import { useStytch, useStytchSession } from '@stytch/react'
import {
  LabOrders as LabOrdersRoute,
  QueryKey as LabsQueryKey,
} from 'api/lab-orders/routes'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

export type LabOrder = {
  id: string
  user: string // id
  signedDate: string // date
}

interface UseLabOrdersInterface {
  labOrders: LabOrder[]
  isLoading: boolean
  downloadLabOrder: (labOrder: LabOrder) => Promise<void>
}

const useLabOrders = (): UseLabOrdersInterface => {
  const { setAlertText } = useAlert()
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchLabs = async (): Promise<LabOrder[]> => {
    if (!session) {
      return []
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${LabOrdersRoute}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          return []
        }
      })
      .catch(() => {
        return []
      })
  }

  const { isLoading, data } = useQuery(LabsQueryKey, fetchLabs)

  const labOrders = useMemo(() => data ?? [], [data])

  const downloadLabOrder = async (labOrder: LabOrder) => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${LabOrdersRoute}/${labOrder.id}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
      .then(async (res) => {
        const blob = await res.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `allara_lab_order_${labOrder.id}_${labOrder.signedDate}.pdf`
        document.body.appendChild(a)
        a.click()
        a.remove()
        window.URL.revokeObjectURL(url)
      })
      .catch((err) =>
        setAlertText(
          'Unable to download Lab Order. Please try again.',
          'An Error Occurred',
          err
        )
      )
  }

  return {
    labOrders,
    isLoading,
    downloadLabOrder,
  }
}

export default useLabOrders
