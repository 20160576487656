import { useCallback, useEffect } from 'react'
import Loader from 'components/Loader'
import { useStytch } from '@stytch/react'
import { RoutePaths } from 'containers/Core/Routes'

const Logout: React.FC = () => {
  const stytchClient = useStytch()

  useEffect(() => {
    stytchClient.session.revoke()
    window.location.href = window.location.origin + RoutePaths.LOGIN
  }, [stytchClient])

  return <Loader />
}

export default Logout
