import { useStytch, useStytchSession } from '@stytch/react'
import { Automation as AutomationRoute } from 'api/automations/routes'
import { Labs as LabsRoute, QueryKey as LabsQueryKey } from 'api/labs/routes'
import axios from 'axios'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import produce from 'immer'
import { useMemo, useState } from 'react'
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'

export type Lab = {
  id: string
  user: string // id
  uploaded: string // date
  documented?: string // date
  type: string
}

export type UploadLabsDto = {
  files: File[]
  documented: string
}

export type UpdateLabsDto = {
  lab: Lab
  documented: string
}

interface UseLabsInterface {
  labs: Lab[]
  isLoading: boolean
  isSubmitting: boolean
  downloadLab: (lab: Lab) => Promise<void>
  handleUploadLabs: UseMutateAsyncFunction<
    undefined,
    unknown,
    UploadLabsDto,
    unknown
  >
  handleUpdateLabs: UseMutateAsyncFunction<
    undefined,
    unknown,
    UpdateLabsDto,
    unknown
  >
}

const useLabs = (): UseLabsInterface => {
  const { setAlertText } = useAlert()
  const queryClient = useQueryClient()
  const stytch = useStytch()
  const { session } = useStytchSession()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const fetchLabs = async (): Promise<Lab[]> => {
    if (!session) {
      return []
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${LabsRoute}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          return []
        }
      })
      .catch(() => {
        return []
      })
  }

  const { isLoading, data } = useQuery(LabsQueryKey, fetchLabs)

  const labs = useMemo(() => data ?? [], [data])

  const downloadLab = async (lab: Lab) => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${LabsRoute}/${lab.id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        const blob = await res.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `allara_lab_${lab.id}_${lab.uploaded}.pdf`
        document.body.appendChild(a)
        a.click()
        a.remove()
        window.URL.revokeObjectURL(url)
      })
      .catch((err) =>
        setAlertText(
          'Unable to download Lab. Please try again.',
          'An Error Occurred',
          err
        )
      )
  }

  const uploadLabs = async (
    uploadLabsDto: UploadLabsDto
  ): Promise<undefined> => {
    if (!session) {
      return
    }

    setIsSubmitting(true)
    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    const listOfPromises = uploadLabsDto.files.map((file) => {
      const formData = new FormData()
      formData.append('lab', file)
      formData.append('documented', uploadLabsDto.documented)

      return axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}${AutomationRoute}/patient/labs/upload`,
          formData,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .catch((err) =>
          setAlertText(
            `Unable to upload file ${file.name}. Please reload the page and try again.`,
            'An Error Occurred',
            err
          )
        )
    })

    await Promise.all(listOfPromises)
  }

  const mutateUpload = useMutation(uploadLabs, {
    onSettled: () => {
      queryClient.invalidateQueries(LabsQueryKey)
      setIsSubmitting(false)
    },

    onMutate: async (uploadLabsDto: UploadLabsDto) => {
      await queryClient.cancelQueries(LabsQueryKey)

      const previousData = queryClient.getQueryData<Lab[]>(LabsQueryKey)

      if (previousData) {
        const nextState = produce<Lab[]>(previousData, (draftState) => {
          draftState.push({
            id: '0',
            user: '0',
            uploaded: new Date().toISOString(),
            documented: uploadLabsDto.documented,
            type: 'Lab',
          })
        })
        queryClient.setQueryData<Lab[]>(LabsQueryKey, nextState)
      }

      return { previousData }
    },
  })

  const handleUploadLabs = mutateUpload.mutateAsync

  const updateLabs = async (
    updateLabsDto: UpdateLabsDto
  ): Promise<undefined> => {
    if (!session) {
      return
    }

    setIsSubmitting(true)
    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}${AutomationRoute}/patient/labs/upload/${updateLabsDto.lab.id}`,
        updateLabsDto,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .catch((err) =>
        setAlertText(
          `Unable to update lab ${updateLabsDto.lab.id}. Please reload the page and try again.`,
          'An Error Occurred',
          err
        )
      )
  }

  const mutateUpdate = useMutation(updateLabs, {
    onSettled: () => {
      queryClient.invalidateQueries(LabsQueryKey)
      setIsSubmitting(false)
    },
    onMutate: async (updateLabsDto: UpdateLabsDto) => {
      await queryClient.cancelQueries(LabsQueryKey)

      const previousData = queryClient.getQueryData<Lab[]>(LabsQueryKey)

      if (previousData) {
        const nextState = produce<Lab[]>(previousData, (draftState) => {
          const oldLabIndex = draftState.findIndex(
            (lab) => lab.id === updateLabsDto.lab.id
          )
          draftState[oldLabIndex] = {
            ...updateLabsDto.lab,
            documented: updateLabsDto.documented,
          }
        })
        queryClient.setQueryData<Lab[]>(LabsQueryKey, nextState)
      }

      return { previousData }
    },
  })

  const handleUpdateLabs = mutateUpdate.mutateAsync

  return {
    labs,
    isLoading,
    isSubmitting,
    downloadLab,
    handleUploadLabs,
    handleUpdateLabs,
  }
}

export default useLabs
