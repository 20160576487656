"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormResponseResource = void 0;
const BaseResource_1 = require("../../BaseResource");
class FormResponseResource extends BaseResource_1.Resource {
    /**
     * Returns a list of form responses within the current account. The responses
     * returned are sorted by creation date, with the most recently added appearing
     * first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/form_responses', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new form response for the current active version of the form you
     * specify. Optionally, you can associate the form with a member.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/form_responses', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing form response. You need only supply the
     * unique form identifier that was returned upon creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/form_responses/${id}`, {
            options,
        });
    }
    /**
     * Updates a form response.
     *
     * Responses included in the update request are updated and/or appended to the
     * existing response based on each response's associated item key. You can submit a
     * form response when the response is complete using the 'submit' parameter.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/form_responses/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
}
exports.FormResponseResource = FormResponseResource;
