"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThreadResource = void 0;
const BaseResource_1 = require("../../BaseResource");
class ThreadResource extends BaseResource_1.Resource {
    /**
     * Returns a list of threads within the current account.
     *
     * The threads returned are sorted with the most recently updated appearing first.
     */
    list(params, options) {
        return this.source.request('GET', '/v1/communication/threads', {
            query: params,
            options,
        });
    }
    /**
     * Creates a new messaging thread.
     */
    create(params, options) {
        return this.source.request('POST', '/v1/communication/threads', {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Retrieves the details of an existing thread. You need only supply the unique
     * thread identifier that was returned upon creation.
     */
    retrieve(id, options) {
        return this.source.request('GET', `/v1/communication/threads/${id}`, {
            options,
        });
    }
    /**
     * Updates the specified thread by setting the values of the parameters passed. Any
     * parameters not provided will be left unchanged.
     *
     * You can change the thread’s status or subject or reassign the thread to a
     * different user.
     */
    update(id, params, options) {
        return this.source.request('POST', `/v1/communication/threads/${id}`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
    /**
     * Marks a thread as read by the member. You can provide a timestamp through which
     * the member has read. All message appearing before this timestamp will be shown
     * as read by the member in the Source Elements SDK. If you're not using Elements,
     * you can use this timestamp to track where the member has read in your own
     * interface.
     *
     * Providing null marks the entire thread as unread.
     */
    mark(id, params, options) {
        return this.source.request('POST', `/v1/communication/threads/${id}/mark`, {
            data: params,
            contentType: 'json',
            options,
        });
    }
}
exports.ThreadResource = ThreadResource;
