import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Divider,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { ChakraPrimaryButton } from 'components/Button'
import { Appointment } from 'hooks/useAppointments/useAppointments'
import useAppointmentType from 'hooks/useAppointments/useAppointmentType'
import { addToGCal, getDownloadICS } from 'utils/calendar.utils'

type CalendarModalProps = {
  isOpen: boolean
  onClose: () => void
  timeZone?: string
  appointment: Appointment
}

const CalendarModal: React.FC<CalendarModalProps> = ({
  isOpen,
  onClose,
  timeZone,
  appointment,
}) => {
  const { appointmentTypeList } = useAppointmentType()
  const matchingAppointmentType = appointmentTypeList?.data.find(
    (a) => a.id === appointment.type.id
  )
  const appointmentDuration = matchingAppointmentType?.duration
  const { provider, time } = appointment

  const handleDownloadICS = () => {
    if (
      !timeZone ||
      !appointment ||
      !provider ||
      !time ||
      !appointmentDuration
    ) {
      return alert('Could not save ICS file')
    }

    getDownloadICS({
      provider,
      dateTime: new Date(time),
      timeZone,
      appointment,
      appointmentDuration,
    })
  }

  const handleAddToGCal = () => {
    if (
      !timeZone ||
      !appointment ||
      !provider ||
      !time ||
      !appointmentDuration
    ) {
      return alert('Could not create event')
    }

    addToGCal({
      provider,
      dateTime: new Date(time),
      timeZone,
      appointment,
      appointmentDuration,
    })
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
        <ModalOverlay />
        <ModalContent padding="1.5rem">
          <ModalHeader
            padding="0 0 1rem 0"
            fontWeight="400"
            display="flex"
            justifyContent="space-between"
          >
            Add appointment to calendar
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody
            display="flex"
            justifyContent="space-evenly"
            gap=".5rem"
            paddingBottom="0"
          >
            <ChakraPrimaryButton onClick={handleDownloadICS}>
              iCal/Outlook
            </ChakraPrimaryButton>
            <ChakraPrimaryButton onClick={handleAddToGCal}>
              Google Calendar
            </ChakraPrimaryButton>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CalendarModal
